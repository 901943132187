import { createAsyncThunk } from '@reduxjs/toolkit';
import {solo} from "../../api/solo-sdk"

export const postAIUnits = createAsyncThunk(
    'aiUnits/postAIUnits',
    async (aiUnits, { rejectWithValue, dispatch, getState }) => {
        const attempt = getState().aiUnits.retryCount || 0; // Get current retry attempt count
        try {
            const response = await solo.reportAIUnits(aiUnits)
            if (!response) {
                throw new Error('Server responded with an error!');
            }else if(response.error){
                throw new Error(response.error);
            }
            return aiUnits;
        } catch (error) {
            console.log('Error reporting AI units:', error.message);
            if (error.message === "units limit reached") {
                //  dispatch(stopMonitoring());
                solo.stopMonitoring();
                // console.log('Server responded with 429, retrying...');
                return rejectWithValue(error.message);
            }
            return rejectWithValue(error.message);
        }
    }
);

import React, { Component } from 'react';
import PopUp from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";
import Lottie from 'react-lottie';

class Popup extends Component {

    render() {

        const { isOpen, closeHandler, title, description, footerButtons, loading, loader, error, children } = this.props

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (

                <PopUp

                    position="center"
                    closeOnDocumentClick={false}
                    open={isOpen}
                    onClose={closeHandler}
                    contentStyle={{
                        width: 335,
                        borderRadius: 9,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: 100,
                        zIndex: 2147483647
                    }}
                >
                    <PopupTitle>{title}</PopupTitle>

                    {description && <PopupMessage>{description}</PopupMessage>}

                    <ErrorMessage >{error}</ErrorMessage>

                    {children}

                    <Footer>
                        {footerButtons.map(b =>
                            <FooterButton onClick={b.onPress} style={{ background: colors.blue, color: "#fff", borderRadius: 9 }} key={b}>
                                {loading ? (
                                    <Lottie
                                        options={defaultOptions}
                                        height={100}
                                        width={100}
                                    />) : (
                                    b.label
                                )}
                            </FooterButton>
                        )}
                    </Footer>
                </PopUp>

        );
    }
}

const PopupTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6875em;
  line-height: 2.3125em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0190110625em;
  text-transform: capitalize; 
  /* Main 1 */
  color: #536EEC;
`

const PopupMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125em;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #303030;
//   margin-bottom: 0.7em;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0.5em;
  left: -0.0625em;
  right: -0.0625em;
  height: 4.7em;
//   box-shadow: 0em 0.070974375em 0.88718125em rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 9px 9px;
`

const FooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 2.5em;

  padding-top: 30px;
  padding-bottom: 30px;

  margin-right: 7px;
  margin-left: 7px;

  font-family: Open Sans;
  font-size: 1m;
  text-align: center;
  
  text-transform: capitalize; 

  flex: 1;
`

const ErrorMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing:0.0190110625em;

  /* Main 2 */

  color: #CC1D6C;
`

export default Popup;

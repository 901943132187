import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {store, persistor, persistConfig} from './app/store'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react';
import { loadPersistedState } from './app/reducers/aiUnitsSlice';
import { getStoredState } from 'redux-persist';
import BlockedPopup from "./components/BlockedPopup"

const ReduxApp = ({}) => {

    useEffect(() => {
        const loadState = async () => {
            const persistedState = await getStoredState(persistConfig);
            store.dispatch(loadPersistedState(persistedState));
        };

        loadState();
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    );


}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReduxApp/>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'

class MediaPlayer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {videoId, playing, initialPlay, onInitialPlay, onProgress, onReady, url,height, onEnded, onPause, onError, onPlay, controls} = this.props;

        return (
            <div style={{position: "relative", width: "100%", height: "100%", maxHeight: height}}>
                <ReactPlayer
                    url={videoId ? `http://www.youtube.com/embed/${videoId}?playsinline=1&autoplay=1` : url + "&playsinline=1&autoplay=1"}
                    onReady={(e) => {
                        console.log("player ready", e)
                        onReady && onReady(e)
                    }}
                    onPause={()=>{
                        console.log("player paused")
                        onPause && onPause()
                    }}
                    onPlay={()=>{
                        console.log("player play")
                        onPlay && onPlay()
                        if(initialPlay && onInitialPlay){
                            onInitialPlay()
                        }
                    }}
                    onError={(e)=>{
                        console.log("player error", e)
                        onError && onError(e)

                    }}
                    onEnded={()=>{
                        console.log("player ended")
                        onEnded && onEnded()
                    }}
                    onProgress={(stats)=>{
                       // console.log("onProgress", stats)
                        //stats.playedSeconds
                        onProgress && onProgress(stats)

                    }}
                    onDuration={(duration)=>{
                        console.log("song duration", duration)
                        //this.props.onDuration(duration)
                    }}
                    width='100%'
                    height='100%'
                    config={{
                        youtube: {
                            playerVars: {'autoplay': 0, 'controls': controls ? 1 : 0},
                            embedOptions: {
                                allowfullscreen: 0,
                                autoplay: 0
                            }
                        }
                    }}
                    playing={playing}
                />
            </div>
        );
    }
}

MediaPlayer.propTypes = {
    videoId: PropTypes.string,
    url: PropTypes.string,
    onPlay: PropTypes.func,
    onReady: PropTypes.func,
    onPause: PropTypes.func,
    onProgress: PropTypes.func,
    playing: PropTypes.bool.isRequired,
    onDuration: PropTypes.func,
    onEnded: PropTypes.func,
    onError: PropTypes.func,
};

export default MediaPlayer;

import firebase from 'firebase';
import "firebase/analytics";
import "firebase/auth";
import axios from "axios"
import { translationsFirebaseConfig, apiRoutes } from "../constants/config";
import remoteConfigFirebase from "./firebase"

const translationsFirebase = firebase.initializeApp(translationsFirebaseConfig, "secondary")

export async function getYoutubeSongMetadataUrl(videoUrl) {
    return axios.get(`https://noembed.com/embed?url=${videoUrl}`)
}

export const getSongsMetadata = async () => {
    return fetch(apiRoutes.getSongsMetadata, { headers: getAuthHeader() }).then((response) => response.json())
}

export const houseLogin = async ({ houseKey }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            houseKey
        })
    };

    return fetch(apiRoutes.houseLogin, init).then((response) => response.json())
}

export const demoSignUp = async ({ email, name, companyName, password, recaptchaToken }) => {
    let headers = new Headers()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            email,
            name,
            companyName,
            password,
            recaptchaToken
        })
    };

    return fetch(apiRoutes.demoSignUp, init).then((response) => response.json())
}

export const loginValidation = async ({ idToken }) => {


    let headers = new Headers()
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${idToken}`)
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            returnSDKCreds: true,
        })
    };
    console.log(apiRoutes.loginValidation, "loginValidation init", init)

    let response = await fetch(apiRoutes.loginValidation, init)
    console.log(apiRoutes.loginValidation,"loginValidation response", response)
    let jsonRes = await response.json();
    return jsonRes
}



export const getSong = async ({ tracker, genres, languages, road, residentId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            emotions: tracker,
            genres,
            languages,
            road,
            residentId

        })
    };
    return fetch(apiRoutes.getFirstSong, init).then((response) => response.json())
}


export const getPlaylist = async ({ tracker, genres, languages, road, size = 5, residentId }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            emotions: tracker,
            genres,
            languages,
            road,
            size,
            residentId
        })
    };
    return fetch(apiRoutes.getPlaylist, init).then((response) => response.json())
}

export const logToSessionSummary = async ({ sessionId, tracker, road, songId, reason }) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            emotions: tracker,
            road,
            sessionId,
            songId,
            reason
        })
    };

    return fetch(apiRoutes.logSessionEvent, init).then((response) => response.json())
}

export const getResidents = async () => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers,
    };

    return fetch(apiRoutes.getResidents, init).then((response) => response.json())
}


const getAuthHeader = () => {
    const token = localStorage.getItem("userToken")
    return new Headers({
        'Authorization': `Bearer ${token}`
    })
}

//TRANSLATION
export const getTranslationsVersion = async () => {
    const snapshot = await translationsFirebase.database().ref('/translations/version').once('value');
    return snapshot.val();
}

export const getTranslations = async () => {
    const snapshot = await translationsFirebase.database().ref('/translations').once('value');
    return snapshot.val();
}


export const getThresholds = async () => {
    return remoteConfigFirebase.database().ref('solo-mask-thresholds').once('value').then((snapshot) => {
        console.log("snapshot.val()", snapshot.val())
        return snapshot.val()
    })
}

export const listenToModulesThresholds = (updateCallback) => {
    return remoteConfigFirebase.database().ref('solo-mask-thresholds/modules').on('value', (snapshot) => {
        const data = snapshot.val();
        updateCallback(data)
    })
}

export const updateThreshold = async (name, value) => {
    await remoteConfigFirebase.database().ref('solo-mask-thresholds').child(name).set(value)
}

export const createDefaultThresholds = async () => {
    await remoteConfigFirebase.database().ref('solo-mask-thresholds').set({
        "happy": 0.5,
        "calm": 0.5,
        "sad": 0.5,
        "angry": 0.5,
        "disgust": 0.5,
        "fear": 0.5,
        "surprise": 0.5,
    })
}

export const login = async (email, password, houseKey) => {
    try {

        let user = null;
        try {
            console.log("logging in with email and password", email, password)
            const {user} = await firebase.auth().signInWithEmailAndPassword(email, password)
            if(user){
                return user
            }
            else return null
        } catch (error) {
            console.error("error logging in", error)
            let errorCode = error.code;
            let errorMessage = error.message;
            return {
                error: error.message
            }
        }
    } catch (e) {
        return {
            error: e
        }
    }
}

export const sendPasswordReset = async (email, redirectURL) => {
    try {
        let actionCodeSettings = {
            url: redirectURL,
            handleCodeInApp: false
        };
        await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
    } catch (error) {
        return {
            error: error.message
        }
    }
}

export const logout = async () => {
    await firebase.auth().signOut()
}


export default remoteConfigFirebase;



    import React, { PureComponent, Fragment } from 'react';
    import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Area,
    ResponsiveContainer,
    ComposedChart,
        AreaChart,
        Brush,
        ReferenceArea
} from 'recharts';
    import PropTypes from "prop-types";
    import { getString } from "../utils/translations";
    import styled from 'styled-components'
    import _ from 'lodash'
    import colors from "../styles/colors";
    import {
    STRESS_HIGH,
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    MOOD_HIGH,
    MOOD_MEDIUM,
    WELLBEING_HIGH,
    WELLBEING_MEDIUM,
    STRESS_MEDIUM,
    STRESS_LOW,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
        PITCH_ANGLE_MAX_ALERT_THRESHOLD,
} from "../api/remoteConfig";
    import {OVER_TIME_BUTTONS} from "../constants";
    import {getFilterReasonString} from "../utils";

    export default class OverTimeChart extends PureComponent {
        render() {
            const { data, x, y, selectedEmotions, tooltipLabelFormatter, domain, selectedIds, showBrush,
                onBrushChange } = this.props;

           // let  = [1]

            let chartColors = []

            if(selectedEmotions.includes(OVER_TIME_BUTTONS.YAW_ANGLE)){
                chartColors.push((
                    <ReferenceArea y1={-90} y2={YAW_ANGLE_MIN_ALERT_THRESHOLD} fill={colors.tomato}/>
                ))
                chartColors.push((
                    <ReferenceArea y1={YAW_ANGLE_MIN_ALERT_THRESHOLD} y2={YAW_ANGLE_MAX_ALERT_THRESHOLD} fill={colors.interest}/>
                ))
                chartColors.push((
                    <ReferenceArea y1={YAW_ANGLE_MAX_ALERT_THRESHOLD} y2={90} fill={colors.tomato}/>
                ))
            }

            if(selectedEmotions.includes(OVER_TIME_BUTTONS.PITCH_ANGLE)){
                chartColors.push((
                    <ReferenceArea y1={-90} y2={PITCH_ANGLE_MIN_ALERT_THRESHOLD} fill={colors.tomato}/>
                ))
                chartColors.push((
                    <ReferenceArea y1={PITCH_ANGLE_MIN_ALERT_THRESHOLD} y2={PITCH_ANGLE_MAX_ALERT_THRESHOLD} fill={colors.interest}/>
                ))
                chartColors.push((
                    <ReferenceArea y1={PITCH_ANGLE_MAX_ALERT_THRESHOLD} y2={90} fill={colors.tomato}/>
                ))
            }


            return (
                <div style={{ width: "100%", height: "100%" ,  marginLeft: -15 }}>
                    {(_.get(data, "length", 0)) ? <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            data={data}
                            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={x.key} tickFormatter={x.formatter || undefined} />
                            <YAxis dataKey={y.key} domain={domain} allowDataOverflow={false} />
                            <Tooltip
                                formatter={(value, name) => {
                                    console.log("formatter", value, name)
                                    if(name.startsWith("filterReason")){
                                        return [getFilterReasonString(value), getString(`${name}`)]
                                    }
                                    return [value, getString(`${name}`)]
                                }}
                                labelFormatter={(payload) => { return tooltipLabelFormatter(payload, data, x) }} />

                            {chartColors}
                            {
                                selectedIds.map((id, index) => {
                                    return selectedEmotions.map(emotion => {
                                        return [
                                            // <Line key={emotion} stroke={colors[emotion]} fill={colors[emotion]} type="monotone" dataKey={emotion} activeDot={{ r: 8 }} strokeWidth={1.5} />,
                                            <Area type="monotone"
                                                  dataKey={`${emotion}_${id}`}
                                                  stroke={colors.blue}
                                                  strokeWidth={1.7}
                                                  fill={colors.gray}
                                                  fillOpacity={0.1}
                                                  isAnimationActive={false}
                                                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                                                  dot={<CustomizedDot emotion={emotion} />}
                                            />
                                        ]
                                    })
                                })
                               }

                            {showBrush && <Brush dataKey={x.key} height={30} stroke="#8884d8" onChange={onBrushChange} />}
                        </AreaChart>

                    </ResponsiveContainer> :
                        <NoDataMessage>
                            {getString('empty_overtime_chart')}
                        </NoDataMessage>
                    }
                </div>
            );
        }
    }

    const validateValue = (value) => {
        return value && value.length > 1 && value[0] !== undefined && value[1] !== undefined && value[0] !== null && value[1] !== null
    }

    const CustomizedDot = (props) => {
        const { cx, cy, stroke, payload, value, emotion } = props;
        if(payload && payload.metadata === "empty" || !validateValue(value)){
            return null;
        }
        //console.log("customized dot", value, payload)
        const color = getColor(emotion, value.length > 1 ? value[1] : value)
        return (
            <circle
                key={cx}
                cx={cx}
                cy={cy}
                r={4}
                stroke={color}
                fill={color}
            />
        );
    };


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <div>{`${label} `}</div>
                    <div>{payload[0].value}</div>
                </div>
            );
        }

        return null;
    };

    export const getColor = (columnType, value, divided) => {
        const color = (value, high, medium, reverse, low) => {
            if (value >= high) {
                if (reverse) {
                    return colors.tomato
                }
                return colors.interest
            } else if (value < high && value >= medium) {
                return colors.mild
            } else {
                if (low) {
                    if (value < medium && value >= low) {
                        if (reverse) {
                            return colors.interest
                        }
                        return colors.tomato
                    }
                    return colors.warm_grey
                } else {
                    if (reverse) {
                        return colors.interest
                    }
                    return colors.tomato
                }
            }
        }
        const val = divided ? value : value / 100
        switch (columnType) {
            case TABLE_COLUMNS.ENGAGEMENT:
                return color(val, ENGAGEMENT_HIGH, ENGAGEMENT_MEDIUM)
            case TABLE_COLUMNS.INTEREST:
                return color(val, INTEREST_HIGH, INTEREST_MEDIUM)
            case TABLE_COLUMNS.MOOD:
                return color(val, MOOD_HIGH, MOOD_MEDIUM)
            case TABLE_COLUMNS.WELLBEING:
                return color(val, WELLBEING_HIGH, WELLBEING_MEDIUM)
            case TABLE_COLUMNS.STRESS:
                return color(val, STRESS_HIGH, STRESS_MEDIUM, true, STRESS_LOW)
            default:
                return colors.blue;
        }
    }

    export const TABLE_COLUMNS = {
        ENGAGEMENT: "engagement",
        INTEREST: "interest",
        MOOD: "mood",
        WELLBEING: "wellbeing",
        STRESS: "stress"
    }

    const NoDataMessage = styled.div`
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 29px;
        font-family: Rubik;
        font-size: 22px;
        color: #2E384D;
    `

    export const overTimeChartShape = PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.any
    }))


    OverTimeChart.propTypes = {
        data: overTimeChartShape,
        color: PropTypes.string,
        selectedEmotions: PropTypes.array
    }

import colors from "../../styles/colors";
import logo from "../../images/logo.svg";
import React from "react";

const Logo = () => {
    return (
        <a style={{
            position: "absolute",
            top: 13,
            left: 10,
        }}
           href="https://imsolo.ai"
        >
            <div style={{
                fontFamily: "Mulish",
                fontWeight: 800,
                fontSize: 16.4613,
                color: colors.lipstick,
                // textShadow: "0px 4.10072px 4.10072px rgba(0, 0, 0, 0.25)"
            }}>Powered By
            </div>
            <img src={logo}/>
        </a>
    )
}

export default Logo

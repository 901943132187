import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Button extends PureComponent {

    render() {

        const {
            icon,
            label,
            onClick,
            disabled,
            style
        } = this.props

        let containerStyle = Object.assign({}, {
            fontFamily: "Open Sans",
            fontWeight: 200,
            fontSize: 20,
            color: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            padding: "8px 13px",
            justifyContent: "center",
            alignItems: "center",
            outline: "none",
            background: "#536EEC",
            // boxShadow: "0px 0px 9.84206px rgba(0, 0, 0, 0.25)",
            borderRadius: 10,
            border: "none",
            cursor: "pointer"
        }, style);
        return (
            <button
                disabled={disabled}
                onClick={onClick}
                style={containerStyle}
            >
                {icon}
                {label}
            </button>
        );
    }
}

Button.propTypes = {
    icon: PropTypes.any,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object
};

Button.defaultProps = {
    disabled: false,
    style: {}
};

export default Button;

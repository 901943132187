import {getConfig, solo} from "../../api/solo-sdk";
import {store} from "../../app/store";
import {postAIUnits} from "../../app/api/aiUnits";

let intervalId = null;

export const reportAIUnitsAndCalcTimeout = () =>{

    if(!solo.isMonitoring()){
        console.log('Monitoring disabled')
        return;
    }

    let UNITS_REPORTING_INTERVAL_MIN = getConfig('UNITS_REPORTING_INTERVAL_MIN');
    let UNITS_REPORTING_INTERVAL_MAX = getConfig('UNITS_REPORTING_INTERVAL_MAX');

    const timeout = 60//Math.floor(Math.random() * (UNITS_REPORTING_INTERVAL_MAX - UNITS_REPORTING_INTERVAL_MIN + 1) + UNITS_REPORTING_INTERVAL_MIN);
    //console.log('Reporting AI units after ', timeout , ' seconds');
    intervalId = setTimeout(() => {
            // console.log('Reporting AI units')
            reportAiUnits()
            reportAIUnitsAndCalcTimeout();
        },
        timeout * 1000
    );
}

const reportAiUnits = () => {
    store.dispatch(postAIUnits(store.getState().aiUnits.aiUnits));
}




const EXIF = require('exif-js');

export const isMobile = () => {
    let isMobile = false;
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        isMobile = true;
    }

    return isMobile
}


export const getAsset = (path) => {
    return process.env.PUBLIC_URL + "/assets/" + path
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function videoDimensions(video) {
    // Ratio of the video's intrisic dimensions
    let videoRatio = video.videoWidth / video.videoHeight;
    // The width and height of the video element
    let width = video.offsetWidth, height = video.offsetHeight;
    // The ratio of the element's width to its height
    let elementRatio = width/height;
    // If the video element is short and wide
    if(elementRatio > videoRatio) width = height * videoRatio;
    // It must be tall and thin, or exactly equal to the original ratio
    else height = width / videoRatio;
    return {
        width: width,
        height: height
    };
}

export function imageDimensions(image) {
    // Ratio of the image's intrinsic dimensions
    let imageRatio = image.naturalWidth / image.naturalHeight;
    // The width and height of the image element
    let width = image.offsetWidth;
    let height = image.offsetHeight;
    // The ratio of the element's width to its height
    let elementRatio = width / height;
    // If the image element is short and wide
    if (elementRatio > imageRatio) {
        width = height * imageRatio;
    } else {
        // It must be tall and thin, or exactly equal to the original ratio
        height = width / imageRatio;
    }
    return {
        width: width,
        height: height
    };
}

export function normalizeMoodEnergyChartPoints(data) {
    // Calculate the center of the circle
    const centerX = 0.5;
    const centerY = 0.5;

    // Calculate the radius of the circle
    const radius = 0.5;


    // Normalize each data point
    const normalizedData = data.map(point => {
        // Calculate the distance from the center of the circle to the data point
        const dx = point.x - centerX;
        const dy = point.y - centerY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const originalX = (point.x);
        const originalY = (point.y);


        // If the distance is greater than the radius, normalize the point
        if (distance > radius) {
            // Calculate the normalized coordinates
            const normalizedX = dx * radius / distance;
            const normalizedY = dy * radius / distance;

            // Update the data point with the normalized coordinates
            return {
                ...point,
                x: centerX + normalizedX,
                y: centerY + normalizedY,
                originalX,
                originalY
            };
        }

        // If the distance is within the radius, the point is already normalized
        return point;
    });

    return normalizedData;
}

export async function correctImageOrientation(imgElement) {
    return new Promise((resolve, reject) => {
        EXIF.getData(imgElement, function() {
            let orientation = EXIF.getTag(this, "Orientation");
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");

            console.log("orientation", orientation)

            switch(orientation) {
                case 2:
                    // horizontal flip
                    canvas.width = imgElement.width;
                    canvas.height = imgElement.height;
                    ctx.translate(imgElement.width, 0);
                    ctx.scale(-1, 1);
                    break;
                case 3:
                    // 180° rotate left
                    canvas.width = imgElement.width;
                    canvas.height = imgElement.height;
                    ctx.translate(imgElement.width, imgElement.height);
                    ctx.rotate(Math.PI);
                    break;
                case 4:
                    // vertical flip
                    canvas.width = imgElement.height;
                    canvas.height = imgElement.width;
                    ctx.translate(0, imgElement.height);
                    ctx.scale(1, -1);
                    break;
                case 5:
                    // vertical flip + 90 rotate right
                    canvas.width = imgElement.height;
                    canvas.height = imgElement.width;
                    ctx.rotate(0.5 * Math.PI);
                    ctx.scale(1, -1);
                    break;
                case 6:
                    // 90° rotate right
                    canvas.width = imgElement.height;
                    canvas.height = imgElement.width;
                    ctx.rotate(0.5 * Math.PI);
                    ctx.translate(0, -canvas.width);
                    break;
                case 7:
                    // horizontal flip + 90 rotate right
                    canvas.width = imgElement.height;
                    canvas.height = imgElement.width;
                    ctx.rotate(0.5 * Math.PI);
                    ctx.translate(canvas.height, -canvas.width);
                    ctx.scale(-1, 1);
                    break;
                case 8:
                    // 90° rotate left
                    canvas.width = imgElement.height;
                    canvas.height = imgElement.width;
                    ctx.rotate(-0.5 * Math.PI);
                    ctx.translate(-canvas.height, 0);
                    break;
                default:
                    canvas.width = imgElement.width;
                    canvas.height = imgElement.height;
                    break;
            }

            ctx.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);
            resolve(canvas.toDataURL("image/png")); // or "image/jpeg"
        });
    });
}

export const uploadImage = async () => {
    const imgFile = document.getElementById('imageUpload').files[0];
    // Convert the image to base64
    const img = new Image();
    const imgLoadPromise = new Promise((resolve, reject) => {
        img.onload = () => {
            console.log("img", img);
            let imageElement = document.getElementById('myImage');
            imageElement.src = img.src;
            resolve(img); // Resolve the promise with the loaded image
        };
        img.onerror = reject; // Reject the promise if there's an error loading the image
    });
    img.src = URL.createObjectURL(imgFile);
    return await imgLoadPromise; // Wait for the image to load before returning
};

export const getFilterReasonString = (filterReason) => {
    const reverseFilterReasons = {
        1: "DETECTION_SCORE",
        2: "BOX_SIZE",
        3: "MASK",
        4: "LIGHT",
        5: "HAND_ON_FACE",
        6: "HEAD_ANGLES",
    }

    return reverseFilterReasons[filterReason]


}

import remoteConfigFirebase, {getThresholds, listenToModulesThresholds} from "./api"
import {initConfig} from "solo-common";


let GPT_DEMO_AVATAR_URL = "https://res.cloudinary.com/dyvvo4u8h/image/upload/v1682927647/RoyAvatar_tlrrwz.png"
let AVATAR_IDLE_URL = "https://ugc-idle.s3-us-west-2.amazonaws.com/6649aa4a925818aa19e7af64e3b75ac4.mp4"
let AVATAR_IDLE_URL_FEMALE = "https://ugc-idle.s3-us-west-2.amazonaws.com/8e3e687eb248c161b597e872b47451d9.mp4"

let GPT_DEMO_CHECKUP_TIME = 7;
let GPT_DEMO_VOICE_TYPE_JP = "ja-JP-DaichiNeural"
let GPT_DEMO_VOICE_TYPE_JP_FEMALE = 'ja-JP-MayuNeural'
let GPT_DEMO_VOICE_TYPE_EN = "en-US-GuyNeural"
let GPT_DEMO_VOICE_TYPE_EN_FEMALE = "en-US-JennyNeural"

let HAPPINESS_MIN_IN = 0;
let HAPPINESS_MIN_OUT = 0;
let HAPPINESS_MAX_IN = 0.35;
let HAPPINESS_MAX_OUT = 1;
let HAPPINESS_CERTAINTY_THRESHOLD = 0.9

let NEUTRAL_MIN_IN = 0;
let NEUTRAL_MIN_OUT = 0;
let NEUTRAL_MAX_IN = 0.35;
let NEUTRAL_MAX_OUT = 1;
let NEUTRAL_CERTAINTY_THRESHOLD = 0.9

let EMOTIONAL_MIN_IN = 0;
let EMOTIONAL_MIN_OUT = 0;
let EMOTIONAL_MAX_IN = 0.35;
let EMOTIONAL_MAX_OUT = 1;
let SADNESS_CERTAINTY_THRESHOLD = 0.5
let ANGRY_CERTAINTY_THRESHOLD = 0.5

let SOLO_WEB_PASSWORD = "";

let BASELINE_DURATION_SECONDS = 20;

let INITIAL_PLAYLIST_SIZE = 5;
let WIDGETS_UPDATE_SECONDS_DEMO = 5;

let SOLO_WEB_FOOTER_TITLE_SECONDS = 5
let SOLO_WEB_SCANNING_SECS = 10

//baseline avg
let BASELINE_HAPPINESS_MIN_IN = 0;
let BASELINE_HAPPINESS_MIN_OUT = 0;
let BASELINE_HAPPINESS_MAX_IN = 0.35;
let BASELINE_HAPPINESS_MAX_OUT = 1;
let BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = 0.9

let BASELINE_NEUTRAL_MIN_IN = 0;
let BASELINE_NEUTRAL_MIN_OUT = 0;
let BASELINE_NEUTRAL_MAX_IN = 0.35;
let BASELINE_NEUTRAL_MAX_OUT = 1;
let BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = 0.9

let BASELINE_EMOTIONAL_MIN_IN = 0;
let BASELINE_EMOTIONAL_MIN_OUT = 0;
let BASELINE_EMOTIONAL_MAX_IN = 0.35;
let BASELINE_EMOTIONAL_MAX_OUT = 1;
let BASELINE_SADNESS_CERTAINTY_THRESHOLD = 0.5;
let BASELINE_ANGRY_CERTAINTY_THRESHOLD = 0.5;

let BASELINE_SURPRISED_CERTAINTY_THRESHOLD = 0.5;
let SURPRISED_CERTAINTY_THRESHOLD = 0.5;
let BASELINE_FEARFUL_CERTAINTY_THRESHOLD = 0.5;
let FEARFUL_CERTAINTY_THRESHOLD = 0.5;
let BASELINE_DISGUSTED_CERTAINTY_THRESHOLD = 0.5;
let DISGUSTED_CERTAINTY_THRESHOLD = 0.5;


let BASELINE_ANGRY_MIN_IN = 0;
let BASELINE_ANGRY_MAX_IN = 0.35;
let BASELINE_ANGRY_MAX_OUT = 1;
let BASELINE_ANGRY_MIN_OUT = 0;

let BASELINE_DISGUSTED_MIN_IN = 0;
let BASELINE_DISGUSTED_MAX_IN = 0.35;
let BASELINE_DISGUSTED_MAX_OUT = 1;
let BASELINE_DISGUSTED_MIN_OUT = 0;

let BASELINE_SAD_MIN_IN = 0;
let BASELINE_SAD_MIN_OUT = 0;
let BASELINE_SAD_MAX_IN = 0.35;
let BASELINE_SAD_MAX_OUT = 1;

let BASELINE_FEARFUL_MIN_IN = 0;
let BASELINE_FEARFUL_MIN_OUT = 0;
let BASELINE_FEARFUL_MAX_IN = 0.35;
let BASELINE_FEARFUL_MAX_OUT = 1;

let BASELINE_SURPRISED_MIN_IN = 0;
let BASELINE_SURPRISED_MIN_OUT = 0;
let BASELINE_SURPRISED_MAX_IN = 0.35;
let BASELINE_SURPRISED_MAX_OUT = 1;

let SAD_MIN_IN = 0;
let SAD_MIN_OUT = 0;
let SAD_MAX_IN = 0.35;
let SAD_MAX_OUT = 1;

let ANGRY_MIN_IN = 0;
let ANGRY_MIN_OUT = 0;
let ANGRY_MAX_IN = 0.35;
let ANGRY_MAX_OUT = 1;

let FEARFUL_MIN_IN = 0;
let FEARFUL_MIN_OUT = 0;
let FEARFUL_MAX_IN = 0.35;
let FEARFUL_MAX_OUT = 1;

let DISGUSTED_MIN_IN = 0;
let DISGUSTED_MIN_OUT = 0;
let DISGUSTED_MAX_IN = 0.35;
let DISGUSTED_MAX_OUT = 1;

let SURPRISED_MIN_IN = 0;
let SURPRISED_MIN_OUT = 0;
let SURPRISED_MAX_IN = 0.35;
let SURPRISED_MAX_OUT = 1;

let ENERGY_CONVERSION_FACTOR_NEUTRAL = 0.5;
let ENERGY_CONVERSION_FACTOR_HAPPINESS = 0.5;
let ENERGY_CONVERSION_FACTOR_DISGUSTED = 0.5;
let ENERGY_CONVERSION_FACTOR_SAD = 0.5;
let ENERGY_CONVERSION_FACTOR_ANGRY = 0.5;
let ENERGY_CONVERSION_FACTOR_FEARFUL = 0.5;
let ENERGY_CONVERSION_FACTOR_SURPRISED = 0.5;

let VALENCE_CONVERSION_FACTOR_NEUTRAL = 0.5;
let VALENCE_CONVERSION_FACTOR_HAPPINESS = 0.5;
let VALENCE_CONVERSION_FACTOR_DISGUSTED = 0.5;
let VALENCE_CONVERSION_FACTOR_SAD = 0.5;
let VALENCE_CONVERSION_FACTOR_ANGRY = 0.5;
let VALENCE_CONVERSION_FACTOR_FEARFUL = 0.5;
let VALENCE_CONVERSION_FACTOR_SURPRISED = 0.5;

let WELLBEING_CONVERSION_FACTOR_HAPPINESS = 0.5;
let WELLBEING_CONVERSION_FACTOR_NEUTRAL = 0.5;
let WELLBEING_CONVERSION_FACTOR_ANGRY = 0.5;
let WELLBEING_CONVERSION_FACTOR_FEAR = 0.5;
let WELLBEING_CONVERSION_FACTOR_SAD = 0.5;
let WELLBEING_CONVERSION_FACTOR_SURPRISED = 0.5;
let WELLBEING_CONVERSION_FACTOR_DISGUSTED = 0.5;

let STRESS_CONVERSION_FACTOR_HAPPINESS = 0.5;
let STRESS_CONVERSION_FACTOR_SAD = 0.5;
let STRESS_CONVERSION_FACTOR_SURPRISED = 0.5;
let STRESS_CONVERSION_FACTOR_FEAR = 0.5;
let STRESS_CONVERSION_FACTOR_DISGUSTED = 0.5;
let STRESS_CONVERSION_FACTOR_NEUTRAL = 0.5;
let STRESS_CONVERSION_FACTOR_ANGRY = 0.5;

let INTEREST_CONVERSION_FACTOR_HAPPINESS = 0;
let INTEREST_CONVERSION_FACTOR_SAD = 0;
let INTEREST_CONVERSION_FACTOR_SURPRISED = 0;
let INTEREST_CONVERSION_FACTOR_FEAR = 0;
let INTEREST_CONVERSION_FACTOR_DISGUSTED = 0;
let INTEREST_CONVERSION_FACTOR_NEUTRAL = 0;
let INTEREST_CONVERSION_FACTOR_ANGRY = 0;

let ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS = 0;
let ENGAGEMENT_CONVERSION_FACTOR_SAD = 0;
let ENGAGEMENT_CONVERSION_FACTOR_SURPRISED = 0;
let ENGAGEMENT_CONVERSION_FACTOR_FEAR = 0;
let ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED = 0;
let ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL = 0;
let ENGAGEMENT_CONVERSION_FACTOR_ANGRY = 0;

let STRESS_HIGH = 0.7
let STRESS_MEDIUM = 0.4
let STRESS_LOW = 0.2

let INTEREST_HIGH = 0.8
let INTEREST_MEDIUM = 0.45
let INTEREST_LOW = 0.2

let MOOD_HIGH = 0.7
let MOOD_MEDIUM = 0.3

let WELLBEING_HIGH = 0.7
let WELLBEING_MEDIUM = 0.3

let ENGAGEMENT_HIGH = 0.8
let ENGAGEMENT_MEDIUM = 0.45
let ENGAGEMENT_LOW = 0.2
let DEMO_FRAME_RATE = 4;
let modulesConfig = null
let isOriginal = true;
let MASK_ON_CERTAINTY_THRESHOLD = 0.85;
let YAWN_CERTAINTY_THRESHOLD = 0.85;
let YAWN_TIREDNESS_TIME_THRESHOLD = 3000;

let EYE_CLOSED_CERTAINTY_THRESHOLD = 0.85;

let EYE_BLINKED_CERTAINTY_THRESHOLD = 0.45;
let EYE_CLOSED_ASLEEP_TIME_THRESHOLD = 3000;

let FACE_SIMILARITY_THRESHOLD = 0.5;
let HAND_POINTS_ON_FACE_THRESHOLD = 1;

let FACE_CERTAINTY_FRAMES = 10;

let DETECTION_SCORE_THRESHOLD = 0.8;
let DETECTION_SCORE_THRESHOLD_SDK_BILLING = 0.8;

let HIGH_STRESS_ALERT_THRESHOLD = 0.8;
let HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS = 2;
let YAW_ANGLE_ALERT_THRESHOLD = 45;
let YAW_ANGLE_MIN_ALERT_THRESHOLD = 45;
let YAW_ANGLE_MAX_ALERT_THRESHOLD = 45;
let PITCH_ANGLE_MIN_ALERT_THRESHOLD = -20;
let PITCH_ANGLE_MAX_ALERT_THRESHOLD = 80;

let PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = -20;
let PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = 80;
let YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = 45;
let YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = 45;
let ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = 45;
let ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = 45;

let BLINK_FREQUENCY_DURATION_SECONDS = 10;
let BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS = 10;

let ATTENTION_MIN_THRESHOLD = 0.5;

let HEAD_ANGLE_EMOTION_FILTER_ACTIVE = true

let PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION = 0.6;

let FILTER_BOX_SIZE = {"active": true, "minWidth": 100, "minHeight": 100}

let UNITS_REPORTING_INTERVAL_MIN
let UNITS_REPORTING_INTERVAL_MAX

const remoteConfig = remoteConfigFirebase.remoteConfig();

remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
};

remoteConfig.defaultConfig = ({
    emotion_avg_func_min_in: HAPPINESS_MIN_IN,
    emotion_avg_func_max_in: HAPPINESS_MAX_IN,
    emotion_avg_func_min_out: HAPPINESS_MIN_OUT,
    emotion_avg_func_max_out: HAPPINESS_MAX_OUT,
    happiness_certainty_threshold: HAPPINESS_CERTAINTY_THRESHOLD,
    neutral_certainty_threshold: NEUTRAL_CERTAINTY_THRESHOLD,
    neutral_avg_func_min_in: NEUTRAL_MIN_IN,
    neutral_avg_func_max_in: NEUTRAL_MAX_IN,
    neutral_avg_func_min_out: NEUTRAL_MIN_OUT,
    neutral_avg_func_max_out: NEUTRAL_MAX_OUT,
    sadness_certainty_threshold: SADNESS_CERTAINTY_THRESHOLD,
    angry_certainty_threshold: ANGRY_CERTAINTY_THRESHOLD,
    emotional_avg_func_min_in: EMOTIONAL_MIN_IN,
    emotional_avg_func_max_in: EMOTIONAL_MAX_IN,
    emotional_avg_func_min_out: EMOTIONAL_MIN_OUT,
    emotional_avg_func_max_out: EMOTIONAL_MAX_OUT,
    SOLO_WEB_PASSWORD: SOLO_WEB_PASSWORD,
    baseline_duration_seconds: BASELINE_DURATION_SECONDS,
    INITIAL_PLAYLIST_SIZE,
    SOLO_WEB_FOOTER_TITLE_SECONDS,
    SOLO_WEB_SCANNING_SECS
});


remoteConfig.fetchAndActivate()
    .then(async () => {
        console.log("initialize remote config")
        //region happiness
        HAPPINESS_MIN_IN = await getConfigItem("happiness_avg_func_min_in");
        HAPPINESS_MIN_IN = HAPPINESS_MIN_IN.asNumber();
        HAPPINESS_MAX_IN = await getConfigItem("happiness_avg_func_max_in");
        HAPPINESS_MAX_IN = HAPPINESS_MAX_IN.asNumber();
        HAPPINESS_MIN_OUT = await getConfigItem("happiness_avg_func_min_out");
        HAPPINESS_MIN_OUT = HAPPINESS_MIN_OUT.asNumber();
        HAPPINESS_MAX_OUT = await getConfigItem("happiness_avg_func_max_out");
        HAPPINESS_MAX_OUT = HAPPINESS_MAX_OUT.asNumber();
        HAPPINESS_CERTAINTY_THRESHOLD = await getConfigItem("happiness_certainty_threshold");
        HAPPINESS_CERTAINTY_THRESHOLD = HAPPINESS_CERTAINTY_THRESHOLD.asNumber();
        //endregion happiness

        //region neutral
        NEUTRAL_MIN_IN = await getConfigItem("neutral_avg_func_min_in");
        NEUTRAL_MIN_IN = NEUTRAL_MIN_IN.asNumber();

        NEUTRAL_MAX_IN = await getConfigItem("neutral_avg_func_max_in");
        NEUTRAL_MAX_IN = NEUTRAL_MAX_IN.asNumber();

        NEUTRAL_MIN_OUT = await getConfigItem("neutral_avg_func_min_out");
        NEUTRAL_MIN_OUT = NEUTRAL_MIN_OUT.asNumber();

        NEUTRAL_MAX_OUT = await getConfigItem("neutral_avg_func_max_out");
        NEUTRAL_MAX_OUT = NEUTRAL_MAX_OUT.asNumber();

        NEUTRAL_CERTAINTY_THRESHOLD = await getConfigItem("neutral_certainty_threshold");
        NEUTRAL_CERTAINTY_THRESHOLD = NEUTRAL_CERTAINTY_THRESHOLD.asNumber();
        //endregion neutral

        //region emotional
        EMOTIONAL_MIN_IN = await getConfigItem("emotional_avg_func_min_in");
        EMOTIONAL_MIN_IN = EMOTIONAL_MIN_IN.asNumber();

        EMOTIONAL_MAX_IN = await getConfigItem("emotional_avg_func_max_in");
        EMOTIONAL_MAX_IN = EMOTIONAL_MAX_IN.asNumber();

        EMOTIONAL_MIN_OUT = await getConfigItem("emotional_avg_func_min_out");
        EMOTIONAL_MIN_OUT = EMOTIONAL_MIN_OUT.asNumber();

        EMOTIONAL_MAX_OUT = await getConfigItem("emotional_avg_func_max_out");
        EMOTIONAL_MAX_OUT = EMOTIONAL_MAX_OUT.asNumber();

        SADNESS_CERTAINTY_THRESHOLD = await getConfigItem("sadness_certainty_threshold");
        SADNESS_CERTAINTY_THRESHOLD = SADNESS_CERTAINTY_THRESHOLD.asNumber();

        ANGRY_CERTAINTY_THRESHOLD = await getConfigItem("angry_certainty_threshold");
        ANGRY_CERTAINTY_THRESHOLD = ANGRY_CERTAINTY_THRESHOLD.asNumber();
        //endregion emotional

        SOLO_WEB_PASSWORD = await getConfigItem("solo_web_password");
        SOLO_WEB_PASSWORD = SOLO_WEB_PASSWORD.asString();

        BASELINE_DURATION_SECONDS = await getConfigItem("baseline_duration_seconds");
        BASELINE_DURATION_SECONDS = BASELINE_DURATION_SECONDS.asNumber();

        INITIAL_PLAYLIST_SIZE = await getConfigItem("INITIAL_PLAYLIST_SIZE")
        INITIAL_PLAYLIST_SIZE = INITIAL_PLAYLIST_SIZE.asNumber()

        SOLO_WEB_FOOTER_TITLE_SECONDS = await getConfigItem("SOLO_WEB_FOOTER_TITLE_SECONDS")
        SOLO_WEB_FOOTER_TITLE_SECONDS = SOLO_WEB_FOOTER_TITLE_SECONDS.asNumber()

        SOLO_WEB_SCANNING_SECS = await getConfigItem("SOLO_WEB_SCANNING_SECS")
        SOLO_WEB_SCANNING_SECS = SOLO_WEB_SCANNING_SECS.asNumber()

        BASELINE_HAPPINESS_MIN_IN = await getConfigItem("BASELINE_HAPPINESS_MIN_IN");
        BASELINE_HAPPINESS_MIN_IN = BASELINE_HAPPINESS_MIN_IN.asNumber();

        BASELINE_HAPPINESS_MIN_OUT = await getConfigItem("BASELINE_HAPPINESS_MIN_OUT");
        BASELINE_HAPPINESS_MIN_OUT = BASELINE_HAPPINESS_MIN_OUT.asNumber();

        BASELINE_HAPPINESS_MAX_IN = await getConfigItem("BASELINE_HAPPINESS_MAX_IN");
        BASELINE_HAPPINESS_MAX_IN = BASELINE_HAPPINESS_MAX_IN.asNumber();

        BASELINE_HAPPINESS_MAX_OUT = await getConfigItem("BASELINE_HAPPINESS_MAX_OUT");
        BASELINE_HAPPINESS_MAX_OUT = BASELINE_HAPPINESS_MAX_OUT.asNumber();

        BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_HAPPINESS_CERTAINTY_THRESHOLD");
        BASELINE_HAPPINESS_CERTAINTY_THRESHOLD = BASELINE_HAPPINESS_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_SURPRISED_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_SURPRISED_CERTAINTY_THRESHOLD");
        BASELINE_SURPRISED_CERTAINTY_THRESHOLD = BASELINE_SURPRISED_CERTAINTY_THRESHOLD.asNumber();

        SURPRISED_CERTAINTY_THRESHOLD = await getConfigItem("SURPRISED_CERTAINTY_THRESHOLD");
        SURPRISED_CERTAINTY_THRESHOLD = SURPRISED_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_FEARFUL_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_FEARFUL_CERTAINTY_THRESHOLD");
        BASELINE_FEARFUL_CERTAINTY_THRESHOLD = BASELINE_FEARFUL_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_DISGUSTED_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_DISGUSTED_CERTAINTY_THRESHOLD");
        BASELINE_DISGUSTED_CERTAINTY_THRESHOLD = BASELINE_DISGUSTED_CERTAINTY_THRESHOLD.asNumber();

        DISGUSTED_CERTAINTY_THRESHOLD = await getConfigItem("DISGUSTED_CERTAINTY_THRESHOLD");
        DISGUSTED_CERTAINTY_THRESHOLD = DISGUSTED_CERTAINTY_THRESHOLD.asNumber();

        FEARFUL_CERTAINTY_THRESHOLD = await getConfigItem("FEARFUL_CERTAINTY_THRESHOLD");
        FEARFUL_CERTAINTY_THRESHOLD = FEARFUL_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_NEUTRAL_MIN_IN = await getConfigItem("BASELINE_NEUTRAL_MIN_IN");
        BASELINE_NEUTRAL_MIN_IN = BASELINE_NEUTRAL_MIN_IN.asNumber();

        BASELINE_NEUTRAL_MIN_OUT = await getConfigItem("BASELINE_NEUTRAL_MIN_OUT");
        BASELINE_NEUTRAL_MIN_OUT = BASELINE_NEUTRAL_MIN_OUT.asNumber();

        BASELINE_NEUTRAL_MAX_IN = await getConfigItem("BASELINE_NEUTRAL_MAX_IN");
        BASELINE_NEUTRAL_MAX_IN = BASELINE_NEUTRAL_MAX_IN.asNumber();

        BASELINE_NEUTRAL_MAX_OUT = await getConfigItem("BASELINE_NEUTRAL_MAX_OUT");
        BASELINE_NEUTRAL_MAX_OUT = BASELINE_NEUTRAL_MAX_OUT.asNumber();

        BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_NEUTRAL_CERTAINTY_THRESHOLD");
        BASELINE_NEUTRAL_CERTAINTY_THRESHOLD = BASELINE_NEUTRAL_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_EMOTIONAL_MIN_IN = await getConfigItem("BASELINE_EMOTIONAL_MIN_IN");
        BASELINE_EMOTIONAL_MIN_IN = BASELINE_EMOTIONAL_MIN_IN.asNumber();

        BASELINE_EMOTIONAL_MIN_OUT = await getConfigItem("BASELINE_EMOTIONAL_MIN_OUT");
        BASELINE_EMOTIONAL_MIN_OUT = BASELINE_EMOTIONAL_MIN_OUT.asNumber();

        BASELINE_EMOTIONAL_MAX_IN = await getConfigItem("BASELINE_EMOTIONAL_MAX_IN");
        BASELINE_EMOTIONAL_MAX_IN = BASELINE_EMOTIONAL_MAX_IN.asNumber();

        BASELINE_EMOTIONAL_MAX_OUT = await getConfigItem("BASELINE_EMOTIONAL_MAX_OUT");
        BASELINE_EMOTIONAL_MAX_OUT = BASELINE_EMOTIONAL_MAX_OUT.asNumber();

        BASELINE_SADNESS_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_SADNESS_CERTAINTY_THRESHOLD");
        BASELINE_SADNESS_CERTAINTY_THRESHOLD = BASELINE_SADNESS_CERTAINTY_THRESHOLD.asNumber();

        BASELINE_ANGRY_CERTAINTY_THRESHOLD = await getConfigItem("BASELINE_ANGRY_CERTAINTY_THRESHOLD");
        BASELINE_ANGRY_CERTAINTY_THRESHOLD = BASELINE_ANGRY_CERTAINTY_THRESHOLD.asNumber();

        VALENCE_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("VALENCE_CONVERSION_FACTOR_HAPPINESS_V2", "number");
        ENERGY_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("ENERGY_CONVERSION_FACTOR_NEUTRAL_V2", "number");
        ENERGY_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("ENERGY_CONVERSION_FACTOR_HAPPINESS_V2", "number");


        ENERGY_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("ENERGY_CONVERSION_FACTOR_DISGUSTED", "number");
        ENERGY_CONVERSION_FACTOR_SAD = await getConfigItem("ENERGY_CONVERSION_FACTOR_SAD", "number");
        ENERGY_CONVERSION_FACTOR_ANGRY = await getConfigItem("ENERGY_CONVERSION_FACTOR_ANGRY", "number");
        ENERGY_CONVERSION_FACTOR_FEARFUL = await getConfigItem("ENERGY_CONVERSION_FACTOR_FEARFUL", "number");
        ENERGY_CONVERSION_FACTOR_SURPRISED = await getConfigItem("ENERGY_CONVERSION_FACTOR_SURPRISED", "number");
        VALENCE_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("VALENCE_CONVERSION_FACTOR_NEUTRAL", "number");
        VALENCE_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("VALENCE_CONVERSION_FACTOR_DISGUSTED", "number");
        VALENCE_CONVERSION_FACTOR_SAD = await getConfigItem("VALENCE_CONVERSION_FACTOR_SAD", "number");
        VALENCE_CONVERSION_FACTOR_ANGRY = await getConfigItem("VALENCE_CONVERSION_FACTOR_ANGRY", "number");
        VALENCE_CONVERSION_FACTOR_FEARFUL = await getConfigItem("VALENCE_CONVERSION_FACTOR_FEARFUL", "number");
        VALENCE_CONVERSION_FACTOR_SURPRISED = await getConfigItem("VALENCE_CONVERSION_FACTOR_SURPRISED", "number");

        BASELINE_ANGRY_MIN_IN = await getConfigItem("BASELINE_ANGRY_MIN_IN");
        BASELINE_ANGRY_MIN_IN = BASELINE_ANGRY_MIN_IN.asNumber();

        BASELINE_ANGRY_MAX_IN = await getConfigItem("BASELINE_ANGRY_MAX_IN");
        BASELINE_ANGRY_MAX_IN = BASELINE_ANGRY_MAX_IN.asNumber();

        BASELINE_ANGRY_MAX_OUT = await getConfigItem("BASELINE_ANGRY_MAX_OUT");
        BASELINE_ANGRY_MAX_OUT = BASELINE_ANGRY_MAX_OUT.asNumber();

        BASELINE_ANGRY_MIN_OUT = await getConfigItem("BASELINE_ANGRY_MIN_OUT");
        BASELINE_ANGRY_MIN_OUT = BASELINE_ANGRY_MIN_OUT.asNumber();

        BASELINE_SAD_MIN_IN = await getConfigItem("BASELINE_SAD_MIN_IN");
        BASELINE_SAD_MIN_IN = BASELINE_SAD_MIN_IN.asNumber();

        BASELINE_SAD_MIN_OUT = await getConfigItem("BASELINE_SAD_MIN_OUT");
        BASELINE_SAD_MIN_OUT = BASELINE_SAD_MIN_OUT.asNumber();

        BASELINE_SAD_MAX_IN = await getConfigItem("BASELINE_SAD_MAX_IN");
        BASELINE_SAD_MAX_IN = BASELINE_SAD_MAX_IN.asNumber();

        BASELINE_SAD_MAX_OUT = await getConfigItem("BASELINE_SAD_MAX_OUT");
        BASELINE_SAD_MAX_OUT = BASELINE_SAD_MAX_OUT.asNumber();

        BASELINE_FEARFUL_MIN_IN = await getConfigItem("BASELINE_FEARFUL_MIN_IN");
        BASELINE_FEARFUL_MIN_IN = BASELINE_FEARFUL_MIN_IN.asNumber();

        BASELINE_FEARFUL_MIN_OUT = await getConfigItem("BASELINE_FEARFUL_MIN_OUT");
        BASELINE_FEARFUL_MIN_OUT = BASELINE_FEARFUL_MIN_OUT.asNumber();

        BASELINE_FEARFUL_MAX_IN = await getConfigItem("BASELINE_FEARFUL_MAX_IN");
        BASELINE_FEARFUL_MAX_IN = BASELINE_FEARFUL_MAX_IN.asNumber();

        BASELINE_FEARFUL_MAX_OUT = await getConfigItem("BASELINE_FEARFUL_MAX_OUT");
        BASELINE_FEARFUL_MAX_OUT = BASELINE_FEARFUL_MAX_OUT.asNumber();

        SAD_MIN_IN = await getConfigItem("SAD_MIN_IN");
        SAD_MIN_IN = SAD_MIN_IN.asNumber();

        SAD_MIN_OUT = await getConfigItem("SAD_MIN_OUT");
        SAD_MIN_OUT = SAD_MIN_OUT.asNumber();

        SAD_MAX_IN = await getConfigItem("SAD_MAX_IN");
        SAD_MAX_IN = SAD_MAX_IN.asNumber();

        SAD_MAX_OUT = await getConfigItem("SAD_MAX_OUT");
        SAD_MAX_OUT = SAD_MAX_OUT.asNumber();

        ANGRY_MIN_IN = await getConfigItem("ANGRY_MIN_IN");
        ANGRY_MIN_IN = ANGRY_MIN_IN.asNumber();

        ANGRY_MIN_OUT = await getConfigItem("ANGRY_MIN_OUT");
        ANGRY_MIN_OUT = ANGRY_MIN_OUT.asNumber();

        ANGRY_MAX_IN = await getConfigItem("ANGRY_MAX_IN");
        ANGRY_MAX_IN = ANGRY_MAX_IN.asNumber();

        ANGRY_MAX_OUT = await getConfigItem("ANGRY_MAX_OUT");
        ANGRY_MAX_OUT = ANGRY_MAX_OUT.asNumber();

        FEARFUL_MIN_IN = await getConfigItem("FEARFUL_MIN_IN");
        FEARFUL_MIN_IN = FEARFUL_MIN_IN.asNumber();

        FEARFUL_MIN_OUT = await getConfigItem("FEARFUL_MIN_OUT");
        FEARFUL_MIN_OUT = FEARFUL_MIN_OUT.asNumber();

        FEARFUL_MAX_IN = await getConfigItem("FEARFUL_MAX_IN");
        FEARFUL_MAX_IN = FEARFUL_MAX_IN.asNumber();

        FEARFUL_MAX_OUT = await getConfigItem("FEARFUL_MAX_OUT");
        FEARFUL_MAX_OUT = FEARFUL_MAX_OUT.asNumber();

        DISGUSTED_MIN_IN = await getConfigItem("DISGUSTED_MIN_IN");
        DISGUSTED_MIN_IN = DISGUSTED_MIN_IN.asNumber();

        DISGUSTED_MIN_OUT = await getConfigItem("DISGUSTED_MIN_OUT");
        DISGUSTED_MIN_OUT = DISGUSTED_MIN_OUT.asNumber();

        DISGUSTED_MAX_IN = await getConfigItem("DISGUSTED_MAX_IN");
        DISGUSTED_MAX_IN = DISGUSTED_MAX_IN.asNumber();

        DISGUSTED_MAX_OUT = await getConfigItem("DISGUSTED_MAX_OUT");
        DISGUSTED_MAX_OUT = DISGUSTED_MAX_OUT.asNumber();

        SURPRISED_MIN_IN = await getConfigItem("SURPRISED_MIN_IN");
        SURPRISED_MIN_IN = SURPRISED_MIN_IN.asNumber();

        SURPRISED_MIN_OUT = await getConfigItem("SURPRISED_MIN_OUT");
        SURPRISED_MIN_OUT = SURPRISED_MIN_OUT.asNumber();

        SURPRISED_MAX_IN = await getConfigItem("SURPRISED_MAX_IN");
        SURPRISED_MAX_IN = SURPRISED_MAX_IN.asNumber();

        SURPRISED_MAX_OUT = await getConfigItem("SURPRISED_MAX_OUT");
        SURPRISED_MAX_OUT = SURPRISED_MAX_OUT.asNumber();

        WELLBEING_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("WELLBEING_CONVERSION_FACTOR_HAPPINESS", "number");
        WELLBEING_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("WELLBEING_CONVERSION_FACTOR_NEUTRAL", "number");
        WELLBEING_CONVERSION_FACTOR_ANGRY = await getConfigItem("WELLBEING_CONVERSION_FACTOR_ANGRY", "number");
        WELLBEING_CONVERSION_FACTOR_FEAR = await getConfigItem("WELLBEING_CONVERSION_FACTOR_FEAR", "number");
        WELLBEING_CONVERSION_FACTOR_SAD = await getConfigItem("WELLBEING_CONVERSION_FACTOR_SAD", "number");
        WELLBEING_CONVERSION_FACTOR_SURPRISED = await getConfigItem("WELLBEING_CONVERSION_FACTOR_SURPRISED", "number");
        WELLBEING_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("WELLBEING_CONVERSION_FACTOR_DISGUSTED", "number");

        STRESS_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("STRESS_CONVERSION_FACTOR_HAPPINESS", "number");
        STRESS_CONVERSION_FACTOR_SAD = await getConfigItem("STRESS_CONVERSION_FACTOR_SAD", "number");
        STRESS_CONVERSION_FACTOR_SURPRISED = await getConfigItem("STRESS_CONVERSION_FACTOR_SURPRISED", "number");
        STRESS_CONVERSION_FACTOR_FEAR = await getConfigItem("STRESS_CONVERSION_FACTOR_FEAR", "number");
        STRESS_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("STRESS_CONVERSION_FACTOR_DISGUSTED", "number");
        STRESS_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("STRESS_CONVERSION_FACTOR_NEUTRAL", "number");
        STRESS_CONVERSION_FACTOR_ANGRY = await getConfigItem("STRESS_CONVERSION_FACTOR_ANGRY", "number");

        INTEREST_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("INTEREST_CONVERSION_FACTOR_HAPPINESS", "number");
        INTEREST_CONVERSION_FACTOR_SAD = await getConfigItem("INTEREST_CONVERSION_FACTOR_SAD", "number");
        INTEREST_CONVERSION_FACTOR_SURPRISED = await getConfigItem("INTEREST_CONVERSION_FACTOR_SURPRISED", "number");
        INTEREST_CONVERSION_FACTOR_FEAR = await getConfigItem("INTEREST_CONVERSION_FACTOR_FEAR", "number");
        INTEREST_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("INTEREST_CONVERSION_FACTOR_DISGUSTED", "number");
        INTEREST_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("INTEREST_CONVERSION_FACTOR_NEUTRAL", "number");
        INTEREST_CONVERSION_FACTOR_ANGRY = await getConfigItem("INTEREST_CONVERSION_FACTOR_ANGRY", "number");

        ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS", "number");
        ENGAGEMENT_CONVERSION_FACTOR_SAD = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_SAD", "number");
        ENGAGEMENT_CONVERSION_FACTOR_SURPRISED = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_SURPRISED", "number");
        ENGAGEMENT_CONVERSION_FACTOR_FEAR = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_FEAR", "number");
        ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED", "number");
        ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL", "number");
        ENGAGEMENT_CONVERSION_FACTOR_ANGRY = await getConfigItem("ENGAGEMENT_CONVERSION_FACTOR_ANGRY", "number");

        STRESS_HIGH = await getConfigItem("STRESS_HIGH");
        STRESS_HIGH = STRESS_HIGH.asNumber();

        STRESS_MEDIUM = await getConfigItem("STRESS_MEDIUM");
        STRESS_MEDIUM = STRESS_MEDIUM.asNumber();

        STRESS_LOW = await getConfigItem("STRESS_LOW");
        STRESS_LOW = STRESS_LOW.asNumber();

        INTEREST_HIGH = await getConfigItem("INTEREST_HIGH", "number");
        INTEREST_MEDIUM = await getConfigItem("INTEREST_MEDIUM", "number");
        INTEREST_LOW = await getConfigItem("INTEREST_LOW", "number");

        ENGAGEMENT_HIGH = await getConfigItem("ENGAGEMENT_HIGH", "number");
        ENGAGEMENT_MEDIUM = await getConfigItem("ENGAGEMENT_MEDIUM", "number");
        ENGAGEMENT_LOW = await getConfigItem("ENGAGEMENT_LOW", "number");

        WELLBEING_HIGH = await getConfigItem("WELLBEING_HIGH");
        WELLBEING_HIGH = WELLBEING_HIGH.asNumber();

        WELLBEING_MEDIUM = await getConfigItem("WELLBEING_MEDIUM");
        WELLBEING_MEDIUM = WELLBEING_MEDIUM.asNumber();

        MOOD_HIGH = await getConfigItem("MOOD_HIGH");
        MOOD_HIGH = MOOD_HIGH.asNumber();

        MOOD_MEDIUM = await getConfigItem("MOOD_MEDIUM");
        MOOD_MEDIUM = MOOD_MEDIUM.asNumber();

        WIDGETS_UPDATE_SECONDS_DEMO = await getConfigItem("WIDGETS_UPDATE_SECONDS_DEMO");
        WIDGETS_UPDATE_SECONDS_DEMO = WIDGETS_UPDATE_SECONDS_DEMO.asNumber();

        DEMO_FRAME_RATE = await getConfigItem("DEMO_FRAME_RATE");
        DEMO_FRAME_RATE = DEMO_FRAME_RATE.asNumber();

        MASK_ON_CERTAINTY_THRESHOLD = await getConfigItem("MASK_ON_CERTAINTY_THRESHOLD");
        MASK_ON_CERTAINTY_THRESHOLD = MASK_ON_CERTAINTY_THRESHOLD.asNumber();


        YAWN_CERTAINTY_THRESHOLD = await getConfigItem("YAWN_CERTAINTY_THRESHOLD");
        YAWN_CERTAINTY_THRESHOLD = YAWN_CERTAINTY_THRESHOLD.asNumber();

        YAWN_TIREDNESS_TIME_THRESHOLD = await getConfigItem("YAWN_TIREDNESS_TIME_THRESHOLD");
        YAWN_TIREDNESS_TIME_THRESHOLD = YAWN_TIREDNESS_TIME_THRESHOLD.asNumber();

        EYE_CLOSED_CERTAINTY_THRESHOLD = await getConfigItem("EYE_CLOSED_CERTAINTY_THRESHOLD");
        EYE_CLOSED_CERTAINTY_THRESHOLD = EYE_CLOSED_CERTAINTY_THRESHOLD.asNumber();

        EYE_CLOSED_ASLEEP_TIME_THRESHOLD = await getConfigItem("EYE_CLOSED_ASLEEP_TIME_THRESHOLD");
        EYE_CLOSED_ASLEEP_TIME_THRESHOLD = EYE_CLOSED_ASLEEP_TIME_THRESHOLD.asNumber();

        EYE_BLINKED_CERTAINTY_THRESHOLD = await getConfigItem("EYE_BLINKED_CERTAINTY_THRESHOLD");
        EYE_BLINKED_CERTAINTY_THRESHOLD = EYE_BLINKED_CERTAINTY_THRESHOLD.asNumber();

        FACE_SIMILARITY_THRESHOLD = await getConfigItem("FACE_SIMILARITY_THRESHOLD");
        FACE_SIMILARITY_THRESHOLD = FACE_SIMILARITY_THRESHOLD.asNumber();
        FACE_CERTAINTY_FRAMES = await getConfigItem("FACE_CERTAINTY_FRAMES");
        FACE_CERTAINTY_FRAMES = FACE_CERTAINTY_FRAMES.asNumber();

        DETECTION_SCORE_THRESHOLD = await getConfigItem("DETECTION_SCORE_THRESHOLD");
        DETECTION_SCORE_THRESHOLD = DETECTION_SCORE_THRESHOLD.asNumber();

        GPT_DEMO_AVATAR_URL = await getConfigItem("GPT_DEMO_AVATAR_URL");
        GPT_DEMO_AVATAR_URL = GPT_DEMO_AVATAR_URL.asString();
        AVATAR_IDLE_URL = await getConfigItem("AVATAR_IDLE_URL");
        AVATAR_IDLE_URL = AVATAR_IDLE_URL.asString();

        AVATAR_IDLE_URL_FEMALE = await getConfigItem("AVATAR_IDLE_URL_FEMALE");
        AVATAR_IDLE_URL_FEMALE = AVATAR_IDLE_URL_FEMALE.asString();

        GPT_DEMO_CHECKUP_TIME = await getConfigItem("GPT_DEMO_CHECKUP_TIME");
        GPT_DEMO_CHECKUP_TIME = GPT_DEMO_CHECKUP_TIME.asNumber();

        GPT_DEMO_VOICE_TYPE_JP = await getConfigItem("GPT_DEMO_VOICE_TYPE_JP");
        GPT_DEMO_VOICE_TYPE_JP = GPT_DEMO_VOICE_TYPE_JP.asString();

        GPT_DEMO_VOICE_TYPE_EN = await getConfigItem("GPT_DEMO_VOICE_TYPE_EN");
        GPT_DEMO_VOICE_TYPE_EN = GPT_DEMO_VOICE_TYPE_EN.asString();

        GPT_DEMO_VOICE_TYPE_JP_FEMALE = await getConfigItem("GPT_DEMO_VOICE_TYPE_JP_FEMALE");
        GPT_DEMO_VOICE_TYPE_JP_FEMALE = GPT_DEMO_VOICE_TYPE_JP_FEMALE.asString();

        GPT_DEMO_VOICE_TYPE_EN_FEMALE = await getConfigItem("GPT_DEMO_VOICE_TYPE_EN_FEMALE");
        GPT_DEMO_VOICE_TYPE_EN_FEMALE = GPT_DEMO_VOICE_TYPE_EN_FEMALE.asString();

        HIGH_STRESS_ALERT_THRESHOLD = await getConfigItem("HIGH_STRESS_ALERT_THRESHOLD");
        HIGH_STRESS_ALERT_THRESHOLD = HIGH_STRESS_ALERT_THRESHOLD.asNumber();
        YAW_ANGLE_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_ALERT_THRESHOLD");
        YAW_ANGLE_ALERT_THRESHOLD = YAW_ANGLE_ALERT_THRESHOLD.asNumber();
        PITCH_ANGLE_MIN_ALERT_THRESHOLD = await getConfigItem("PITCH_ANGLE_MIN_ALERT_THRESHOLD");
        PITCH_ANGLE_MIN_ALERT_THRESHOLD = PITCH_ANGLE_MIN_ALERT_THRESHOLD.asNumber();
        PITCH_ANGLE_MAX_ALERT_THRESHOLD = await getConfigItem("PITCH_ANGLE_MAX_ALERT_THRESHOLD");
        PITCH_ANGLE_MAX_ALERT_THRESHOLD = PITCH_ANGLE_MAX_ALERT_THRESHOLD.asNumber();

        HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS = await getConfigItem("HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS");
        HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS = HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS.asNumber();

        DETECTION_SCORE_THRESHOLD_SDK_BILLING = await getConfigItem("DETECTION_SCORE_THRESHOLD_SDK_BILLING");
        DETECTION_SCORE_THRESHOLD_SDK_BILLING = DETECTION_SCORE_THRESHOLD_SDK_BILLING.asNumber();

        PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION = await getConfigItem("PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION");
        PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION = PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION.asNumber();

        HAND_POINTS_ON_FACE_THRESHOLD = await getConfigItem("HAND_POINTS_ON_FACE_THRESHOLD");
        HAND_POINTS_ON_FACE_THRESHOLD = HAND_POINTS_ON_FACE_THRESHOLD.asNumber();


        YAW_ANGLE_MIN_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_MIN_ALERT_THRESHOLD");
        YAW_ANGLE_MIN_ALERT_THRESHOLD = YAW_ANGLE_MIN_ALERT_THRESHOLD.asNumber();
        YAW_ANGLE_MAX_ALERT_THRESHOLD = await getConfigItem("YAW_ANGLE_MAX_ALERT_THRESHOLD");
        YAW_ANGLE_MAX_ALERT_THRESHOLD = YAW_ANGLE_MAX_ALERT_THRESHOLD.asNumber();
        PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD");
        PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD.asNumber();
        PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD");
        PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD.asNumber();
        YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD");
        YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD.asNumber();
        YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD");
        YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD.asNumber();
        ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = await getConfigItem("ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD");
        ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD = ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD.asNumber();
        ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = await getConfigItem("ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD");
        ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD = ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD.asNumber();

        HEAD_ANGLE_EMOTION_FILTER_ACTIVE = await getConfigItem("HEAD_ANGLE_EMOTION_FILTER_ACTIVE");
        HEAD_ANGLE_EMOTION_FILTER_ACTIVE = HEAD_ANGLE_EMOTION_FILTER_ACTIVE.asBoolean();

        ATTENTION_MIN_THRESHOLD = await getConfigItem("ATTENTION_MIN_THRESHOLD");
        ATTENTION_MIN_THRESHOLD = ATTENTION_MIN_THRESHOLD.asNumber();

        BLINK_FREQUENCY_DURATION_SECONDS = await getConfigItem("BLINK_FREQUENCY_DURATION_SECONDS");
        BLINK_FREQUENCY_DURATION_SECONDS = BLINK_FREQUENCY_DURATION_SECONDS.asNumber();

        BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS = await getConfigItem("BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS");
        BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS = BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS.asNumber();

        FILTER_BOX_SIZE = await getConfigItem("FILTER_BOX_SIZE", "object");

        UNITS_REPORTING_INTERVAL_MIN = await getConfigItem("UNITS_REPORTING_INTERVAL_MIN");
        UNITS_REPORTING_INTERVAL_MIN = UNITS_REPORTING_INTERVAL_MIN.asNumber();
        UNITS_REPORTING_INTERVAL_MAX = await getConfigItem("UNITS_REPORTING_INTERVAL_MAX");
        UNITS_REPORTING_INTERVAL_MAX = UNITS_REPORTING_INTERVAL_MAX.asNumber();

        listenToModulesThresholds(updateModules)
        let {modules} = await getThresholds()
        updateModules(modules)


    })
    .catch((err) => {
        console.error(err);
    });

const updateModules = (modules) => {
    console.log("updateModules", modules)
    modulesConfig = {

        ENERGY_CONVERSION_FACTOR_NEUTRAL_V2: Number(modules.energy.neutral),
        ENERGY_CONVERSION_FACTOR_HAPPINESS_V2: Number(modules.energy.happiness),
        ENERGY_CONVERSION_FACTOR_DISGUSTED: Number(modules.energy.disgusted),
        ENERGY_CONVERSION_FACTOR_SAD: Number(modules.energy.sad),
        ENERGY_CONVERSION_FACTOR_ANGRY: Number(modules.energy.angry),
        ENERGY_CONVERSION_FACTOR_FEARFUL: Number(modules.energy.fearful),
        ENERGY_CONVERSION_FACTOR_SURPRISED: Number(modules.energy.surprised),
        VALENCE_CONVERSION_FACTOR_NEUTRAL: Number(modules.mood.neutral),
        VALENCE_CONVERSION_FACTOR_HAPPINESS_V2: Number(modules.mood.happiness),
        VALENCE_CONVERSION_FACTOR_DISGUSTED: Number(modules.mood.disgusted),
        VALENCE_CONVERSION_FACTOR_SAD: Number(modules.mood.sad),
        VALENCE_CONVERSION_FACTOR_ANGRY: Number(modules.mood.angry),
        VALENCE_CONVERSION_FACTOR_FEARFUL: Number(modules.mood.fearful),
        VALENCE_CONVERSION_FACTOR_SURPRISED: Number(modules.mood.surprised),


        STRESS_CONVERSION_FACTOR_ANGRY: Number(modules.stress.angry),
        STRESS_CONVERSION_FACTOR_FEAR: Number(modules.stress.fearful),
        STRESS_CONVERSION_FACTOR_NEUTRAL: Number(modules.stress.neutral),
        STRESS_CONVERSION_FACTOR_HAPPINESS: Number(modules.stress.happiness),
        STRESS_CONVERSION_FACTOR_SAD: Number(modules.stress.sad),
        STRESS_CONVERSION_FACTOR_SURPRISED: Number(modules.stress.surprised),
        STRESS_CONVERSION_FACTOR_DISGUSTED: Number(modules.stress.disgusted),

        WELLBEING_CONVERSION_FACTOR_HAPPINESS: Number(modules.wellbeing.happiness),
        WELLBEING_CONVERSION_FACTOR_NEUTRAL: Number(modules.wellbeing.neutral),
        WELLBEING_CONVERSION_FACTOR_ANGRY: Number(modules.wellbeing.angry),
        WELLBEING_CONVERSION_FACTOR_FEAR: Number(modules.wellbeing.fearful),
        WELLBEING_CONVERSION_FACTOR_SAD: Number(modules.wellbeing.sad),
        WELLBEING_CONVERSION_FACTOR_SURPRISED: Number(modules.wellbeing.surprised),
        WELLBEING_CONVERSION_FACTOR_DISGUSTED: Number(modules.wellbeing.disgusted),
        ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS: Number(modules.engagement.happiness),
        ENGAGEMENT_CONVERSION_FACTOR_SAD: Number(modules.engagement.sad),
        ENGAGEMENT_CONVERSION_FACTOR_SURPRISED: Number(modules.engagement.surprised),
        ENGAGEMENT_CONVERSION_FACTOR_FEAR: Number(modules.engagement.fearful),
        ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED: Number(modules.engagement.disgusted),
        ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL: Number(modules.engagement.neutral),
        ENGAGEMENT_CONVERSION_FACTOR_ANGRY: Number(modules.engagement.angry),
        INTEREST_CONVERSION_FACTOR_HAPPINESS: Number(modules.interest.happiness),
        INTEREST_CONVERSION_FACTOR_SAD: Number(modules.interest.sad),
        INTEREST_CONVERSION_FACTOR_SURPRISED: Number(modules.interest.surprised),
        INTEREST_CONVERSION_FACTOR_FEAR: Number(modules.interest.fearful),
        INTEREST_CONVERSION_FACTOR_DISGUSTED: Number(modules.interest.disgusted),
        INTEREST_CONVERSION_FACTOR_NEUTRAL: Number(modules.interest.neutral),
        INTEREST_CONVERSION_FACTOR_ANGRY: Number(modules.interest.angry),
    };
    initConfig(modulesConfig);
    isOriginal = false
}

export const updateConfig = (maskMode) => {
    if (maskMode) {
        if (!isOriginal) {
            return
        } else {
            initConfig(modulesConfig);
            isOriginal = false
        }
    } else {
        if (isOriginal) {
            return
        } else {
            resetConfig()
        }
    }
}


export const resetConfig = () => {
    initConfig({

        ENERGY_CONVERSION_FACTOR_NEUTRAL_V2: ENERGY_CONVERSION_FACTOR_NEUTRAL,
        ENERGY_CONVERSION_FACTOR_HAPPINESS_V2: ENERGY_CONVERSION_FACTOR_HAPPINESS,
        ENERGY_CONVERSION_FACTOR_DISGUSTED,
        ENERGY_CONVERSION_FACTOR_SAD,
        ENERGY_CONVERSION_FACTOR_ANGRY,
        ENERGY_CONVERSION_FACTOR_FEARFUL,
        ENERGY_CONVERSION_FACTOR_SURPRISED,
        VALENCE_CONVERSION_FACTOR_NEUTRAL,
        VALENCE_CONVERSION_FACTOR_HAPPINESS_V2: VALENCE_CONVERSION_FACTOR_HAPPINESS,
        VALENCE_CONVERSION_FACTOR_DISGUSTED,
        VALENCE_CONVERSION_FACTOR_SAD,
        VALENCE_CONVERSION_FACTOR_ANGRY,
        VALENCE_CONVERSION_FACTOR_FEARFUL,
        VALENCE_CONVERSION_FACTOR_SURPRISED,


        STRESS_CONVERSION_FACTOR_ANGRY,
        STRESS_CONVERSION_FACTOR_FEAR,
        STRESS_CONVERSION_FACTOR_NEUTRAL,
        STRESS_CONVERSION_FACTOR_HAPPINESS,
        STRESS_CONVERSION_FACTOR_SAD,
        STRESS_CONVERSION_FACTOR_SURPRISED,
        STRESS_CONVERSION_FACTOR_DISGUSTED,

        WELLBEING_CONVERSION_FACTOR_HAPPINESS,
        WELLBEING_CONVERSION_FACTOR_NEUTRAL,
        WELLBEING_CONVERSION_FACTOR_ANGRY,
        WELLBEING_CONVERSION_FACTOR_FEAR,
        WELLBEING_CONVERSION_FACTOR_SAD,
        WELLBEING_CONVERSION_FACTOR_SURPRISED,
        WELLBEING_CONVERSION_FACTOR_DISGUSTED,
        ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS,
        ENGAGEMENT_CONVERSION_FACTOR_SAD,
        ENGAGEMENT_CONVERSION_FACTOR_SURPRISED,
        ENGAGEMENT_CONVERSION_FACTOR_FEAR,
        ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED,
        ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL,
        ENGAGEMENT_CONVERSION_FACTOR_ANGRY,
        INTEREST_CONVERSION_FACTOR_HAPPINESS,
        INTEREST_CONVERSION_FACTOR_SAD,
        INTEREST_CONVERSION_FACTOR_SURPRISED,
        INTEREST_CONVERSION_FACTOR_FEAR,
        INTEREST_CONVERSION_FACTOR_DISGUSTED,
        INTEREST_CONVERSION_FACTOR_NEUTRAL,
        INTEREST_CONVERSION_FACTOR_ANGRY
    })
    isOriginal = true
}

const getConfigItem = async (key, type) => {
    let item = await remoteConfig.getValue(key);
    switch (type) {
        case "number":
            return item.asNumber();
        case "boolean":
            return item.asBoolean();
        case "object":
            return JSON.parse(item.asString());
        default:
            return item;

    }
};

export {
    HAPPINESS_MIN_IN,
    HAPPINESS_MIN_OUT,
    HAPPINESS_MAX_IN,
    HAPPINESS_MAX_OUT,
    HAPPINESS_CERTAINTY_THRESHOLD,
    NEUTRAL_MIN_IN,
    NEUTRAL_MIN_OUT,
    NEUTRAL_MAX_IN,
    NEUTRAL_MAX_OUT,
    NEUTRAL_CERTAINTY_THRESHOLD,
    EMOTIONAL_MIN_IN,
    EMOTIONAL_MIN_OUT,
    EMOTIONAL_MAX_IN,
    EMOTIONAL_MAX_OUT,
    SADNESS_CERTAINTY_THRESHOLD,
    ANGRY_CERTAINTY_THRESHOLD,
    SOLO_WEB_PASSWORD,
    BASELINE_DURATION_SECONDS,
    INITIAL_PLAYLIST_SIZE,
    SOLO_WEB_FOOTER_TITLE_SECONDS,
    SOLO_WEB_SCANNING_SECS,
    BASELINE_HAPPINESS_MIN_IN,
    BASELINE_HAPPINESS_MIN_OUT,
    BASELINE_HAPPINESS_MAX_IN,
    BASELINE_HAPPINESS_MAX_OUT,
    BASELINE_HAPPINESS_CERTAINTY_THRESHOLD,
    BASELINE_NEUTRAL_MIN_IN,
    BASELINE_NEUTRAL_MIN_OUT,
    BASELINE_NEUTRAL_MAX_IN,
    BASELINE_NEUTRAL_MAX_OUT,
    BASELINE_NEUTRAL_CERTAINTY_THRESHOLD,
    BASELINE_EMOTIONAL_MIN_IN,
    BASELINE_EMOTIONAL_MIN_OUT,
    BASELINE_EMOTIONAL_MAX_IN,
    BASELINE_EMOTIONAL_MAX_OUT,
    BASELINE_SADNESS_CERTAINTY_THRESHOLD,
    BASELINE_ANGRY_CERTAINTY_THRESHOLD,
    BASELINE_SURPRISED_CERTAINTY_THRESHOLD,
    SURPRISED_CERTAINTY_THRESHOLD,
    BASELINE_FEARFUL_CERTAINTY_THRESHOLD,
    FEARFUL_CERTAINTY_THRESHOLD,
    BASELINE_DISGUSTED_CERTAINTY_THRESHOLD,
    DISGUSTED_CERTAINTY_THRESHOLD,
    VALENCE_CONVERSION_FACTOR_HAPPINESS,
    ENERGY_CONVERSION_FACTOR_NEUTRAL,
    ENERGY_CONVERSION_FACTOR_DISGUSTED,
    ENERGY_CONVERSION_FACTOR_SAD,
    ENERGY_CONVERSION_FACTOR_ANGRY,
    ENERGY_CONVERSION_FACTOR_FEARFUL,
    ENERGY_CONVERSION_FACTOR_SURPRISED,
    VALENCE_CONVERSION_FACTOR_NEUTRAL,
    VALENCE_CONVERSION_FACTOR_DISGUSTED,
    VALENCE_CONVERSION_FACTOR_SAD,
    VALENCE_CONVERSION_FACTOR_ANGRY,
    VALENCE_CONVERSION_FACTOR_FEARFUL,
    VALENCE_CONVERSION_FACTOR_SURPRISED,
    ENERGY_CONVERSION_FACTOR_HAPPINESS,
    BASELINE_ANGRY_MIN_IN,
    BASELINE_ANGRY_MAX_IN,
    BASELINE_ANGRY_MAX_OUT,
    BASELINE_ANGRY_MIN_OUT,
    BASELINE_DISGUSTED_MIN_IN,
    BASELINE_DISGUSTED_MAX_IN,
    BASELINE_DISGUSTED_MAX_OUT,
    BASELINE_DISGUSTED_MIN_OUT,
    BASELINE_SAD_MIN_IN,
    BASELINE_SAD_MIN_OUT,
    BASELINE_SAD_MAX_IN,
    BASELINE_SAD_MAX_OUT,
    BASELINE_FEARFUL_MIN_IN,
    BASELINE_FEARFUL_MIN_OUT,
    BASELINE_FEARFUL_MAX_IN,
    BASELINE_FEARFUL_MAX_OUT,
    BASELINE_SURPRISED_MIN_IN,
    BASELINE_SURPRISED_MIN_OUT,
    BASELINE_SURPRISED_MAX_IN,
    BASELINE_SURPRISED_MAX_OUT,
    SAD_MIN_IN,
    SAD_MIN_OUT,
    SAD_MAX_IN,
    SAD_MAX_OUT,
    ANGRY_MIN_IN,
    ANGRY_MIN_OUT,
    ANGRY_MAX_IN,
    ANGRY_MAX_OUT,
    FEARFUL_MIN_IN,
    FEARFUL_MIN_OUT,
    FEARFUL_MAX_IN,
    FEARFUL_MAX_OUT,
    DISGUSTED_MIN_IN,
    DISGUSTED_MIN_OUT,
    DISGUSTED_MAX_IN,
    DISGUSTED_MAX_OUT,
    SURPRISED_MIN_IN,
    SURPRISED_MIN_OUT,
    SURPRISED_MAX_IN,
    SURPRISED_MAX_OUT,
    WELLBEING_CONVERSION_FACTOR_HAPPINESS,
    WELLBEING_CONVERSION_FACTOR_NEUTRAL,
    WELLBEING_CONVERSION_FACTOR_ANGRY,
    WELLBEING_CONVERSION_FACTOR_FEAR,
    WELLBEING_CONVERSION_FACTOR_SAD,
    WELLBEING_CONVERSION_FACTOR_SURPRISED,
    WELLBEING_CONVERSION_FACTOR_DISGUSTED,
    STRESS_CONVERSION_FACTOR_HAPPINESS,
    STRESS_CONVERSION_FACTOR_SAD,
    STRESS_CONVERSION_FACTOR_SURPRISED,
    STRESS_CONVERSION_FACTOR_FEAR,
    STRESS_CONVERSION_FACTOR_DISGUSTED,
    STRESS_CONVERSION_FACTOR_NEUTRAL,
    STRESS_CONVERSION_FACTOR_ANGRY,
    STRESS_HIGH,
    STRESS_MEDIUM,
    STRESS_LOW,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    INTEREST_LOW,
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    ENGAGEMENT_LOW,
    INTEREST_CONVERSION_FACTOR_HAPPINESS,
    INTEREST_CONVERSION_FACTOR_SAD,
    INTEREST_CONVERSION_FACTOR_SURPRISED,
    INTEREST_CONVERSION_FACTOR_FEAR,
    INTEREST_CONVERSION_FACTOR_DISGUSTED,
    INTEREST_CONVERSION_FACTOR_NEUTRAL,
    INTEREST_CONVERSION_FACTOR_ANGRY,
    ENGAGEMENT_CONVERSION_FACTOR_HAPPINESS,
    ENGAGEMENT_CONVERSION_FACTOR_SAD,
    ENGAGEMENT_CONVERSION_FACTOR_SURPRISED,
    ENGAGEMENT_CONVERSION_FACTOR_FEAR,
    ENGAGEMENT_CONVERSION_FACTOR_DISGUSTED,
    ENGAGEMENT_CONVERSION_FACTOR_NEUTRAL,
    ENGAGEMENT_CONVERSION_FACTOR_ANGRY,
    WIDGETS_UPDATE_SECONDS_DEMO,
    WELLBEING_MEDIUM,
    MOOD_HIGH,
    WELLBEING_HIGH,
    MOOD_MEDIUM,
    DEMO_FRAME_RATE,
    MASK_ON_CERTAINTY_THRESHOLD,
    YAWN_CERTAINTY_THRESHOLD,
    YAWN_TIREDNESS_TIME_THRESHOLD,
    EYE_CLOSED_CERTAINTY_THRESHOLD,
    EYE_CLOSED_ASLEEP_TIME_THRESHOLD,
    EYE_BLINKED_CERTAINTY_THRESHOLD,
    FACE_SIMILARITY_THRESHOLD,
    FACE_CERTAINTY_FRAMES,
    DETECTION_SCORE_THRESHOLD,
    GPT_DEMO_AVATAR_URL,
    AVATAR_IDLE_URL,
    AVATAR_IDLE_URL_FEMALE,
    GPT_DEMO_CHECKUP_TIME,
    GPT_DEMO_VOICE_TYPE_JP,
    GPT_DEMO_VOICE_TYPE_EN,
    GPT_DEMO_VOICE_TYPE_JP_FEMALE,
    GPT_DEMO_VOICE_TYPE_EN_FEMALE,
    HIGH_STRESS_ALERT_THRESHOLD,
    YAW_ANGLE_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
    PITCH_ANGLE_MAX_ALERT_THRESHOLD,
    HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS,
    DETECTION_SCORE_THRESHOLD_SDK_BILLING,
    PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION,
    HAND_POINTS_ON_FACE_THRESHOLD,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    HEAD_ANGLE_EMOTION_FILTER_ACTIVE,
    ATTENTION_MIN_THRESHOLD,
    BLINK_FREQUENCY_DURATION_SECONDS,
    BLINK_FREQUENCY_FILTER_X_FIRST_SECONDS,
        FILTER_BOX_SIZE,
        UNITS_REPORTING_INTERVAL_MIN,
        UNITS_REPORTING_INTERVAL_MAX
}

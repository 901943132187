import {LEVELS} from "../../constants";
import colors from "../../styles/colors";
import {
    ENGAGEMENT_HIGH, ENGAGEMENT_MEDIUM,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    STRESS_HIGH,
    STRESS_LOW,
    STRESS_MEDIUM
} from "../../api/remoteConfig";
import {
    calcEnergy,
    calcInterest,
    calcStress,
    calcValence,
    calcWellbeing,
    calcEngagement,
    calcMoodV3, calcEnergyV3
} from "../../utils/bioFeedback";

export const getLevelColor = (level) => {
    switch (level) {
        case LEVELS.HIGH_STRESS:
        case LEVELS.DISLIKE:
        case LEVELS.LOW_ENGAGEMENT:
            return colors.tomato
        case LEVELS.MEDIUM:
        case LEVELS.NEUTRAL:
            return colors.yellowish
        case LEVELS.LOW_STRESS:
        case LEVELS.LIKE:
        case LEVELS.HIGH_ENGAGEMENT:
            return colors.green
        default:
            return colors.warm_grey
    }
}

export const calcStressLevel = (stress) => {
    let level
    if (stress > STRESS_HIGH) {
        level = LEVELS.HIGH_STRESS
    } else if (stress < STRESS_HIGH && stress > STRESS_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (stress < STRESS_MEDIUM && stress > STRESS_LOW) {
        level = LEVELS.LOW_STRESS
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return {value: level, color}
}

export const calcInterestLevel = (interest) => {
    let level
    if (interest > INTEREST_HIGH) {
        level = LEVELS.LIKE
    } else if (interest < INTEREST_HIGH && interest > INTEREST_MEDIUM) {
        level = LEVELS.NEUTRAL
    } else if (interest < INTEREST_MEDIUM) {
        level = LEVELS.DISLIKE
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return {value: level, color}
}

export const calcEngagementLevel = (engagement) => {
    let level
    if (engagement > ENGAGEMENT_HIGH) {
        level = LEVELS.HIGH_ENGAGEMENT
    } else if (engagement < ENGAGEMENT_HIGH && engagement > ENGAGEMENT_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (engagement < ENGAGEMENT_MEDIUM) {
        level = LEVELS.LOW_ENGAGEMENT
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return {value: level, color}
}

export const calcEnergyLevel = (energy) => {
    let level
    if (energy > ENGAGEMENT_HIGH) {
        level = LEVELS.HIGH_ENGAGEMENT
    } else if (energy < ENGAGEMENT_HIGH && energy > ENGAGEMENT_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (energy < ENGAGEMENT_MEDIUM) {
        level = LEVELS.LOW_ENGAGEMENT
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return {value: level, color}
}

export const calcValenceLevel = (valence) => {
    let level
    if (valence > ENGAGEMENT_HIGH) {
        level = LEVELS.HIGH_ENGAGEMENT
    } else if (valence < ENGAGEMENT_HIGH && valence > ENGAGEMENT_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (valence < ENGAGEMENT_MEDIUM) {
        level = LEVELS.LOW_ENGAGEMENT
    } else {
        level = LEVELS.NO
    }

    const color = getLevelColor(level)

    return {value: level, color}
}

export const calcWellbeingLevel = (wellbeing) => {
    let color, value = Math.round(normalizeValue(wellbeing) * 100)
    if (wellbeing >= 0.7) {
        color = colors.green
    } else if (wellbeing < 0.7 && wellbeing > 0.3) {
        color = colors.yellowish
    } else {
        color = colors.tomato
    }
    return {value, color}
}

export const normalizeValue = (value) => {
    if (value < 0) {
        return 0
    }
    if (value > 1) {
        return 1
    }
    return value
}

export const formatTime = (s) => {
    let m = Math.floor(s / 60);
    m = (m >= 10) ? m : "" + m;
    s = Math.floor(s % 60);
    s = (s >= 10) ? s : "0" + s;
    return m + ":" + s;
}

export const formatMiliseconds = (ms) => {
    let m = Math.floor(ms / 60000);
    m = (m >= 10) ? m : "" + m;
    let s = ((ms % 60000) / 1000).toFixed(0);
    s = (s >= 10) ? s : "0" + s
    return m + ":" + s;
}

export const detectEmotionColor = (expression) => {
    switch (expression) {
        case 'happy':
            return "#FFBD58"
        case 'neutral':
            return "#6FCF97"
        case 'sad':
            return "#EB5757"
        case 'angry':
            return "#000000"
        case 'disgusted':
            return "#828282"
        case 'fearful':
            return "#BB6BD9"
        case 'surprised':
            return "#56CCF2"
    }
}

export const getDominantEmotion = (detection) => {
    const expressionsArr = []

    for (const expression in detection.expressions) {
        if (typeof detection.expressions[expression] === "number") {
            const color = detectEmotionColor(expression)
            const value = Math.round(detection.expressions[expression] * 100)
            expressionsArr.push({label: expression, color, value})
        }
    }

    const maxObject = expressionsArr.reduce(function (prev, current) {
        return (prev.value > current.value) ? prev : current
    })

    return maxObject
}

export const getEmojiFilterValue = (dominantEmotion) => {
    if(dominantEmotion.value > .1){
        return dominantEmotion.label
    }else {
        return null
    }
}

export const getResults = (id, avg, filterEmotions) => {

    const valence =  filterEmotions ? null :calcValence(avg)
    const energy = filterEmotions ? null : calcEnergy(avg)

    const stress = filterEmotions ? null : calcStress(avg)
    const wellbeing = filterEmotions ? null : calcWellbeing(avg)
    const interest = filterEmotions ? null : calcInterest(avg)
    const engagement = filterEmotions ? null : calcEngagement(avg)

    const stressLevel = filterEmotions ? null : calcStressLevel(stress)
    const interestLevel = filterEmotions ? null : calcInterestLevel(interest)
    const engagementLevel = filterEmotions ? null : calcEngagementLevel(engagement)
    const wellbeingLevel = filterEmotions ? null : calcWellbeingLevel(wellbeing)
    const valenceLevel =  calcValenceLevel(valence)
    const energyLevel =  calcEnergyLevel(energy)


    return {
        valence,
        mood: valence,
        energy,
        stress,
        wellbeing,
        interest,
        engagement,
        stressLevel,
        interestLevel,
        engagementLevel,
        wellbeingLevel,
        valenceLevel,
        energyLevel,
        id
    }
}

import { loadTranslations, storeTranslations, getCurrentStoredTranslationsVersion, storeTranslationsSelectedLocale, getSelectedLocale } from "../api/AsyncStorage";
import { getTranslationsVersion, getTranslations } from "../api/api"
import _ from "lodash"

export const translations = {
    locales: {},
    version: null,
    selectedLocale: "en",
    supportedLanguages: [
        { key: "en", name: "EN", fullName: 'english' },
        { key: "ja", name: "日本語", fullName: 'japanese' },
    ],
    fallbackLocale: "en"
}

export const load = async () => {
    try {
        let currentVersion = await getCurrentStoredTranslationsVersion();
        let dbVersion = await getTranslationsVersion()
        console.log("load translation", { currentVersion, dbVersion })
        if (!currentVersion || dbVersion > Number(currentVersion)) {
            //load from database
            const { version, locales } = await getTranslations();
            await storeTranslations({ version: dbVersion.toString(), locales })
            translations.locales = locales;
            translations.version = version;
            return true;
        } else {
            //load from local storage
            const locales = await loadTranslations();
            const selectedLocale = await getSelectedLocale();
            translations.locales = locales;
            translations.version = dbVersion;
            translations.selectedLocale = selectedLocale;
            return true
        }
    } catch (e) {
        console.error("error loading translations", e)
        return false;
    }

}

export const setLocale = async (locale) => {
    translations.selectedLocale = locale
    await storeTranslationsSelectedLocale(locale)
    console.log("selected locale", translations.selectedLocale)
}

export const getString = (key) => {
    return _.get(translations.locales, `[${translations.selectedLocale}][${key}]`, _.get(translations.locales, `[${translations.fallbackLocale}][${key}]`, key));
}
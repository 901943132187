import React, {Component} from 'react';
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
import colors from '../styles/colors';
import _ from 'lodash'
import {Fragment} from 'react';
import {getString} from "../utils/translations";
import {MASK_ON_CERTAINTY_THRESHOLD, YAWN_CERTAINTY_THRESHOLD} from "../api/remoteConfig";
import EmojiFilter from "./EmojiFilter";
import {formatMiliseconds} from "../views/Demo/helpers";


class DetectionSquare extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
        }
    }

    toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    renderExtension = () => {

        const {
            stressLevel, engagementLevel, font, mask,
            noMask, yawn, closedEyes, alerts, noYawn, yawnRatio, headPose, frown, id,
            closedRightEye,
            closedLeftEye, openEyes,
            showId, score, alertsActive,
            eyesDirection,
            filterEmotions,
            showOnlyId
        } = this.props
        //   console.log("mask", mask, MASK_ON_CERTAINTY_THRESHOLD)

        //   console.log("DetectionSquare headPose", headPose)
        let tirednessAlert = false
        let asleepAlert = false
        let totalYawnTime = 0
        let leftEyeClosedTimeInARow = 0
        let rightEyeClosedTimeInARow = 0
        let blinkCount = 0
        let avgClosedEyesTime = 0
        let bpm = 0
        let bpts = 0
        let focusTime = 0
        let yawnPerMinute = 0;

        if (alerts) {
            tirednessAlert = alerts.tiredness.tired
            totalYawnTime = alerts.tiredness.totalYawnTime
            asleepAlert = alerts.asleep.asleep
            leftEyeClosedTimeInARow = alerts.asleep.leftEyeClosedTimeInARow
            rightEyeClosedTimeInARow = alerts.asleep.rightEyeClosedTimeInARow
            avgClosedEyesTime = alerts.asleep.avgClosedEye
            blinkCount = alerts.alert.blinkCount
            bpm = alerts.alert.bpm
            //  bpts = alerts.alert.bpts
            focusTime = alerts.alert.focusTime
            yawnPerMinute = alerts.alert.yawnPerMinute
        }

        if(showOnlyId){
            return (
                <MeterContainer>
                    <Title font={font ? "15" : "10"}>
                        {getString("id")}
                    </Title>
                    <Value font={font ? "15" : "10"}>
                        {id}
                    </Value>
                </MeterContainer>
            )
        }

        return (<Fragment>
            {showId && (<MeterContainer>
                <Title font={font ? "15" : "10"}>
                    {getString("id")}
                </Title>
                <Value font={font ? "15" : "10"}>
                    {id}
                </Value>
            </MeterContainer>)}


            <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    {getString("score")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {Math.round((score + Number.EPSILON) * 100) / 100}
                </Value>

            </MeterContainer>

            {process.env.REACT_APP_ENV === "lab" ? (
                <>
                    {/*<MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("blinks_per_minute")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {Math.round((bpm + Number.EPSILON) * 100) / 100}
                        </Value>

                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("yawnPerMinute")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {Math.round((yawnPerMinute + Number.EPSILON) * 100) / 100}
                        </Value>

                    </MeterContainer>
*/}


                    {/*   <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("focusTime")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {formatMiliseconds(focusTime)}
                        </Value>

                    </MeterContainer>*/}

                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            {getString("eyes_direction")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {eyesDirection && eyesDirection.x ? `X: ${Math.round(eyesDirection.x * 100)}, Y: ${Math.round(eyesDirection.y * 100)}` : "N/A"}
                        </Value>

                    </MeterContainer>

                    {/*  <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("closed_eyes_time")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {formatMiliseconds(avgClosedEyesTime)}
                        </Value>
                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("blinkCount")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {blinkCount}
                        </Value>

                    </MeterContainer>
*/}


                </>
            ) : null}

        </Fragment>)
    }

    renderAlert = () => {
        const {alerts} = this.props
        let alert = alerts && (alerts.alert.yawning || alerts.alert.highStress || alerts.alert.eyesClosed || !alerts.alert.headFacingCamera)
        let alertString = ""
        if (alert) {
            if (alerts.alert.yawning) {
                alertString = "alert_tired"
            } else if (alerts.alert.highStress) {
                alertString = "alert_stress"
            } else if (alerts.alert.eyesClosed) {
                alertString = "alert_asleep"
            } else if (!alerts.alert.headFacingCamera) {
                alertString = "alert_attention"
            }
        }
        if (alert) {
            return (
                <div
                    style={{position: "absolute", top: -30, left: 0, color: "#fff", font: "OpenSans", fontWeight: 800}}>
                    {getString(alertString)}
                </div>
            )
        }
    }

    renderEyeGaze = () => {

        const {width, height, leftIrisCenter, rightIrisCenter, eyesDirection} = this.props
        // Inside your DetectionSquare component's render method, before the return statement
        // Calculate pixel positions of the iris centers (assuming they are available)

        // Assuming eyesDirection might need to be inverted in the x-direction
        const correctedEyesDirection = {
            x: -eyesDirection.x, // Invert the x component
            y: eyesDirection.y, // Keep the y component as is if it's correct
        };

        // Example iris centers (replace with your actual calculated values)
        const leftIrisCenterPixel = leftIrisCenter;
        const rightIrisCenterPixel = rightIrisCenter;

        // Calculate line endpoints based on eyesDirection
        // Assuming eyesDirection is normalized and represents a direction vector
        const lineLength = 400; // Length of the line in pixels
        const leftLineEnd = {
            x: leftIrisCenterPixel.x + correctedEyesDirection.x * lineLength,
            y: leftIrisCenterPixel.y + correctedEyesDirection.y * lineLength,
        };
        const rightLineEnd = {
            x: rightIrisCenterPixel.x + correctedEyesDirection.x * lineLength,
            y: rightIrisCenterPixel.y + correctedEyesDirection.y * lineLength,
        };

        /*  console.log("render eye gaze", {
              leftIrisCenterPixel,
              rightIrisCenterPixel,
              leftLineEnd,
              rightLineEnd,
          } )
  */
// Now, in your component's JSX, you can use these calculations to render lines using SVG or canvas
// For example, using SVG:
        return (
            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                {/* Draw lines for left and right iris */}
                <line x1={leftIrisCenter.x} y1={leftIrisCenter.y} x2={leftLineEnd.x} y2={leftLineEnd.y} stroke="red"
                      strokeWidth="2"/>
                <line x1={rightIrisCenter.x} y1={rightIrisCenter.y} x2={rightLineEnd.x} y2={rightLineEnd.y} stroke="red"
                      strokeWidth="2"/>
            </svg>
        );
    }

    render() {

        let marginLeft = null

        const {
            top,
            left,
            width,
            height,
            label,
            percentage,
            progressColor,
            emphasize,
            extendedDetectionSquare,
            font,
            emojiFilter,
            alerts,
            alertsActive,
            leftIrisCenter,
            rightIrisCenter,
            filterEmotions,
            showOnlyId
        } = this.props
        const {open} = this.state
        if (width < 100) {
            marginLeft = (100 - width) / -2
        }

        let alert = alertsActive && alerts && (alerts.alert.yawning || alerts.alert.highStress || alerts.alert.eyesClosed || !alerts.alert.headFacingCamera)

        return (
            <div style={{position: 'absolute', top, left, zIndex: 999}}>
                {!showOnlyId && alertsActive && this.renderAlert()}
                <div style={{width, height}} onClick={this.toggle}>
                    <Square height={height} color={alert ? colors.red : emphasize ? colors.blue : colors.white}/>
                </div>
                {extendedDetectionSquare && open &&
                    <div style={{width: '100%', minWidth: 125, position: 'absolute', marginLeft, top: height}}>
                        {!filterEmotions && (
                            <div style={{width: "100%"}}>
                                <ProgressBar
                                    label={label}
                                    percentage={percentage}
                                    progressColor={progressColor}
                                    backgroundColor={"white"}
                                    width={63}
                                    font={font ? true : false}
                                    showValue={true}
                                    heightMargin={true}
                                />
                            </div>
                        )}
                        {this.renderExtension()}
                    </div>
                }
            </div>

        );
    }
}

DetectionSquare.defaultProps = {
    showId: true
}

const Square = styled.div`
    // width: 100%;
    height: ${props => props.height}px;
    border: 3px solid ${props => props.color};
    box-sizing: border-box;
`

const MeterContainer = styled.div`
    display: flex;
    background: white;
    height: 18px;
    position: relative;
`

const Title = styled.div`
    font-size: ${props => props.font}px;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    top: 0;
    margin-left: 5px;
    font-weight: 900;
`

const Value = styled.div`
    font-size: ${props => props.font}px;
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 5px;
    color: ${props => props.color};
`

export default DetectionSquare;

import React, { PureComponent } from 'react';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    Cell,
    ResponsiveContainer,
    ReferenceArea,
    Label
} from 'recharts';

import styled from "styled-components";
import { getString } from "../utils/translations";
import fonts from "../styles/fonts";
import { getAsset } from "../utils";


const CustomLabel = ({ value }) => {
    return (<span style={{}}>{value}</span>)
}

let labelStyle = {
    fontFamily: fonts.OpenSans,
    fontWeight: "300",
    fontSize: 20,
    color: "#909090",
    textTransform: "uppercase",
    fillOpacity: 0.4,
}

export default class ValenceEnergyScatter extends PureComponent {

    formatName = (name) => {

        return name === "x" ? `${getString("mood")}` : getString("energy")
    }

    formatValue = (value) => {
        /*  if(this.props.specialFormat){
              return `${Math.round(value)}%`
          }*/
        let number = Math.round(value * 100);
        if(number > 100){
            number = 100
        }
        return `${number}%`
    }

    renderTooltip = ({ active, payload, label }) => {

        const renderMetadata = (payload) => {
            if (payload.metadata) {
                if (payload.metadataFormatter) {
                    let metadataFormatter = payload.metadataFormatter(payload.metadata);
                    //   console.log("metadataFormatter", metadataFormatter)
                    return metadataFormatter
                }
                return payload.metadata
            }
            return null
        }

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: 'white', border: 'thin solid #999999', padding: 12, fontWeight: "400" }}>
                    <p className="intro" style={{ color: '#000000' }}>{renderMetadata(payload[0].payload)}</p>
                </div>
            );
        }

        return null;
    };

    renderCircle = (r) => {
        const {size} = this.props;
        let dims = size * 0.77;
        return (
            <div style={{position: "absolute", left: 0, top:0, width: dims, height: dims}}>
                <img style={{height: "100%", width:"100%"}} src={getAsset("images/chart.svg")}/>
            </div>
        )
    }

    render() {

        const { data, chartStyle, size } = this.props;
        let dotSize = 5;
        if(data && data.length > 30){
            dotSize = 3
        }

        console.log("rnder chart", data)

        return (
            <div style={{
                width: "100%",
                height: "100%",
                position: "relative",
                borderBottom: "3px solid #000",
                borderLeft: "3px solid #000",
            }}>
                <div style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1
                }}>
                    <img style={{ position: "absolute", top: -16, left: -10 }}
                         src={getAsset("icons/arrow.svg")} />
                    <img style={{ position: "absolute", bottom: -10, right: -16, transform: "rotate(90deg)" }}
                         src={getAsset("icons/arrow.svg")} />
                    <AxisLabel style={{ bottom: -21, left: 0, right: 0, marginBottom: -2 }}>{getString("mood")}</AxisLabel>
                    <AxisLabel style={Object.assign(chartStyle, {
                        bottom: 0,
                        left: -42,
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                    })}>
                        <div style={{ transform: "rotate(270deg)" }}>{getString("energy")}</div>
                    </AxisLabel>
                </div>
                <div style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    zIndex: 99
                }}>
                    {this.renderCircle(125)}
                    <ResponsiveContainer width="100%" height="100%">
                        <ScatterChart margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>

                            <defs>
                                <linearGradient id="colorSad" x1="0%" y1="100%" x2="100%" y2="0%">
                                    <stop offset="5%" stopColor="#CC1D6C" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#CC1D6C" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorCalm" x1="100%" y1="100%" x2="0%" y2="0%">
                                    <stop offset="5%" stopColor="#6FCF97" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#6FCF97" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorAngry" x1="0%" y1="0%" x2="100%" y2="100%">
                                    <stop offset="0%" stopColor="#000000" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#000000" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorHappy" x1="100%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="5%" stopColor="#F2C94C" stopOpacity={1} />
                                    <stop offset="95%" stopColor="#F2C94C" stopOpacity={0} />
                                </linearGradient>
                            </defs>

                            <XAxis type="number" dataKey="x" domain={[0, 1]} hide={true} />
                            <YAxis type="number" dataKey="y" domain={[0, 1]} hide={true} />
                            <Tooltip
                                payload={[{}]}
                                cursor={{ strokeDasharray: '3 3' }}
                                formatter={(value, name) => {
                                    return [this.formatValue(value), this.formatName(name)]
                                }}
                                content={this.renderTooltip}
                            />

                            <Scatter data={data} fill="#536EEC" shape={({ cx, cy, r }) => {
                                return (
                                    <g>
                                        <circle cx={cx} cy={cy} r={dotSize} fill="#536EEC" />
                                    </g>
                                );
                            }}/>

                            {/*  {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={"#536EEC"} />
                                ))}
                            </Scatter>*/}
                        </ScatterChart>
                    </ResponsiveContainer>

                </div>

            </div >

        );
    }
}

const CustomizedDot = ({ cx, cy, r }) => {
    return (
        <g>
            {r > 5 ? (
                <circle cx={cx} cy={cy} r={r} fill="blue" />
            ) : (
                <rect x={cx - 5} y={cy - 5} width={10} height={10} fill="blue" />
            )}
        </g>
    );
};


const AxisLabel = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #000;
  position: absolute;
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
`

import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';

import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`;

const cleanPercentage = (percentage) => {
    const tooLow = percentage < 5;
    const tooHigh = percentage > 99;
    return tooLow ? 5 : tooHigh ? 96.6 : percentage;
};

const Circle = ({ color, pct, shadowFilter, opacity, width }) => {

    const r = width / 4;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;

    return (
        <circle
            strokeDashoffset={100}
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={strokePct !== circ ? color : ""}
            strokeWidth={r / 4.5}
            strokeDasharray={circ}
            strokeLinecap="round"
            strokeDashoffset={pct ? strokePct : 0}
            filter={strokePct !== circ ? shadowFilter : ""}
            style={{
                opacity,
                transition: "stroke-dashoffset 0.8s ease-in-out",
            }}
        />
    );
};

const Text = ({ value, title, width }) => {
    return (
        <Fragment>
            <text
                x="50%"
                y="42%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize={width / 12.7}
                style={{
                    textTransform: 'uppercase',
                    fontFamily: "Mulish",
                    fontWeight: 800
                }}
            >
                {value}
            </text>
            <text
                x="50%"
                y="56%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize={width / 19}
                style={{
                    textTransform: "capitalize",
                    fontFamily: "Mulish",
                    fontWeight: 300
                }}
            >
                {title}
            </text>
        </Fragment>
    );
}

const AnimatedText = ({ value, title }) => {
    return (
        <Fragment>
            <TextAnimation
                x="50%"
                y="42%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize={"1.4em"}
                style={{
                    textTransform: 'uppercase',
                    fontFamily: "Mulish",
                    fontWeight: 800
                }}
            >
                {value}
            </TextAnimation>
            <TextAnimation
                x="50%"
                y="56%"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize={"1.15em"}
                style={{
                    textTransform: "capitalize",
                    fontFamily: "Mulish",
                    fontWeight: 300
                }}
            >
                {title}
            </TextAnimation>
        </Fragment>
    );
};

const CircularProgress = ({ percentage, color, value, title, width }) => {
    const [animation, setAnimation] = useState(false)
    const pct = cleanPercentage(percentage);

    useEffect(() => {
        setAnimation(true);
        setTimeout(() => {
            setAnimation(false)
        }, 500);
    }, [title])

    return (
        <div>
            <svg width={200} height={200}>
                <defs>
                    <linearGradient id={`gradient-${color}`} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="35%" stopColor="#FFFFFF" />
                        <stop offset="100%" stopColor={color} />
                    </linearGradient>
                </defs>

                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="shadow-filter">
                        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur stdDeviation="5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                        <feColorMatrix values="0 0 0 0 0  0 0 0 0 0   0 0 0 0 0  0 0 0 0.3 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>

                <g transform={`rotate(-90 ${"100 100"})`}>
                    <Circle color="#C4C4C4" opacity={"30%"} width={width} />
                    <Circle color={color} pct={pct} shadowFilter={"url(#shadow-filter)"} width={width} />
                    {/* <Circle color={`url(#gradient-${color})`} pct={pct < 22 ? pct : 22} width={width} /> */}
                </g>
                {animation ? <AnimatedText title={title} value={value} /> : <Text title={title} value={value} width={width} />}
            </svg>
        </div>
    );
};

const TextAnimation = styled.text`
  animation: 1s ${fadeInAnimation};
`;

export default CircularProgress;
import styled from "styled-components";

export const ValueBoxSecondary = styled.div`
    
    margin-bottom: 13px;
    
    //line-height: 1.8125em;

    cursor: pointer;
    margin-bottom: 13px;
    height: 3.1875em;
    width: 14.125em;
    font-family: Open Sans;
    font-style: normal;
    font-size: 1.3125em;
    text-align: center;
    vertical-align: middle;
    word-break: break-all;

`

export const ValueBoxSecondaryInput = styled.input`
    cursor: pointer;
    margin-bottom: 13px;
    border: 1px solid #536EEC;
    border-radius: 5px;
    height: 3.1875em;
    width: 14.125em;
    line-height: 3.1875em;
    font-family: Open Sans;
    font-style: normal;
    font-size: 1.3125em;
    //line-height: 1.8125em;
    text-align: center;
    vertical-align: middle;
`

export const ValueBoxMain = styled.div`
    cursor: pointer;
    background: rgba(224, 231, 255, 0.2);
    margin-bottom: 13px;
    border: 1px solid #536EEC;
    border-radius: 5px;
    height: 3.1875em;
    width: 14.125em;
    line-height: 3.1875em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.3125em;
    //line-height: 1.8125em;
    text-align: center;
    vertical-align: middle;

    color: #2E384D;
`

export const ErrorMessage = styled.div`
    color: red;
`

import Popup from "./Popup";
import {getString} from "../utils/translations";


const BlockedPopup = ({closeHandler, open}) => {
    return (
        <Popup
            isOpen={open}
            closeHandler={closeHandler}
            closeOnDocumentClick={false}
            title={getString("usage_limit_reached_title")}
            description={getString("usage_limit_reached_description")}
            footerButtons={[{label: getString("usage_limit_reached_action_button"), onPress: closeHandler}]}
        />
    )
}

export default BlockedPopup;


import { createSlice } from '@reduxjs/toolkit';
import _, {findIndex} from "lodash";
import {clearFaceIdMap} from "solo-web-engine";

const initialState = {
    faceIds: [],
    selectedIds: [],
    faceIdCount: {},
};

export const faceIdSlice = createSlice({
    name: 'faceId',
    initialState,
    reducers: {
        addFaceId: (state, action) => {
            state.faceIds.push(action.payload);

           // if (state.selectedIds.length === 0) {
              //  console.log("selecting id", action.payload)
                state.selectedIds.push(action.payload);
            //}
        },
        selectId: (state, action) => {

            const id =action.payload
            let i = findIndex(state.selectedIds, (value) => {
                return value === id
            });

            let update = _.cloneDeep(state.selectedIds)

            if (!state.selectedIds.length) {
                update.push(id)
            } else {
                if (i === -1) {
                    update.push(id)
                } else {
                    update.splice(i, 1);
                }
            }

            state.selectedIds = update;
        },
        updateFaceIdCount: (state, action) => {
            state.faceIdCount[action.payload.id] = action.payload.count;
        },
        resetFaceId: (state) => {
            state.faceIds = [];
            state.selectedIds = [];
            state.faceIdCount = {};
            clearFaceIdMap();
        }
    },
});

export const { addFaceId, selectId, updateFaceIdCount , resetFaceId} = faceIdSlice.actions;

export default faceIdSlice.reducer;

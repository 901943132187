import React from 'react';
import SidebarItem from "./SidebarItem";
import {getString} from "../../utils/translations";

const Sidebar = ({ items,itemHeight, children }) => {

    return (
        <div style={{background: "#fff", paddingLeft: 15, marginTop: 65}}>
            {items && items.map((item, i) => {
                return <SidebarItem itemHeight={itemHeight || null} key={item.title} title={item.title} value={item.value} />
            })}
            {children}
        </div>
    );
};

export default Sidebar;

import {ATTENTION_MIN_THRESHOLD} from "../../api/remoteConfig";

export default class AttentionMetricsCalculator {
    constructor() {
        this.facesData = {};
    }

    update(frame) {
        const faceId = frame.id;
        if(!faceId) return;
        if (!this.facesData[faceId]) {
            this.facesData[faceId] = {
                totalAttentionTime: 0,
                currentAttentionStreak: 0,
                maxAttentionTime: 0,
                minAttentionTime: Number.MAX_SAFE_INTEGER,
                streakCount: 0,
                totalStreakTime: 0,
                lastTimeSpent: 0,
                avgAttentionTime: 0
            };
        }

        const data = this.facesData[faceId];
        const timeSpentOnFrame = frame.timeSpent - data.lastTimeSpent;
        data.lastTimeSpent = frame.timeSpent;
        if (frame[`attention_${faceId}`] > ATTENTION_MIN_THRESHOLD) {
            data.currentAttentionStreak += timeSpentOnFrame;
            data.totalAttentionTime += timeSpentOnFrame;
            // Update max attention time if current streak is the longest
        //    console.log("current vs max", data.currentAttentionStreak, data.maxAttentionTime, Math.max(data.maxAttentionTime, data.currentAttentionStreak))
            data.maxAttentionTime = Math.max(data.maxAttentionTime, data.currentAttentionStreak);
        } else {
            // When the streak ends, update min and total streak time
            if (data.currentAttentionStreak > 0) {
                data.totalStreakTime += data.currentAttentionStreak;
                if (data.streakCount === 0 || data.currentAttentionStreak < data.minAttentionTime) {
                    data.minAttentionTime = data.currentAttentionStreak;
                }
                data.streakCount++;
                data.currentAttentionStreak = 0;
            }
        }

        // Update average attention time
   //     data.avgAttentionTime = data.streakCount > 0 ? data.totalStreakTime / data.streakCount : data.currentAttentionStreak;
    }

    getMetricsForFace(faceId) {
        const data = this.facesData[faceId];
        if (!data) {
            return null; // No data for the given face ID
        }

        let avgAttentionTime = data.streakCount > 0 ? data.totalAttentionTime / data.streakCount : data.currentAttentionStreak;

        // Adjust minAttentionTime for the case where no streaks were found
        if (data.minAttentionTime === Number.MAX_SAFE_INTEGER) {
            data.minAttentionTime = 0;
        }

        return {
            totalAttentionTime: data.totalAttentionTime,
            minAttentionTime: data.minAttentionTime,
            maxAttentionTime: data.maxAttentionTime,
            avgAttentionTime
        };
    }

    getAverageMetrics() {
        let sumTotalAttentionTime = 0;
        let sumMinAttentionTime = 0;
        let sumMaxAttentionTime = 0;
        let sumAvgAttentionTime = 0;
        let faceCount = Object.keys(this.facesData).length;

        if (faceCount === 0) {
            return {
                avgTotalAttentionTime: 0,
                avgMinAttentionTime: 0,
                avgMaxAttentionTime: 0,
                avgAttentionTime: 0
            };
        }

        for (let faceId in this.facesData) {
            const metrics = this.getMetricsForFace(faceId);
            sumTotalAttentionTime += metrics.totalAttentionTime;
            sumMinAttentionTime += metrics.minAttentionTime;
            sumMaxAttentionTime += metrics.maxAttentionTime;
            sumAvgAttentionTime += metrics.avgAttentionTime;
        }

        return {
            avgTotalAttentionTime: sumTotalAttentionTime / faceCount,
            avgMinAttentionTime: sumMinAttentionTime / faceCount,
            avgMaxAttentionTime: sumMaxAttentionTime / faceCount,
            avgAttentionTime: sumAvgAttentionTime / faceCount
        };
    }

    reset() {
        this.facesData = {};
    }
}

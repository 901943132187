import React from 'react';
import firebase, {loginValidation} from "./api/api"
import {initMixpanel, identifyMixpanel} from "./utils/analytics";
import {solo as soloSdkClient} from "./api/solo-sdk";

import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";

import Login from "./views/Login"

import Demo from "./views/Demo"
import { store } from './app/store'
import { Provider } from 'react-redux'


import './App.css';
import BlockedPopup from "./components/BlockedPopup";
import {setUnitsLimit} from "./app/reducers/aiUnitsSlice";

function App({}) {


    const [screen, setScreen] = useState("LOGIN");
    const [screenReady, setScreenReady] = useState(false);

    const [user, setUser] = useState(null)
    const [sdkUser, setSdkUser] = useState(false)
    const [sdkHouse, setSdkHouse] = useState(null)
    const [blockedPopupOpen, setBlockedPopupOpen]  = useState(false);

    const dispatch = useDispatch()
    const unitsLimit = useSelector(state => state.aiUnits.unitsLimit)


    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const mode = queryParams.get('mode');



        initMixpanel()

        console.log("mode", mode)
        if (mode === 'signup') {
            console.log("signup mode: ON")
            setScreen("SIGNUP");
            setScreenReady(true)
            return;
        }

        handleAuth().catch(console.error);
    }, [])

    const handleAuth = async () => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken(true).then(async (idToken) => {
                    localStorage.setItem("checkupUserToken", idToken)
                    localStorage.setItem("userToken", idToken)
                    console.log("user", user)
                    identifyMixpanel(user.uid, user.email)

                    try {
                        //login validation - get sdk credentials
                        let res = await loginValidation({idToken})
                        console.log("loginValidation", res)

                        if(res && res.success){
                            if(res.sdkCreds){
                                //todo init sdk
                                let sdkInit = await soloSdkClient.init({appId: res.sdkCreds.appId, apiKey: res.sdkCreds.apiKey})
                                console.log("sdkInit", sdkInit)
                                if(!sdkInit){
                                    console.error("sdk init failed", res.sdkCreds)
                                    setScreen("LOGIN")
                                    return
                                }

                                soloSdkClient.identify({
                                    userId: res.selectedHouse.id,
                                    groupId: res.selectedHouse.id,
                                    userName: `${res.selectedHouse.name} Playground`,
                                    groupName: res.selectedHouse.name,
                                })
                                setSdkUser(true)
                                setSdkHouse(res.selectedHouse.name)

                            }
                            setScreen("DEMO")
                        }else {
                            console.error("loginValidation error", res)
                            if(res.message === "units limit reached"){
                                setBlockedPopupOpen(true)
                                dispatch(setUnitsLimit(true))
                            }
                            setScreen("LOGIN")
                        }
                    }catch (e) {
                        console.error("loginValidation error", e)
                        if(e.message === "units limit reached"){
                            setBlockedPopupOpen(true)
                            dispatch(setUnitsLimit(true))
                        }
                        setScreen("LOGIN")
                    }
                });
            }
        });

        firebase.auth().onIdTokenChanged(async (user) => {
            if (user) {
                // User is signed in or token was refreshed.
                const idToken = await user.getIdToken();
                localStorage.setItem("checkupUserToken", idToken)
                localStorage.setItem("userToken", idToken)
            } else {
                // User is signed out.
                setUser(null)
                // todo: move to logout
                setScreen("LOGIN")
            }
            setScreenReady(true)
        })
    }

    const renderScreen = () => {
        switch (screen) {
            case "LOGIN":
                return <Login/>
            case "DEMO":
                return <Demo sdkUser={sdkUser} sdkHouse={sdkHouse}/>
            case "SIGNUP":
                return <Demo sdkUser={null} sdkHouse={null} signup={true} handleSignup={handleAuth}/>
            default:
                return <div>screen not found</div>
        }
    }

    return (
        <>
            {screenReady ? renderScreen() : (<Demo/>)}
            <BlockedPopup
              open={unitsLimit}
              closeHandler={()=>{
                  setBlockedPopupOpen(false)
                  dispatch(setUnitsLimit(false))
              }}
            />
    </>)


}

export default (App);


import {analytics} from "../api/firebase";

import mixpanel from "mixpanel-browser";


export const initMixpanel = () => {
    mixpanel.init("a544429cd3c5d5aff2b76396946e0722", { debug: true, track_pageview: true, persistence: 'localStorage' });
}

export const identifyMixpanel = (id, email) => {
    mixpanel.identify(id);
    mixpanel.people.set({email});
    mixpanel.people.set_once('First Login Date', new Date());

}



export const logEvent = (name, metadata) => {
    name = `DEMO_WEB_${name}`
    analytics.logEvent(name, metadata);
}

import firebase from 'firebase';
import "firebase/analytics";
import "firebase/auth";
import "firebase/database";
import { firebaseConfig } from "../constants/config";

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();

export default firebase;

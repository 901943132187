import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {styles} from '../styles/styleguide'

class Input extends PureComponent {
    render() {
        const {
            type,
            value,
            onChange,
            placeholder
        } = this.props;
        return (
            <input
                style={styles.input}
                type={type || "text"}
                value={value}
                onChange={(e)=>{onChange(e.target.value)}}
                placeholder={placeholder}
            />
        );
    }
}

Input.propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

export default Input;

import React from 'react';

import {AVATAR_IDLE_URL, AVATAR_IDLE_URL_FEMALE} from "../../api/remoteConfig";
export const AVATAR_SIZE = 250;
function Avatar({loop = true, videoRef, gender = 'female'}) {

    return (
        <div style={{width: AVATAR_SIZE, height: AVATAR_SIZE, position: "relative"}}>
            <video style={{
                width: AVATAR_SIZE,
                height:AVATAR_SIZE,
                borderRadius: "50%",
                zIndex: 5,
                position: "relative",
                transition: "opacity 1s ease-in"
            }} id="talk-video" ref={videoRef} playsInline autoPlay={true}></video>
            <video
                style={{width: AVATAR_SIZE, height: AVATAR_SIZE, borderRadius: "50%", position: "absolute", top: 0, left: 0}}
                id="idle-video"
                playsInline
                src={gender === 'male' ? AVATAR_IDLE_URL: AVATAR_IDLE_URL_FEMALE || "https://ugc-idle.s3-us-west-2.amazonaws.com/8e3e687eb248c161b597e872b47451d9.mp4"}
                loop={loop}
                autoPlay={true}/>
        </div>
    );
}

export default Avatar;


// moduleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import firebase from '../../api/firebase'; // Adjust the path to your Firebase configuration

export const fetchModuleData = createAsyncThunk(
    'modules/fetchData',
    async (moduleType) => {
        const snapshot = await firebase.database().ref(`solo-modules-config/${moduleType}`).once('value');
        return { type: moduleType, data: snapshot.val() };
    }
);

export const updateModuleData = createAsyncThunk(
    'modules/updateData',
    async ({ moduleType, data }) => {
        await firebase.database().ref(`solo-modules-config/${moduleType}`).set(data);
        return { type: moduleType, data };
    }
);

// Default values for attention and fatigue
const defaultValues = {
        "attention": {
            "norms": {
                "browInnerUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "browsDown": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesBlink": {
                    "mean": 0.1,
                    "sd": 0.05
                },
                "eyesLookDown": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookIn": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookOut": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesWide": {
                    "mean": 0.3,
                    "sd": 0.15
                },
                "mouthPucker": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "jawOpen": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "mouthUpperUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "browsOuterUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesSquint": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookRight": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookLeft": {
                    "mean": 0.2,
                    "sd": 0.1
                }
            },
            "weights": {
                "browInnerUp": 0.7,
                "browsDown": 13.7,
                "eyesBlink": 0.5,
                "eyesLookDown": 1,
                "eyesLookIn": 1,
                "eyesLookOut": 1,
                "eyesLookUp": 1,
                "eyesWide": 0.5,
                "mouthOpen": 0.3,
                "mouthPucker": 0.3,
                "jawOpen": 0.1,
                "mouthUpperUp": 0.1,
                "browsOuterUp": 0.1,
                "eyesSquint": 0.1,
                "eyesLookRight": 0.1,
                "eyesLookLeft": 0.1
            }
        },
        "fatigue": {
            "norms": {
                "browInnerUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "browsDown": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyeBlink": {
                    "mean": 0.1,
                    "sd": 0.05
                },
                "eyeSquint": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "jawOpen": {
                    "mean": 0.1,
                    "sd": 0.05
                },
                "mouthStretch": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "mouthUpperUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "browsOuterUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesBlink": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookDown": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookRight": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookLeft": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "eyesLookUp": {
                    "mean": 0.2,
                    "sd": 0.1
                },
                "mouthPucker": {
                    "mean": 0.2,
                    "sd": 0.1
                }
            },
            "weights": {
                "browInnerUp": 0.5,
                "browsDown": 2,
                "eyeBlink": 2,
                "eyeSquint": 20,
                "jawOpen": 3,
                "mouthStretch": 0.5,
                "mouthUpperUp": 0.1,
                "browsOuterUp": 0.1,
                "eyesBlink": 0.1,
                "eyesLookDown": 0.1,
                "eyesLookRight": 0.1,
                "eyesLookLeft": 0.1,
                "eyesLookUp": 0.1,
                "mouthPucker": 0.1
            }
        }
    }
;

export const initializeFirebaseData = () => {
    firebase.database().ref('solo-modules-config').set(defaultValues);
};



const moduleSlice = createSlice({
    name: 'modules',
    initialState: defaultValues,
    extraReducers: {
        [fetchModuleData.fulfilled]: (state, action) => {
            state[action.payload.type] = action.payload.data;
        },
        [updateModuleData.fulfilled]: (state, action) => {
            state[action.payload.type] = action.payload.data;
        },
    },
});

export default moduleSlice.reducer;

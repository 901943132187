import React, {Component, Fragment} from 'react';
import _, {get, findIndex} from "lodash";

import colors from "../../styles/colors";
import styled from 'styled-components'

import VideoStream from "../../components/VideoStream";


import {uploadImage} from "../../utils"
import {OverTimeButton} from "../../StyledComponents";

import {
    init, detect, generateFaceID,
    faceIdMap,
    findMatchingFaceIDLocationBased,
    filterFaceIdMap,
    landmarksWrapper,
    getDefaultOptions, toggleMaskDetection
} from "solo-web-engine"
import {load as loadTranslations, translations, setLocale, getString} from "../../utils/translations";
import Switch from "react-switch";

import ProgressBar from '../../components/ProgressBar'
import Widget from '../../components/Widget'
import ValenceEnergyScatter from "../../components/ValenceEnergyScatter"
import OverTimeChart from '../../components/OverTime';
import Popup from "../../components/Popup";

import {pushToTracker, solo as soloSdkClient} from "../../api/solo-sdk";

import {addFaceId, selectId, updateFaceIdCount, resetFaceId} from '../../app/reducers/faceIdSlice';
import {handleAIUnit} from "../../app/reducers/aiUnitsSlice";

import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";


import {
    calcStressLevel,
    calcInterestLevel,
    calcEngagementLevel,
    normalizeValue,
    getDominantEmotion, getEmojiFilterValue, getResults, formatMiliseconds
} from "./helpers"

import {
    convertEmotions,
    thresholdEmotion,
    fromBoolToEmotion,
    headFacingCamera,
    calculateFatigueScore,
    calculateAttentionScore,
    calculateModuleScore,
    headFacingCameraEmotionFilter
} from "../../utils/bioFeedback"

import {connect} from "react-redux";

import {pushToOvertime, resetOvertime, setWidgetResults} from "../../app/reducers/emotionalData";
import {fetchModuleData} from '../../app/reducers/modulesSlice';

import {PROGRESS_BARS, PLAYER_TYPE, FACE_API_OPTIONS, OVER_TIME_BUTTONS} from "../../constants";

import Avatar, {AVATAR_SIZE} from "../../components/Avatar";


import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import DetectionSquare from "../../components/DetectionSquare"

import {
    updateConfig,
    MASK_ON_CERTAINTY_THRESHOLD,
    FACE_CERTAINTY_FRAMES,
    DETECTION_SCORE_THRESHOLD,
    PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION,
    HEAD_ANGLE_EMOTION_FILTER_ACTIVE,
    YAWN_CERTAINTY_THRESHOLD,
    YAWN_TIREDNESS_TIME_THRESHOLD,
    EYE_CLOSED_CERTAINTY_THRESHOLD,
    EYE_CLOSED_ASLEEP_TIME_THRESHOLD,
    EYE_BLINKED_CERTAINTY_THRESHOLD,
    YAW_ANGLE_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
    PITCH_ANGLE_MAX_ALERT_THRESHOLD,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD, FILTER_BOX_SIZE, HAND_POINTS_ON_FACE_THRESHOLD,
} from "../../api/remoteConfig"

import {styles} from "../../styles/styleguide";
import {getAsset, imageDimensions, normalizeMoodEnergyChartPoints, videoDimensions} from "../../utils";
import {logout, updateThreshold} from "../../api/api";
import {getThresholds} from "../../api/api";
import Logo from "./Logo";
import EmojiFilter from "../../components/EmojiFilter";
import ContentSelectionPopup from "../../components/ContentSelectionPopup";
import MediaPlayer from "../../components/MediaPlayer";
import ModuleEditor from "../../components/ModuleEditor";
import Sidebar from "../../components/Sidebar";
import Button from "../../components/Button";
import IdButtons from "../../components/IdButtons";
import SignUpPopup from "./SignUpPopup";
import SoloOverTimeChart from "./SoloOverTimeChart";


const options = getDefaultOptions()
const OVERTIME_CHART_HEIGHT = 250;

let MODELS_DIR = process.env.PUBLIC_URL + '/models';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

class App extends Component {

    constructor(props) {
        super(props);

        this.progressBar = React.createRef()
        this.blendshapesProgressBar = React.createRef()
        this.player = React.createRef()
        this.playerRef = React.createRef()
        this.stream = React.createRef()
        this.uploadStream = React.createRef()
        this.chart = React.createRef()
        this.options = React.createRef()
        this.language = React.createRef()
        this.uploadedImage = React.createRef()
        this.uploadedVideo = React.createRef()
        this.videoPlayer = React.createRef()
        this.emotionsSidebar = React.createRef()
        this.avatarRef = React.createRef()
        this.emotionsTracker = [];
        this.moodEnergyTracker = [];
        this.detectTimeOut = null;
        this.shouldDetectTimeOut = null;
        this.checkupTimeOut = null;
        this.timeSpent = 0;
        this.forwardTimes = [];
        this.contentFeedbackTime = 0
        this.timer = null;

        this.otButtons1Container = React.createRef();
        this.otButtons2Container = React.createRef();
        this.otButtons3Container = React.createRef();

        this.emojiRefs = {
            happy: React.createRef(),
            sad: React.createRef(),
            surprised: React.createRef(),
            angry: React.createRef(),
            fear: React.createRef(),
            disgusted: React.createRef(),
        }

        this.state = {
            loader: true,
            detectionsArray: [],
            noResultCount: 0,
            timeSpent: 0,
            currentlyDisplayed: {},
            valenceEnergyData: [],
            videoFile: null,
            videoPlaying: false,
            playerType: PLAYER_TYPE.CAMERA,
            input: null,
            showEmotionsBar: true,
            detectedImage: false,
            currentLang: translations.selectedLocale,
            translationsLoaded: true,
            emotionDetectionInitialized: false,
            playerPlaying: false,
            playlist: [],
            sampleCount: 0,
            videoSrc: '',
            songDuration: 65,
            playedSeconds: 0,
            checkupPlayedSeconds: 0,
            isScanning: true,
            engagementLevelFade: false,
            interestLevelFade: false,
            stressLevelFade: false,
            updatedWidgets: false,
            optionsOpened: false,
            gotCheckupResults: false,
            videoUploadOption: FACE_API_OPTIONS.SSD,
            videoPopupOpen: false,
            overtime: [],
            selectedButtons: [OVER_TIME_BUTTONS.STRESS],
            happyThreshold: .5,
            calmThreshold: .5,
            sadThreshold: .5,
            angryThreshold: .5,
            disgustThreshold: .5,
            fearThreshold: .5,
            surpriseThreshold: .5,
            faceIdMinDistanceThreshold: 50,
            happyActive: true,
            calmActive: true,
            sadActive: true,
            angryActive: true,
            disgustActive: true,
            fearActive: true,
            surpriseActive: true,
            maskOn: false,
            overtimePatterns: null,
            showFaceLandmarks: true,
            emotionsModelActive: true,
            maskModelActive: false,
            yawnModelActive: true,
            handsModelActive: true,
            eyesClosedModelActive: false,
            eyesDirectionModelActive: false,
            time: 0,
            fps: 0,
            chartActive: true,
            sidebarActive: true,
            boundingBoxActive: true,
            faceIds: [],
            selectedIds: [],
            faceIdCount: {},
            avatarActive: false,
            avatarHappinessIndex: 1,
            avatarSadnessIndex: 1,
            avatarAngerIndex: 1,
            avatarFearIndex: 1,
            avatarDisgustIndex: 1,
            avatarSurpriseIndex: 1,
            emojiFilterActive: false,
            emojiFrameInfo: {},
            happyReflection: null,
            sadReflection: null,
            surprisedReflection: null,
            angryReflection: null,
            fearReflection: null,
            disgustedReflection: null,
            cplxModelActive: false,
            alertsActive: false,
            contentFeedbackPopupOpen: false,
            contentFeedbackUrl: null,
            contentFeedbackPlaying: false,
            contentFeedbackEnded: false,
            blendShapesActive: true,
            chart_show_all_time: false,
            moduleEditorActive: false,
            rightSidebarActive: true,
            dynamicWidth: "60%",
            demoSignupPopupOpen: false,
            isCameraPlaying: true,
            shouldReToggleLandmarks: true,
            previewOverTime: [],
            previewTracker: [],
            isPreviewMode: false,
        }
    }

    componentDidMount = async () => {

        let initializedEmotions = false
        let initializedTranslations = false

        this.props.fetchModuleData("attention")
        this.props.fetchModuleData("fatigue")

        try {
            initializedEmotions = await init({
                    YAWN_CERTAINTY_THRESHOLD,
                    YAWN_TIREDNESS_TIME_THRESHOLD,
                    EYE_CLOSED_CERTAINTY_THRESHOLD,
                    EYE_CLOSED_ASLEEP_TIME_THRESHOLD,
                    EYE_BLINKED_CERTAINTY_THRESHOLD,
                    YAW_ANGLE_ALERT_THRESHOLD,
                    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
                    PITCH_ANGLE_MAX_ALERT_THRESHOLD,
                    YAW_ANGLE_MIN_ALERT_THRESHOLD,
                    YAW_ANGLE_MAX_ALERT_THRESHOLD,
                    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
                    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
                    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
                    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
                    ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
                    ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
                    FILTER_BOX_SIZE,
                    FILTER_BOX_SIZE_ACTIVE: true,
                    HAND_POINTS_ON_FACE_THRESHOLD,
                    DETECTION_SCORE_THRESHOLD,
                    DETECTION_SCORE_FILTER_ACTIVE: true,

                    MASK_MODEL_ACTIVE: this.state.maskModelActive,
                    MASK_ON_CERTAINTY_THRESHOLD,
                    HEAD_ANGLE_EMOTION_FILTER_ACTIVE,
                    LIGHT_FILTER_ACTIVE: true,
                    LIGHT_FILTER_THRESHOLD: 10,
                    HAND_FILTER_ACTIVE: true,
                },
                MODELS_DIR
            )
            //    await loadFaceLandmarkModel()
            this.setState({emotionDetectionInitialized: initializedEmotions})

        } catch (e) {
            console.error("unable to init emotion detections", e)
            Promise.resolve(e).then(function (value) {
                console.error("unable to init emotion detections promise", value)
            })
        }

        try {
            initializedTranslations = await loadTranslations()
            this.setState({
                currentLang: translations.selectedLocale,
                translationsLoaded: true
            })
            if (!initializedTranslations) {
                console.error("error initialize translations")
            }
        } catch (e) {
            console.error("unable to init translations", e)

        }

        console.log("initialized", initializedEmotions && initializedTranslations)
        if (initializedEmotions && initializedTranslations) {
            this.setState({loader: false})
        }

        try {

            let {happy, calm, sad, angry, disgust, fear, surprise, modules, faceIdMinDistance} = await getThresholds()

            this.setState({
                happyThreshold: Number(happy),
                calmThreshold: Number(calm),
                sadThreshold: Number(sad),
                angryThreshold: Number(angry),
                disgustThreshold: Number(disgust),
                fearThreshold: Number(fear),
                surpriseThreshold: Number(surprise),
                faceIdMinDistanceThreshold: Number(faceIdMinDistance),
            })
        } catch (e) {
            console.error("unable to get thresholds", e)
        }

        //mirror emoji filters
        for (const emoji in this.emojiRefs) {
            const targetElement = this.emojiRefs[emoji]?.current;
            if (targetElement) {
                const clonedElement = targetElement.cloneNode(true);
                this.setReflection(clonedElement, emoji);
            }
        }

        window.addEventListener('resize', this.updateOTButtonsWidth);

        if (this.props.signup) {
            this.handleDemoSignupMode()
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.emojiFilterActive && this.state.emojiFilterActive) {
            for (const emoji in this.emojiRefs) {
                const targetElement = this.emojiRefs[emoji]?.current;
                if (targetElement) {
                    const clonedElement = targetElement.cloneNode(true);
                    this.setReflection(clonedElement, emoji);
                }
            }
        }
        if (this.state.playerType !== prevState.playerType) {
            this.setState({gotCheckupResults: false, detectionsArray: []})
            this.props.resetOvertime()
            clearTimeout(this.checkupTimeOut);
            if (prevState.playerType === PLAYER_TYPE.CHECKUP_CAMERA && this.state.playerType === PLAYER_TYPE.CAMERA) {
                this.detectionLoop()
            }

        }

        if (prevState.input !== this.state.input ||
            prevState.videoSrc !== this.state.videoSrc ||
            prevState.videoFile !== this.state.videoFile) {

            this.props.resetOvertime()

            clearTimeout(this.detectTimeOut)
            this.detectTimeOut = null

            clearTimeout(this.shouldDetectTimeOut)
            this.shouldDetectTimeOut = null
        }

        if (this.state.playerType === PLAYER_TYPE.VIDEO_UPLOAD
            || this.state.playerType === PLAYER_TYPE.VIDEO_PLAYER) {
            if (prevState.playerType !== this.state.playerType ||
                prevState.videoFile !== this.state.videoFile) {


                const video = document.getElementById('myVideo');
                console.log("load video", {playerType: this.state.playerType, videoFile: this.state.videoFile, video})
                if (video) {
                    this.resetOverTime()
                    this.setState({input: video})
                    video.onplaying = (event) => {
                        // this.setDuration(video.duration)

                        clearTimeout(this.detectTimeOut)
                        this.detectTimeOut = null

                        clearTimeout(this.shouldDetectTimeOut)
                        this.shouldDetectTimeOut = null

                        this.setState({videoPlaying: true})
                        console.log("UPLOADED VIDEO STARTED")


                        if(this.state.isPreviewMode){
                            this.startSyncTimer()
                        }else {
                            if (this.props.sdkUser) {
                                console.log("start monitoring", this.state.playerType)
                                soloSdkClient.setDetectionInterval(100)
                                soloSdkClient.setContent(this.state.playerType, true)
                            }
                            this.detectionLoop()
                        }
                    };
                    video.onpause = (event) => {
                        this.setState({videoPlaying: false})
                        if (this.props.sdkUser) {
                            soloSdkClient.stopMonitoring()
                        }
                        this.stopSyncTimer()
                    };
                    video.onended = (event) => {
                        console.log("video ended");
                        this.setState({videoPlaying: false})
                        if (this.props.sdkUser) {
                            soloSdkClient.stopMonitoring()
                        }

                        if (this.props.signup) {
                            // loop video & clear overtime
                            this.setState({videoPlaying: true})
                            this.resetOverTime()
                            // seek to 0
                            video.currentTime = 0
                            video.play()
                        }


                        // Trigger preview mode when the video ends, if the player type is VIDEO_UPLOAD
                        if (this.state.playerType === PLAYER_TYPE.VIDEO_UPLOAD) {
                            this.setState({ isPreviewMode: true });
                        }

                        this.stopSyncTimer()
                    }

                    video.ontimeupdate = (event) => {

                        if (this.state.isPreviewMode) {
                            //console.log("ontimeupdate", video.currentTime)
                            this.seekToDataAtTime(video.currentTime);
                        }
                    };

                    if (this.props.signup) {
                        video.play()
                    }
                }

            }
        }

        const current = this.props.widgetResultsArray.filter(w => w.id === this.state.currentlyDisplayed.id)
        const prev = prevProps.widgetResultsArray.filter(w => w.id === prevState.currentlyDisplayed.id)

        const prevEngagement = prev.length ? prev[0].engagement : null
        const currentEngagement = current.length ? current[0].engagement : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevEngagement, currentEngagement)) {

            const prevLevel = calcEngagementLevel(prevEngagement)
            const currentLevel = calcEngagementLevel(currentEngagement)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({engagementLevelFade: true})
                setTimeout(() => {
                    this.setState({engagementLevelFade: false})
                }, 150)
            }
        }

        const prevInterest = prev.length ? prev[0].interest : null
        const currentInterest = current.length ? current[0].interest : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevInterest, currentInterest)) {

            const prevLevel = calcInterestLevel(prevInterest)
            const currentLevel = calcInterestLevel(currentInterest)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({interestLevelFade: true})
                setTimeout(() => {
                    this.setState({interestLevelFade: false})
                }, 150)
            }
        }

        const prevStress = prev.length ? prev[0].stress : null
        const currentStress = current.length ? current[0].stress : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevStress, currentStress)) {

            const prevLevel = calcStressLevel(prevStress)
            const currentLevel = calcStressLevel(currentStress)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({stressLevelFade: true})
                setTimeout(() => {
                    this.setState({stressLevelFade: false})
                }, 150)
            }
        }

        if (this.props.sdkUser && prevState.playerType !== this.state.playerType) {
            if ([PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD].includes(prevState.playerType)) {
                console.log("stop monitoring")
                soloSdkClient.stopMonitoring()
            }
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOTButtonsWidth);
    }

    handleDemoSignupMode = async () => {
        const videoPath = `${process.env.PUBLIC_URL}/videos/playground-demo-signup-video.mp4`;
        try {
            const response = await fetch(videoPath);
            const videoBlob = await response.blob();
            const videoElement = this.uploadedVideo.current
            // Set the video source to the Blob URL
            console.log("Setting video path", videoPath)
            videoElement.src = videoPath;
            // Reload the video source
            videoElement.load();
            // Assuming uploadVideo is a method within this component that takes a File object as an argument
            this.uploadVideo(videoPath);

            this.setState({
                demoSignupPopupOpen: true,
            })
        } catch (error) {
            console.error("Error fetching signup video:", error);
        }
    }

    updateOTButtonsWidth = () => {
        if (this.otButtons1Container.current && this.otButtons3Container.current && this.otButtons2Container.current) {
            const parentWidth = this.otButtons2Container.current.parentElement.clientWidth;
            const div1Width = this.otButtons1Container.current.clientWidth;
            const div3Width = this.otButtons3Container.current.clientWidth;
            const newDiv2Width = parentWidth - div1Width - div3Width - 30;
            this.setState({dynamicWidth: newDiv2Width});
        }
    };

    setReflection = (element, emoji) => {
        this.setState({[`${emoji}Reflection`]: element})
    }

    changeThreshold = async (name, value) => {
        console.log("changeThreshold", name, value)
        this.setState({[`${name}Threshold`]: Number(value)})
        await updateThreshold(name, value)
    }

    changeEmotionActive = (name, value) => {
        console.log("changeEmotionActive", name, value)
        this.setState({
            [`${name}Active`]: value,
            detectedImage: false,
        }, () => {
            console.log("changeEmotionActive", this.state.playerType)
            if (this.state.playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                this.detectionLoop()
            }
        })
    }

    streamStarted = async () => {
        const {input, playerType} = this.state
        console.log("streamStarted", input)
        this.startTimer()
        this.detectionLoop()
        if (this.props.sdkUser) {
            console.log("start monitoring", playerType)
            soloSdkClient.setDetectionInterval(100)
            soloSdkClient.setContent(playerType, true)
        }

    }

    startTimer = () => {
        // start timer to calculate time spent
        // count every 10 milliseconds
        this.startTime = Date.now()
        this.timer = setInterval(() => {
            let timeSpent = Date.now() - this.startTime
            this.timeSpent = timeSpent
        }, 10)
    }

    processEmotions = (tracker) => {
        try {
            let convertedEmotions
            if (tracker) {
                convertedEmotions = convertEmotions(tracker);
            } else {
                convertedEmotions = convertEmotions(this.emotionsTracker);
            }
            return {
                valence: convertedEmotions.valence,
                energy: convertedEmotions.energy,
                emotions: convertedEmotions.avg,
                stress: convertedEmotions.stress,
                wellbeing: convertedEmotions.wellbeing,
                interest: convertedEmotions.interest,
                engagement: convertedEmotions.engagement
            }
        } catch (e) {
            console.error("error in processEmotions", e)
        }
    }

    shouldDetectEmotions = () => {
        const {detectedImage, playerType, videoPlaying, gotCheckupResults, isCameraPlaying, isPreviewMode} = this.state;

        if (playerType === PLAYER_TYPE.IMAGE_UPLOAD && detectedImage) {
            return false
        }
        if (playerType === PLAYER_TYPE.VIDEO_PLAYER || playerType === PLAYER_TYPE.VIDEO_UPLOAD) {
            if (!videoPlaying || isPreviewMode) {
                return false
            }
        }
        if (playerType === PLAYER_TYPE.CHECKUP_CAMERA && gotCheckupResults) {
            return false
        }

        if (PLAYER_TYPE.CONTENT_FEEDBACK === playerType && (!this.state.contentFeedbackPlaying || this.state.contentFeedbackEnded)) {
            return false
        }

        if(playerType === PLAYER_TYPE.CAMERA && !isCameraPlaying){
            return false
        }

        return true
    }

    pushToOverTime = (data) => {
        const {playerType} = this.state
        let timeSpent = playerType === PLAYER_TYPE.CONTENT_FEEDBACK ? this.contentFeedbackTime : this.timeSpent;
        // console.log("pushToOverTime", data, timeSpent)
        let vidCurrTime = playerType === PLAYER_TYPE.CONTENT_FEEDBACK ? this.contentFeedbackTime : this.uploadedVideo.current && this.uploadedVideo.current.currentTime;
        // console.log("pushToOverTime", data)
        this.props.pushToOvertime({
            data,
            playerType,
            vidCurrTime: vidCurrTime,
            timeSpent: timeSpent
        })
    }

    updateTimeStats(timeInMs) {
        this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30)
        const avgTimeInMs = this.forwardTimes.reduce((total, t) => total + t) / this.forwardTimes.length
        let time = `${Math.round(avgTimeInMs)} ms`;
        let fps = `${Math.round(1000 / avgTimeInMs)}`;
        //console.log('time', time)
        // console.log('fps', fps)
        this.setState({fps, time})
    }

    detectionLoop = async (interval = 0) => {
        const {playerType} = this.state

        try {
            let shouldDetect = this.shouldDetectEmotions();
            //console.log("shouldDetect", shouldDetect, playerType)
            if (!shouldDetect) {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
                return this.shouldDetectTimeOut = setTimeout(() => this.detectionLoop(interval), interval)
            } else {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
            }
            await this.detect();

            if (this.shouldDetectEmotions()) {
                this.detectTimeOut = setTimeout(() => this.detectionLoop(interval), interval)
            }
        } catch (e) {
            console.log("error", e)
        }
    }

    detect = async () => {
        try {

            const {
                ts,
                result,
                faceMeshResult,
            } = await this.analyzeFrame();

            this.handleDetectionResult(result, ts, faceMeshResult)


        } catch (e) {
            console.log("error", e)
        }
    }

    async analyzeFrame() {

        const {playerType, input} = this.state
        const {
            emotionsModelActive,
            maskModelActive,
            yawnModelActive,
            eyesClosedModelActive,
            eyesDirectionModelActive,
            handsModelActive,
        } = this.state

        const isImage = playerType === PLAYER_TYPE.IMAGE_UPLOAD
        const ts = Date.now()

        const {detectionsWithExpressions, faceMeshResult} = await detect(input, {
            emotionsModelActive,
            maskModelActive,
            yawnModelActive,
            eyesClosedModelActive,
            eyesDirectionModelActive,
            handsModelActive,
            isImage,
            detectLightingLevels: true,
            showFaceLandmarks: {
                show: this.state.showFaceLandmarks,
                canvas: document.getElementById('canvas-overlay')
            },
            emotionsModelOptions: options,
            returnOriginalFacemesh: true,
        })

        // console.log("onresult", result)

        this.updateTimeStats(Date.now() - ts)
        return {ts, result: detectionsWithExpressions, faceMeshResult};
    }

    handleDetectionResult = (result, ts, faceMeshResult) => {

        const {playerType} = this.state
        const {

            maskModelActive,
            moduleEditorActive
        } = this.state

        const {attentionConfig, fatigueConfig} = this.props;

        const isImage = playerType === PLAYER_TYPE.IMAGE_UPLOAD;

        if (this.props.sdkUser && soloSdkClient.isMonitoring()) {
            pushToTracker({results: result})

            this.props.handleAIUnit({
                startTime: ts,
                sessionId: soloSdkClient.getSession(),
                deviceId: "playground-browser",
                userId: soloSdkClient.getUserId(),
                numberOfFrames: 1
            })
        }

        let widgets;

        if (result && result.length) {

            const {noResultCount, showFaceLandmarks} = this.state
            let counter = noResultCount
            this.setState({noResultCount: counter - 1})

            if (isImage) {
                this.setState({detectedImage: true})
            }

            this.setState({detectionsArray: []})

            const detections = []
            const widgetResults = []

            let i = 0
            let frameIds = []
            let filterEmotionsById = {}
            for (const r of result) {

                let id = isImage && !moduleEditorActive ? generateFaceID() : this.getFaceId(r);
                frameIds.push(id)
                widgets = {}

                let score = r.detection.score;
                let filterEmotions = r.toFilter || false


                let maskMode = maskModelActive && r.mask > MASK_ON_CERTAINTY_THRESHOLD
                this.setState({maskOn: maskMode})

                if (maskMode) {
                    updateConfig(true)
                } else {
                    updateConfig(false)
                }

                const {
                    happyThreshold,
                    calmThreshold,
                    sadThreshold,
                    angryThreshold,
                    disgustThreshold,
                    fearThreshold,
                    surpriseThreshold,
                    happyActive,
                    calmActive,
                    sadActive,
                    angryActive,
                    disgustActive,
                    fearActive,
                    surpriseActive,

                } = this.state

                const {angry, disgusted, fearful, happy, neutral, sad, surprised} = r.expressions

                const isHappy = happyActive ? thresholdEmotion(happy, happyThreshold) : false
                const isCalm = calmActive ? thresholdEmotion(neutral, calmThreshold) : false
                const isSad = sadActive ? thresholdEmotion(sad, sadThreshold) : false
                const isAngry = angryActive ? thresholdEmotion(angry, angryThreshold) : false
                const isDisgusted = disgustActive ? thresholdEmotion(disgusted, disgustThreshold) : false
                const isFearful = fearActive ? thresholdEmotion(fearful, fearThreshold) : false
                const isSurprised = surpriseActive ? thresholdEmotion(surprised, surpriseThreshold) : false

                let thresholdEmotions = {
                    happiness: filterEmotions ? null : maskMode ? fromBoolToEmotion(isHappy) : happy,
                    neutral: filterEmotions ? null : maskMode ? fromBoolToEmotion(isCalm) : neutral,
                    angry: filterEmotions ? null : maskMode ? fromBoolToEmotion(isAngry) : angry,
                    disgusted: filterEmotions ? null : maskMode ? fromBoolToEmotion(isDisgusted) : disgusted,
                    surprised: filterEmotions ? null : maskMode ? fromBoolToEmotion(isSurprised) : surprised,
                    sad: filterEmotions ? null : maskMode ? fromBoolToEmotion(isSad) : sad,
                    fearful: filterEmotions ? null : maskMode ? fromBoolToEmotion(isFearful) : fearful
                }

                let unfilteredEmotions = {
                    happiness: happy,
                    neutral,
                    angry,
                    disgusted,
                    surprised,
                    sad,
                    fearful
                }

                //console.log("thresholdEmotions", thresholdEmotions)

                widgets = getResults(id, thresholdEmotions, filterEmotions)


                let fatigue = calculateModuleScore(r.faceBlendshapes, fatigueConfig)

                const attention = calculateModuleScore(r.faceBlendshapes, attentionConfig)

                //  console.log("result", r)
                widgets = {
                    ...widgets,
                    mask: r.mask,
                    noMask: r.noMask,
                    yawn: r.yawn,
                    yawnRatio: r.yawnRatio,
                    headPose: r.headAngles,
                    frown: r.frown,
                    closedLeftEye: r.closedLeftEye,
                    openLeftEye: r.openLeftEye,
                    closedRightEye: r.closedRightEye,
                    openRightEye: r.openRightEye,
                    closedEyes: r.closedEyes,
                    openEyes: r.openEyes,
                    score: score,
                    time: ts,
                    faceBlendshapes: r.faceBlendshapes,
                    eyesDirection: r.eyesDirection,
                    fatigue,
                    attention,
                    lightIndex: r.lightIndex,
                    filterReason: r.filterReason,
                    headAnglesFallback: r.headAnglesFallback
                    // leftRightLightDifference: r.leftRightLightDifference,
                    // topBottomLightDifference: r.topBottomLightDifference,
                    //  faceBackgroundLightDifference: r.faceBackgroundLightDifference,
                    // lux: r.lux,
                    //  lux2: r.lux2,
                }

               // console.log("widgets", widgets)
                widgetResults.push(widgets)

                if (playerType !== PLAYER_TYPE.IMAGE_UPLOAD) {
                    this.moodEnergyTracker.push({
                        emotions: r.expressions,
                        playedSeconds: this.state.playedSeconds,
                        songDuration: this.state.songDuration,
                        thresholdEmotions,
                        mask: r.mask,
                        noMask: r.noMask,
                        yawn: r.yawn,
                        yawnRatio: r.yawnRatio,
                        headPose: r.headPose,
                        frown: r.frown,
                        closedLeftEye: r.closedLeftEye,
                        openLeftEye: r.openLeftEye,
                        closedRightEye: r.closedRightEye,
                        openRightEye: r.openRightEye,
                        closedEyes: r.closedEyes,
                        openEyes: r.openEyes,
                    }) //remote
                    const {valence, energy} = this.processEmotions(this.moodEnergyTracker)
                    r.valence = valence
                    r.energy = energy
                }

                r.id = id

                if (r.middleEyes) {
                    const {leftIrisCenter, rightIrisCenter, leftIrisCenterPixel, rightIrisCenterPixel} = r.middleEyes
                    //  console.log({ leftIrisCenter, rightIrisCenter })
                    r.leftIrisCenter = leftIrisCenterPixel
                    r.rightIrisCenter = rightIrisCenterPixel
                } else {
                    console.log("no middleEyes")
                }

                r.filterEmotions = filterEmotions

                detections.push(r)
            }

            filterFaceIdMap(frameIds)
            let vidCurrTime = playerType === PLAYER_TYPE.CONTENT_FEEDBACK ? this.contentFeedbackTime : this.uploadedVideo.current && this.uploadedVideo.current.currentTime;
            this.setState({
                currentResults: result,
                previewTracker: [...this.state.previewTracker, {time: vidCurrTime, faceMeshResult, detections}],
            })

            this.pushToOverTime(widgetResults)




            let showEmotionsBar = true
            if ((playerType !== PLAYER_TYPE.IMAGE_UPLOAD) && detections.length > 1) {
                showEmotionsBar = false
            }

            if (playerType === PLAYER_TYPE.CHECKUP_CAMERA) {
                if (this.state.gotCheckupResults) {
                    showEmotionsBar = true
                } else {
                    showEmotionsBar = false
                }
            }

            const valenceEnergyData = this.calcValenceEnergyData(widgetResults)

            let currentlyDisplayed = {...this.state.currentlyDisplayed}
            const {selectedIds} = this.props
            if (filterEmotionsById[selectedIds[selectedIds.length - 1]] === true) {
                console.log("filter emotions for id")
                currentlyDisplayed = {}
            } else {
                if (detections.length > 0 && (selectedIds.length > 0 || isImage)) {
                    // select the detection with the last selected id
                    let idRes = detections.find(d => d.id === selectedIds[selectedIds.length - 1])

                    let filterEmotions = false
                    if (idRes) {
                        if (filterEmotionsById[idRes.id] === true) {
                            filterEmotions = true
                        }
                        currentlyDisplayed = {
                            ...currentlyDisplayed,
                            ['expressions']: idRes.expressions,
                            ['detection']: idRes.detection,
                            ['id']: idRes.id,
                            ['moodTitle']: idRes.moodTitle,
                            ['faceBlendshapes']: idRes.faceBlendshapes,
                            filterEmotions
                        }
                    } else {
                        this.props.selectId(detections[0].id)
                        if (filterEmotionsById[detections[0].id] === true) {
                            filterEmotions = true
                        }
                        currentlyDisplayed = {
                            ...currentlyDisplayed,
                            ['expressions']: detections[0].expressions,
                            ['detection']: detections[0].detection,
                            ['id']: detections[0].id,
                            ['moodTitle']: detections[0].moodTitle,
                            ['faceBlendshapes']: detections[0].faceBlendshapes,
                            filterEmotions
                        }
                    }
                } else {
                    currentlyDisplayed = {}
                }
            }


            this.setState({
                detectionsArray: detections,
                showEmotionsBar,
                currentlyDisplayed,
                valenceEnergyData
            })

        } else {
            let {noResultCount} = this.state
            let counter = noResultCount

            if (playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                this.setState({detectionsArray: [], currentlyDisplayed: {}, valenceEnergyData: [{x: 0, y: 0}]})
            } else {
                this.setState({noResultCount: counter + 1})
                if (counter > 3) {
                    this.setState({
                        detectionsArray: [],
                        currentlyDisplayed: {},
                        valenceEnergyData: [{x: 0, y: 0}],
                        noResultCount: 0
                    })
                }
            }

            let overtimeData = [{
                valence: null,
                mood: null,
                energy: null,
                stress: null,
                wellbeing: null,
                interest: null,
                engagement: null,
                stressLevel: null,
                interestLevel: null,
                engagementLevel: null,
                wellbeingLevel: null,
                valenceLevel: null,
                energyLevel: null,
                id: null
            }]

            this.pushToOverTime([overtimeData])


            // console.log("no result")
        }
    }

    showImageLandmarks() {
        this.setState({showFaceLandmarks: true})
    }

    getFaceId(r) {
        let descriptor = r.descriptor
        //  console.log("r", r)
        const matchingFaceID = findMatchingFaceIDLocationBased(r.detection.box, this.state.faceIdMinDistanceThreshold);
        let id;

        // Get the current state of faceIdMap and faceIdCount from the Redux store
        const faceIdCount = this.props.faceIdCount;

        if (matchingFaceID) {
            // If a match is found, assign the same face ID to the current face
            id = matchingFaceID
        } else {
            // If no match is found, generate a new face ID and add it to the list of detected face IDs
            id = generateFaceID();
            this.props.addFaceId(id)
        }
        faceIdMap.set(id, r.detection.box);

        if (!faceIdCount[id]) {
            this.props.updateFaceIdCount({id, count: 1})
        } else {
            this.props.updateFaceIdCount({id, count: faceIdCount[id] + 1})
        }
        return id;
    }

    updateInput = (input, callback) => {
        this.setState({input}, callback)
    }

    calcValenceEnergyData = (detectionsArray) => {
        let data = []
        for (const detection of detectionsArray) {
            data.push({
                x: normalizeValue(detection.valence),
                y: normalizeValue(detection.energy),
                id: detection.id
            })
        }

        return normalizeMoodEnergyChartPoints(data)
    }

    uploadImagePending = async () => {
        // this.setState({optionsOpened: false})

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.resetOverTime()

        this.setState({
            playerType: PLAYER_TYPE.IMAGE_UPLOAD,
            detectedImage: false,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
            rightSidebarActive: true,
            blendShapesActive: true
        })

    }

    uploadImage = async () => {
        // this.setState({optionsOpened: false})
        await uploadImage()
        this.setState({
            input: document.getElementById('myImage')
        }, this.detect)

    }

    uploadVideoPending = async () => {

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.resetOverTime()

        this.setState({
            playerType: PLAYER_TYPE.VIDEO_UPLOAD,
            detectionsArray: [],
            videoFile: null,
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}]
        })
    }

    uploadVideo = async (file = null, loop = false) => {
        const videoFile = file ? file : URL.createObjectURL(document.getElementById('videoUpload').files[0])

        console.log("videoFile", videoFile)
        this.setState({
            videoFile,
            playerType: PLAYER_TYPE.VIDEO_UPLOAD,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}]
        })
    }

    openContentFeedback = () => {

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.resetOverTime()

        this.setState({
            playerType: PLAYER_TYPE.CONTENT_FEEDBACK,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
            contentFeedbackPopupOpen: true,
            contentFeedbackUrl: null,
            contentFeedbackEnded: false,

        })
    }

    handleCameraClick = () => {
        //   this.setState({optionsOpened: false})

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.resetOverTime()

        this.setState({
            playerType: PLAYER_TYPE.CAMERA,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
        })

    }

    toggleOption = (option) => {
        this.setState({[option]: !this.state[option]})
    }

    toggleEmoji = () => {
        this.setState({emojiFilterActive: !this.state.emojiFilterActive})
    }

    toggleCplxModel = () => {
        this.setState({cplxModelActive: !this.state.cplxModelActive})
    }

    toggleAlerts = () => {
        this.setState({alertsActive: !this.state.alertsActive})
    }

    renderChart = () => {

        let {currentLang, valenceEnergyData, currentlyDisplayed, showEmotionsBar} = this.state

        const width = _.get(this.emotionsSidebar, "current.clientWidth", 179)

        return (
            <div ref={this.chart} style={{
                zIndex: 55,
                height: width + 6,
                width: width,
                backgroundColor: 'white',
                borderRadius: 6.34215,
                paddingBottom: 53,
                paddingLeft: 25,
                paddingRight: 22,
                paddingTop: 7,
                boxShadow: '0px 0px 8.95362px rgba(0, 0, 0, 0.25)',
                opacity: 1
            }}>
                <div style={{
                    fontFamily: 'Rubik',
                    fontWeight: 900,
                    textAlign: 'center',
                    color: '#2E384D',
                    margin: 3,
                }}>
                    {getString("circumplex_model")}
                </div>
                <ValenceEnergyScatter
                    data={valenceEnergyData}
                    currentLang={currentLang}
                    chartStyle={{marginLeft: currentLang === "ja" ? -10 : 0}}
                    size={width}
                />
            </div>
        )
    }

    renderOptions = () => {

        const {currentLang, optionsOpened} = this.state
        const width = this.options.current && this.options.current.offsetWidth

        let divDisplay = "flex"

        return (
            <Col md={1} ref={this.options} style={{
                position: 'absolute',
                left: 10,
                top: 110,
                marginBottom: 35,
                display: 'flex',
                flexDirection: 'column',
                gap: width / 25,
                color: colors.white,
                alignItems: 'center',
                zIndex: 99999
            }}>
                <Fragment>
                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }} onClick={this.handleCameraClick}>{getString("monitoring")}</div>


                    <label style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}
                    >
                        <input
                            id="videoUpload"
                            type="file"
                            onClick={this.uploadVideoPending}
                            onChange={() => this.uploadVideo()}
                            style={{display: 'none'}}/>
                        {getString("video")}</label>

                    <label style={{
                        width,
                        marginTop: -7,
                        marginBottom: 2,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}>
                        <input id="imageUpload"
                               type="file"
                               onClick={this.uploadImagePending}
                               onChange={this.uploadImage}
                               style={{display: 'none'}}
                               accept=".jpg, .jpeg, .png"/>
                        {getString("image")}</label>


                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }} onClick={this.openContentFeedback}>{getString("content_feedback")}</div>

                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}
                         onClick={this.toggleOptions}>{optionsOpened ? getString('hide_options') : getString("options")}</div>
                </Fragment>
            </Col>
        )
    }

    toggleOptions = () => {
        this.setState({optionsOpened: !this.state.optionsOpened})
    }

    toggleFaceLandmarks = () => {
        if (this.state.showFaceLandmarks) {
            const canvas = document.getElementById('canvas-overlay')
            // faceapi.matchDimensions(canvas, {width: 0, height: 0})
            // resize the detected boxes and landmarks in case your displayed image has a different size than the original
            // const resizedResults = faceapi.resizeResults(result, displaySize)
            // draw the landmarks into the canvas
            //  faceapi.draw.drawFaceLandmarks(canvas, resizedResults)


        } else if (this.state.playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
            this.showImageLandmarks();
        }
        this.setState({showFaceLandmarks: !this.state.showFaceLandmarks})
    }

    handleDetectionSquareClick = (detection) => {
        console.log("handleDetectionSquareClick", detection)
        this.setState({currentlyDisplayed: detection})
    }

    extendedSquare = () => {
        const {detectionsArray, playerType} = this.state
        if (detectionsArray.length > 1
            || playerType === PLAYER_TYPE.IMAGE_UPLOAD
            || playerType === PLAYER_TYPE.VIDEO_PLAYER
            || playerType === PLAYER_TYPE.VIDEO_UPLOAD
            || playerType === PLAYER_TYPE.CAMERA
        ) {
            return true
        }
        return false
    }

    renderDetectionSquare = (height, width) => {

        const {
            detectionsArray,
            currentlyDisplayed,
            showEmotionsBar,
            playerType,
            boundingBoxActive,
            emojiFilterActive,
            alertsActive,
            isCameraPlaying
        } = this.state

        const {widgetResultsArray} = this.props

        if (!boundingBoxActive || !isCameraPlaying) {
            return null
        }

        let getBiggerFont = false

        if (detectionsArray.length === 1) {
            getBiggerFont = true;
        }

        //  console.log("detectionsArray", detectionsArray)

        let map = detectionsArray.map(d => {


                let dominantEmotion = d.toFilter ? {label: "", color: null, value: null} :getDominantEmotion(d)
                //   console.log("dominantEmotion", dominantEmotion)

                let emphasizeDetection = false
                if (d.id === currentlyDisplayed.id && showEmotionsBar) {
                    emphasizeDetection = true
                }

                let currentWidget = widgetResultsArray.filter(w => w.id === d.id)
                let stressLevel, interestLevel, engagementLevel, wellbeingLevel
                let alerts = null


                if (currentWidget.length) {
                    stressLevel = currentWidget[0].stressLevel
                    interestLevel = currentWidget[0].interestLevel
                    engagementLevel = currentWidget[0].engagementLevel
                    wellbeingLevel = currentWidget[0].wellbeingLevel
                    alerts = currentWidget[0].alerts
                } else {
                    return null;
                }


                let width1 = d.detection._box._width / (d.detection.imageDims._width / width);
                let emojiFilterValue = d.toFilter ? null : getEmojiFilterValue(dominantEmotion);
                let emojiSize = width1 * 1.25;

                let top = (d.detection._box.top / (d.detection.imageDims._height / height)) - 7;
                let left = d.detection._box.left / (d.detection.imageDims._width / width);

                let frame = playerType === PLAYER_TYPE.IMAGE_UPLOAD ? "frame" : performance.now()

                const filterEmotions = d.filterEmotions
                //  console.log("renderDetectionSquare", d.id, frame)
                return <div id={`${d.id}_${frame}`} key={`${d.id}_${frame}`}
                            onClick={() => this.handleDetectionSquareClick(d)} style={{cursor: 'pointer'}}>
                    {emojiFilterActive && emojiFilterValue && (
                        <div key={emojiFilterValue}
                             style={{position: "absolute", left: left, top: top - width1, width: emojiSize, zIndex: 5}}>
                            <EmojiFilter emotion={emojiFilterValue} width={emojiSize}
                                         updateFrameInfo={this.updateEmojiFrameInfo}
                                         frameInfo={this.state.emojiFrameInfo[d.id]}
                                         id={d.id}
                            />
                        </div>
                    )}
                    <DetectionSquare
                        font={getBiggerFont}
                        emphasize={emphasizeDetection}
                        filterEmotions={filterEmotions}
                        percentage={dominantEmotion.value}
                        label={getString(dominantEmotion.label)}
                        progressColor={dominantEmotion.color}
                        top={top}
                        left={left}
                        width={width1}
                        height={(d.detection._box._height / (d.detection.imageDims._height / height)) + 25}
                        extendedDetectionSquare={this.extendedSquare() ? true : false}
                        stressLevel={stressLevel}
                        interestLevel={interestLevel}
                        engagementLevel={engagementLevel}
                        wellbeingLevel={wellbeingLevel}
                        mask={currentWidget[0].mask}
                        noMask={currentWidget[0].noMask}
                        yawn={currentWidget[0].yawn}
                        noYawn={currentWidget[0].noYawn}
                        yawnRatio={currentWidget[0].yawnRatio}
                        closedEyes={currentWidget[0].closedEyes}
                        openEyes={currentWidget[0].openEyes}
                        closedRightEye={currentWidget[0].closedRightEye}
                        closedLeftEye={currentWidget[0].closedLeftEye}
                        headPose={currentWidget[0].headPose}
                        frown={currentWidget[0].frown}
                        alerts={alerts}
                        alertsActive={alertsActive}
                        id={d.id}
                        showId={true}
                        score={d.detection.score}
                        eyesDirection={currentWidget[0].eyesDirection}
                        leftIrisCenter={d.leftIrisCenter}
                        rightIrisCenter={d.rightIrisCenter}
                        showOnlyId={d.toFilter}
                    />
                </div>
            }
        );
        //   console.log("map",map)
        return map
    }

    updateEmojiFrameInfo = (frameInfo, emotion, id) => {
        this.setState({emojiFrameInfo: {...this.state.emojiFrameInfo, [id]: {...frameInfo, emotion}}})
    }

    renderBlendShapes = () => {
        const {blendShapesActive, currentlyDisplayed} = this.state
        if (!blendShapesActive) {
            return null
        }

        let faceBlendshapes = currentlyDisplayed && currentlyDisplayed.faceBlendshapes;
        let blendShapes = []
        let jawOpen = 0;
        let mouthPucker = 0;
        let browsDown = 0;
        let browsOuterUp = 0;
        let eyesBlink = 0;
        let openEyes = 0;
        let eyesSquint = 0;
        let mouthSmile = 0;
        let eyesLookDown = 0;
        let eyesLookRight = 0;
        let eyesLookLeft = 0;
        let eyesLookUp = 0;
        let mouthUpperUp = 0;
        if (faceBlendshapes) {
            //console.log("faceBlendshapes", faceBlendshapes)
            jawOpen = faceBlendshapes.jawOpen;
            mouthPucker = faceBlendshapes.mouthPucker;
            browsDown = faceBlendshapes.browsDown;
            browsOuterUp = faceBlendshapes.browsOuterUp;
            eyesBlink = faceBlendshapes.eyesBlink;
            openEyes = 1 - faceBlendshapes.eyesBlink
            eyesSquint = faceBlendshapes.eyesSquint;
            mouthSmile = faceBlendshapes.mouthSmile;
            eyesLookDown = faceBlendshapes.eyesLookDown;
            eyesLookRight = faceBlendshapes.eyesLookRight;
            eyesLookLeft = faceBlendshapes.eyesLookLeft;
            eyesLookUp = faceBlendshapes.eyesLookUp;
            mouthUpperUp = faceBlendshapes.mouthUpperUp;
        }
        blendShapes = [
            {title: getString("jawOpen"), value: jawOpen},
            {title: getString("mouthPucker"), value: mouthPucker},
            {title: getString("browsDown"), value: browsDown},
            {title: getString("browsOuterUp"), value: browsOuterUp},
            {title: getString("openEyes"), value: openEyes},
            {title: getString("eyesSquint"), value: eyesSquint},
            //  {title: "mouthSmile", value: mouthSmile},
            {title: getString("eyesLookDown"), value: eyesLookDown},
            {title: getString("eyesLookRight"), value: eyesLookRight},
            {title: getString("eyesLookLeft"), value: eyesLookLeft},
            {title: getString("eyesLookUp"), value: eyesLookUp},
            {title: getString("mouthUpperUp"), value: mouthUpperUp},
        ]

        //  console.log("blendShapes", blendShapes)

        return (
            <>
                {blendShapes.map(bs => {
                    return this.renderBlendshape(bs.title, bs.value, colors.lipstick)
                })}
            </>
        )
    }

    renderBlendshape = (title, value, color) => {
        return (
            <div style={{width: "100%"}} key={title}>
                <div style={{
                    display: 'flex',
                    flexDirection: "col",
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                    letterSpacing: -0.5,
                    paddingTop: 3,
                    paddingBottom: 3,
                    fontSize: 13,
                }}>
                    {getString(title)}</div>
                <div>
                    <ProgressBar
                        percentage={Math.round(value * 100)}
                        progressColor={color}
                        showValue={true}
                        width={this.blendshapesProgressBar.current && this.blendshapesProgressBar.current.offsetWidth / 1.3}
                        threshold={false}
                        animationDelay={0}
                        height={30}
                    />
                </div>

            </div>)
    }

    renderEmotionsBar = () => {

        const {currentLang, currentlyDisplayed, showEmotionsBar, maskOn, maskModelActive} = this.state
        let toMap = PROGRESS_BARS

        return (
            <div style={styles.emotionsSideBar}>
                {
                    toMap.map(pb => {
                            return (
                                <div style={{width: "100%"}} ref={this.progressBar} key={pb.title}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: "col",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: 'black',
                                        fontFamily: "Open Sans",
                                        fontWeight: 800,
                                        letterSpacing: -0.5,
                                        paddingTop: 3,
                                        paddingBottom: 3,
                                        fontSize: currentLang === "ja" && 13,
                                    }}>
                                        {getString(pb.title)}</div>
                                    <div style={{paddingLeft: 15, paddingRight: 15}}>
                                        <ProgressBar
                                            percentage={!showEmotionsBar ? 0 : Math.round(get(currentlyDisplayed.expressions, pb.emotion, 0) * 100)}
                                            progressColor={pb.color}
                                            showValue={true}
                                            width={this.progressBar.current && this.progressBar.current.offsetWidth / 1.3}
                                            animationDelay={PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION}
                                        />
                                    </div>
                                </div>)
                        }
                    )}


            </div>
        )
    }

    resetOverTime = () => {
        faceIdMap.clear()
        this.props.resetOvertime()
        this.props.resetFaceId()
        landmarksWrapper.clearResults()
        landmarksWrapper.resetDrawingUtils()
        this.setState({previewTracker: [], previewOverTime: [], isPreviewMode: false})
    }

    alterOvertimeData = (currentTime) => {
        // Use props.overtime to filter only items with timeSpent <= currentTime (in milliseconds)
        const filteredOvertime = this.props.overtime.filter(item => {
            console.log("item.vidCurrTime",item.vidCurrTime ,item.vidCurrTime <= currentTime)
            return item.vidCurrTime <= currentTime
        });
        console.log("filteredOvertime", filteredOvertime)
        // Update the state to use filtered data for preview mode
        this.setState({ previewOverTime: filteredOvertime });
    };

    // Method to set detection data based on the current playback time in preview mode
    setDetectionArrayByTime = (time) => {
        const { showFaceLandmarks, previewTracker } = this.state;

        // Filter props.overtime for relevant detection data close to the current time
        const detectionArray = previewTracker.filter(d => d.time <= time  && time - d.time <= 200); // Allow a window of 200ms

        if (detectionArray.length > 0) {
            // Sort to get the most recent detection closest to the current time
            let sorted = detectionArray.sort((a, b) => b.time - a.time);
            const closestDetection = sorted[0];

            console.log("closestDetection", closestDetection)

            // Update the state with the closest detection result
            this.setState({ detectionsArray: closestDetection.detections });

            // Optionally, render face landmarks if enabled
            if (showFaceLandmarks) {
             /*   landmarksWrapper.drawResults(
                    closestDetection.faceMeshResult,
                    document.getElementById('canvas-overlay2'),
                    document.getElementById('myVideo')
                );*/
                landmarksWrapper.clearResults();
            } else {
                landmarksWrapper.clearResults();
            }
        } else {
            // Clear detections array if no valid detection is found
            this.setState({ detectionsArray: [] });
        }
    };

    startSyncTimer = () => {
        console.log("startSyncTimer");
        this.stopSyncTimer(); // Ensure any existing timer is cleared
        this.timer = setInterval(() => {
            if (this.state.isPreviewMode) {
                const currentTime = this.uploadedVideo.current.currentTime;
                this.seekToDataAtTime(currentTime);
            }
        }, 200); // Polling interval set to 200ms
    }

    stopSyncTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    seekToDataAtTime = (currentTime) => {
      //  console.log("seekToDataAtTime", currentTime);
        this.alterOvertimeData(currentTime);
        this.setDetectionArrayByTime(currentTime);
    };


    renderVideo = (width, height) => {
        const { videoFile, detectionsArray, videoSrc, playerType, showFaceLandmarks, isPreviewMode } = this.state;

        if (playerType === PLAYER_TYPE.VIDEO_UPLOAD && !videoFile) {
            return null;
        }

        let formattedHeight = height;
        let isContentFeedback = playerType === PLAYER_TYPE.CONTENT_FEEDBACK;

        if ([PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD].includes(playerType)) {
            formattedHeight = height - OVERTIME_CHART_HEIGHT;
        }

        let vid = document.getElementById('myVideo');
        if (PLAYER_TYPE.CAMERA === playerType || isContentFeedback) {
            vid = document.getElementById('videoElement');
        }

        let CONTENT_FEEDBACK_CAM_TOP = 50;
        let CONTENT_FEEDBACK_CAM_RIGHT = 10;

        let canvasLeft = 0;
        let canvasTop = 0;
        let vidDims = {};

        if (vid) {
            let { width, height } = videoDimensions(vid);
            canvasLeft = isContentFeedback ? CONTENT_FEEDBACK_CAM_RIGHT : (vid.offsetWidth - width) / 2;
            canvasTop = isContentFeedback ? CONTENT_FEEDBACK_CAM_TOP : (vid.offsetHeight - height) / 2;
            vidDims = { width, height: height, offsetWidth: vid.offsetWidth, offsetHeight: vid.offsetHeight};
            if(isPreviewMode){
             //   vidDims.height = vidDims.height - 100
            }
        }
        if(isPreviewMode){
            //console.log("vidDims", vidDims)
        }

        //console.log("Setting canvas-overlay2 height:", isPreviewMode, vidDims.height - 60 ,  vidDims.height);

        let frameTime = performance.now();

        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    flexDirection: 'column',
                    position: "relative",
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {
                    [PLAYER_TYPE.VIDEO_PLAYER, PLAYER_TYPE.VIDEO_UPLOAD].includes(playerType)
                    && (document.getElementById('videoUpload') && document.getElementById('videoUpload').files.length) || this.props.signup ?

                        <video
                            id="myVideo"
                            style={{ width: "100%", height: "auto", maxHeight: formattedHeight }}
                            controls={isPreviewMode}
                            autoPlay={true}
                            ref={this.uploadedVideo}
                            src={playerType === PLAYER_TYPE.VIDEO_PLAYER ? videoSrc : videoFile}
                        /> : null
                }

                {[PLAYER_TYPE.CAMERA].includes(playerType) ?
                    <div style={{ position: 'relative', width: "100%", height: formattedHeight }}>
                        <VideoStream
                            streamSuccessHandler={this.streamStarted}
                            width={width}
                            maxHeight={formattedHeight ? formattedHeight : "100vh"}
                            updateInput={this.updateInput}
                        />
                        <button
                            onClick={this.toggleCameraState}
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                zIndex: 1000,
                                padding: '10px 20px',
                                backgroundColor: '#000',
                                color: '#fff',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            {this.state.isCameraPlaying ? 'Pause' : 'Play'}
                        </button>
                    </div> : null
                }

                {isContentFeedback ? (
                    <>
                        <div
                            style={{
                                position: "absolute",
                                top: CONTENT_FEEDBACK_CAM_TOP,
                                right: CONTENT_FEEDBACK_CAM_RIGHT,
                                zIndex: 9999
                            }}
                        >
                            <VideoStream
                                streamSuccessHandler={() => {
                                }}
                                width={width}
                                maxHeight={formattedHeight ? formattedHeight : "100vh"}
                                updateInput={this.updateInput}
                            />
                        </div>

                        {this.renderContentFeedback(window.innerHeight - OVERTIME_CHART_HEIGHT)}
                    </>
                ) : null}
                {isPreviewMode ? ( <canvas
                    id={"canvas-overlay2"}
                    style={{
                        position: "absolute",
                        top: canvasTop,
                        [isContentFeedback ? "right" : "left"]: canvasLeft,
                        width: vidDims.width,
                        height: isPreviewMode ? vidDims.height - 60 : vidDims.height,
                        maxHeight: isPreviewMode ? `${vidDims.height - 60}px` : `${vidDims.height}px`, // Adding a max-height as a fallback
                        zIndex: 999,
                        visibility: showFaceLandmarks ? "visible" : "hidden"
                    }}
                />) : (<canvas
                    id={"canvas-overlay"}
                    style={{
                        position: "absolute",
                        top: canvasTop,
                        [isContentFeedback ? "right" : "left"]: canvasLeft,
                        width: vidDims.width,
                        height: vidDims.height,
                        zIndex: 999,
                        visibility: showFaceLandmarks ? "visible" : "hidden"
                    }}
                />)}


                <div
                    key={frameTime}
                    style={{
                        position: 'absolute',
                        zIndex: 998,
                        top: canvasTop,
                        [isContentFeedback ? "right" : "left"]: canvasLeft,
                        width: vidDims.width,
                        height: isPreviewMode ? vidDims.height - 60 : vidDims.height,
                    }}
                >
                    {detectionsArray && detectionsArray.length ? this.renderDetectionSquare(vidDims.height, vidDims.width) : null}
                </div>

                {[PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD, PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType) && this.renderOvertimeChart(width)}
            </div>
        );
    };

    toggleCameraState = () => {
        if(this.state.isCameraPlaying){
            let shouldReToggleLandmarks = this.state.showFaceLandmarks;
            this.setState({
                isCameraPlaying: false,
                shouldReToggleLandmarks,
                showFaceLandmarks: false
            })
        }else {
            this.setState({isCameraPlaying: true})
            this.detectionLoop()
            if(this.state.shouldReToggleLandmarks){
                this.setState({showFaceLandmarks: true})
            }
        }
    };

    onBrushChange = (e) => {
        const {overtime} = this.props
        //  console.log("onBrushChange", e, _.get(overtime, `[${e.endIndex}].timeSpent`))
        //  console.log(this.playerRef)

        this.playerRef.seekTo(_.get(overtime, `[${e.endIndex}].timeSpent`), "seconds")
    }

    renderOvertimeChart = (width) => {

        const {contentFeedbackEnded, playerType, showFaceLandmarks, chartActive, previewOverTime, isPreviewMode} = this.state
        const {overtime, faceIds, selectedIds, faceIdCount, selectId} = this.props

        if (!chartActive) {
            return null
        }

        return <SoloOverTimeChart
            overtime={isPreviewMode ? previewOverTime : overtime}
            faceIds={faceIds}
            faceIdCount={faceIdCount}
            selectedIds={selectedIds}
            showBrush={playerType === PLAYER_TYPE.CONTENT_FEEDBACK && contentFeedbackEnded}
            onBrushChange={this.onBrushChange}
            toggleFaceLandmarks={this.toggleFaceLandmarks}
            resetOverTime={this.resetOverTime}
            selectId={selectId}
            showFaceLandmarks={showFaceLandmarks}
            isJP={translations.selectedLocale === "ja"}
        />


        /*
            let modified, shouldModify = false,
                domain = [0, 100]

            shouldModify = true
            modified = _.cloneDeep(overtime)

            if (!chart_show_all_time && modified.length > 1) {

                const totalTimeSpent = modified[modified.length - 1].timeSpent;

                // Filter the array for the last 30 seconds
                const last30SecondsEntries = modified.filter(item => {
                    return item.timeSpent >= totalTimeSpent - 30 * 1000;
                });

                modified = last30SecondsEntries;
            }

            //  console.log("modified", modified)
            let chartWidth = width;

            if (selectedButtons.length === 1) {
                if (selectedButtons[0] === OVER_TIME_BUTTONS.YAW_ANGLE) {
                    domain = [-50, 50]
                } else if (selectedButtons[0] === OVER_TIME_BUTTONS.PITCH_ANGLE) {
                    domain = [-60, 60]
                } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN) {
                    domain = [-100, 100]
                } else if (selectedButtons[0] === OVER_TIME_BUTTONS.YAWN_RATIO) {
                    domain = [0, 100]
                } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_SCORE) {
                    domain = [0, 100]
                } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_NOSE) {
                    domain = [300, 350]
                }
            }

            return (
                <div style={{height: OVERTIME_CHART_HEIGHT, position: "absolute", left: 0, right: 0, bottom: 0}}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 12,
                        flexDirection: 'column'
                    }}>
                        <div style={{display: "flex", alignItems: "flex-start", width: "100%"}}>
                            {this.renderIdsButtons(faceIds)}
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            marginTop: 10

                        }}>
                            {this.renderOvertimeButtons([OVER_TIME_BUTTONS.MOOD, OVER_TIME_BUTTONS.ENERGY, OVER_TIME_BUTTONS.STRESS, OVER_TIME_BUTTONS.ENGAGEMENT, OVER_TIME_BUTTONS.FATIGUE, OVER_TIME_BUTTONS.ATTENTION])}
                            <div style={{
                                width: '100%',
                                height: '100%',
                            }}>
                                <OverTimeChart
                                    x={{key: "index"}}
                                    y={{key: "value"}}
                                    data={shouldModify ? modified : (overtime || [{}])}
                                    selectedEmotions={selectedButtons}
                                    tooltipLabelFormatter={this.tooltipLabelFormatter}
                                    domain={domain}
                                    selectedIds={selectedIds}
                                    showBrush={playerType === PLAYER_TYPE.CONTENT_FEEDBACK && contentFeedbackEnded}
                                    onBrushChange={this.onBrushChange}
                                />
                            </div>
                            {this.renderOvertimeButtons([OVER_TIME_BUTTONS.YAWN_RATIO, OVER_TIME_BUTTONS.EYES, OVER_TIME_BUTTONS.YAW_ANGLE, OVER_TIME_BUTTONS.PITCH_ANGLE, OVER_TIME_BUTTONS.FROWN, OVER_TIME_BUTTONS.BLINKS_PER_MINUTE])}
                        </div>

                    </div>
                </div>
            )*/
    }

    renderImageUpload = (width, height) => {

        const {showFaceLandmarks} = this.state
        const {detectionsArray, moduleEditorActive} = this.state


        let image = document.getElementById('myImage')
        let canvasLeft = 0;
        let canvasTop = 0;
        let imageDims = {}
        if (image) {
            let {width, height} = imageDimensions(image)
            canvasLeft = (image.offsetWidth - width) / 2
            canvasTop = (image.offsetHeight - height) / 2
            imageDims = {width, height}

        }

        return (

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: "relative"}}>
                <img id="myImage" src="" ref={this.uploadedImage} style={{
                    display: 'block',
                    maxWidth: width,
                    maxHeight: height,
                    width: 'auto',
                    height: 'auto'
                }}/>
                <div style={{position: 'absolute', top: canvasTop, left: canvasLeft}}>
                    {detectionsArray.length ? this.renderDetectionSquare(imageDims.height, imageDims.width) : null}
                </div>
                <canvas id={"canvas-overlay"} style={{
                    position: "absolute",
                    top: canvasTop,
                    left: canvasLeft,
                    visibility: showFaceLandmarks ? "visible" : "hidden"
                }}/>
                <OverTimeButton style={{
                    position: "absolute",
                    bottom: 50,
                    right: 50,
                    backgroundColor: colors.black,
                    color: colors.white,
                    borderRadius: 0,
                    width: 150
                }}
                                onClick={this.toggleFaceLandmarks}>
                    {showFaceLandmarks ? getString("hide_landmarks") : getString("show_landmarks")}
                </OverTimeButton>

                {moduleEditorActive && (
                    <>
                        {this.renderOvertimeChart(width)}
                        <Button label={"Detect"} style={{position: "absolute", top: 10, left: 10, zIndex: 99999999999}}
                                onClick={() => {
                                    this.setState({detectedImage: false}, () => {
                                        this.detect()
                                    })
                                }}></Button>
                    </>
                )}
            </div>
        )
    }

    renderContentFeedback = (height) => {
        const {contentFeedbackUrl, contentFeedbackEnded} = this.state
        if (!contentFeedbackUrl) {
            return null
        }


        //    console.log("contentFeedbackUrl", contentFeedbackUrl)

        return (
            <MediaPlayer
                playing={this.state.contentFeedbackPlaying}
                url={contentFeedbackUrl}
                height={height}
                controls={!contentFeedbackEnded}
                onReady={(e) => {
                    this.playerRef = e['player'];
                    this.setState({contentFeedbackPlaying: true})
                }}
                onPlay={() => {
                    console.log("onPlay")
                    this.setState({contentFeedbackPlaying: true}, () => {
                        if (!this.state.contentFeedbackEnded) {
                            if (this.props.sdkUser) {
                                soloSdkClient.setContent("content_feedback", true)
                            }
                            this.detectionLoop()

                        }

                    })
                }}
                onPause={() => {
                    this.setState({contentFeedbackPlaying: false})
                    if (this.props.sdkUser) {
                        soloSdkClient.stopMonitoring()
                    }
                }}
                onEnded={() => {
                    this.setState({
                        contentFeedbackPlaying: false,
                        contentFeedbackEnded: true
                    })
                    if (this.props.sdkUser) {
                        soloSdkClient.stopMonitoring()
                    }

                }}
                onError={() => {
                    this.setState({contentFeedbackPlaying: false})
                    if (this.props.sdkUser) {
                        soloSdkClient.stopMonitoring()
                    }
                }}
                onProgress={(stats) => {
                    this.contentFeedbackTime = stats.playedSeconds
                    // console.log("onDuration", stats, this.contentFeedbackTime)
                }}

            />
        )
    }

    renderPlayer = () => {

        const {playerType} = this.state

        const width = this.player.current && this.player.current.offsetWidth
        let height = window.innerHeight

        if ([PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType)) {
            height = 150
        }

        if (!this.state.emotionDetectionInitialized) {
            return null
        }

        return (
            <div ref={this.player}
                 style={{
                     justifyContent: 'center',
                     alignItems: playerType === PLAYER_TYPE.VIDEO_UPLOAD ? 'flex-end' : 'center',
                     display: 'flex',
                     position: 'absolute',
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     margin: 'auto',
                 }}>
                {playerType === PLAYER_TYPE.IMAGE_UPLOAD
                    ? this.renderImageUpload(width, height)
                    : this.renderVideo(width, height)
                }


                <div style={{right: 20, bottom: OVERTIME_CHART_HEIGHT + 20, position: "absolute"}}>
                    {this.state.cplxModelActive ? this.renderChart() : this.state.avatarActive &&
                        <Avatar videoRef={this.avatarRef}/>}
                </div>

            </div>
        )
    }

    changeLanguage = () => {
        const {currentLang} = this.state
        currentLang === "en" ? setLocale("ja") : setLocale("en")
        this.setState({currentLang: translations.selectedLocale})
    }

    currentLangVal = () => {
        let otherLocale = translations.supportedLanguages.find(l => l.key !== translations.selectedLocale);
        return otherLocale ? otherLocale.name : translations.fallbackLocale;
    }

    renderHeaderMenu = () => {
        return (
            <div ref={this.language}
                 style={{position: 'absolute', right: 15, top: 0, display: 'flex', flexDirection: "row"}}>


                <div style={{
                    width: 69,
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.white,
                    backgroundColor: colors.black,
                    borderRadius: "0 0 10px 10px",
                    cursor: 'pointer'
                }} onClick={() => {
                    window.open("https://www.loom.com/share/d748e1b965d4422d962ea0d636f84607?sid=9bb61e76-539f-4da6-bddb-f3e22b83620e", "_blank")
                }}>
                    {getString("tutorial")}
                </div>
                <div style={{
                    width: 69,
                    height: 45,
                    marginLeft: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.white,
                    backgroundColor: colors.black,
                    borderRadius: "0 0 10px 10px",
                    cursor: 'pointer'
                }} onClick={this.changeLanguage}>
                    {this.currentLangVal()}
                </div>
                <img onClick={logout} style={{marginLeft: 10, cursor: "pointer"}}
                     src={getAsset("icons/logout.svg")}/>
            </div>
        )
    }

    calculateWidget = (value, level) => {
        //     console.log("calculateWidget", value, level)
        const {currentlyDisplayed} = this.state
        const {widgetResultsArray} = this.props
        //    console.log("widgetResultsArray", widgetResultsArray)
        //   console.log("currentlyDisplayed", currentlyDisplayed)
        let current = widgetResultsArray.filter(w => w.id === currentlyDisplayed.id)

        let defaultWidget = {color: "#b0b0b0", value: 0, numeral: ""}
        let widget = defaultWidget;


        if (current && current.length > 0) {
            widget = _.cloneDeep(get(current[0], level, defaultWidget)) || defaultWidget
            let currentVal = get(current[0], value, 0) || 0
            //console.log("currentVal", currentVal)
            widget["numeral"] = Math.round(currentVal * 100)
        }

        //  console.log("widget", widget)

        return widget
    }

    renderWidgets = () => {
        const width = _.get(this.emotionsSidebar, "current.clientWidth", 179)

        const stressLevel = this.calculateWidget("stress", "stressLevel")
        const interestLevel = this.calculateWidget("interest", "interestLevel")
        const engagementLevel = this.calculateWidget("engagement", "engagementLevel",)
        const wellbeingLevel = this.calculateWidget("wellbeing", "wellbeingLevel")
        const energyLevel = this.calculateWidget("energy", "energyLevel")
        const valenceLevel = this.calculateWidget("valence", "valenceLevel")

        return (
            <div style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                top: 0,
                width: width,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: 44
            }}>
                {this.renderWidget(getString("mood"), valenceLevel.numeral, valenceLevel.value, valenceLevel.color, width)}
                {this.renderWidget(getString("energy"), energyLevel.numeral, energyLevel.value, energyLevel.color, width)}
                {/*   {this.renderWidget(getString("wellbeing"), wellbeingLevel.numeral, wellbeingLevel.value, wellbeingLevel.color, width)}*/}
                {this.renderWidget(getString("stress"), stressLevel.numeral, stressLevel.value, stressLevel.color, width)}
            </div>
        )
    }

    shouldRenderWidgets = () => {
        const {playerType} = this.state
        return false
    }
    renderWidget = (name, numeral, value, color, width) => {
        const {showEmotionsBar} = this.state
        return <Widget
            title={name}
            value={showEmotionsBar ? numeral : 0}
            progressColor={showEmotionsBar ? color : colors.warm_grey}
            presentationValue={showEmotionsBar ? getString(value) : null}
            width={width}
            grayOut={!showEmotionsBar}/>
    }

    setModelActiveState = (model, active) => {
        this.setState({[model]: active})
    }

    renderToggles = () => {
        const {
            optionsOpened,
            chartActive,
            sidebarActive,
            boundingBoxActive,
            blendShapesActive,
            handsModelActive,
            moduleEditorActive,
            rightSidebarActive,
            maskModelActive
        } = this.state

        if (!optionsOpened) {
            return null
        }

        return (
            <div style={{
                position: "absolute",
                bottom: OVERTIME_CHART_HEIGHT + 20,
                left: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                zIndex: 999999
            }}>


                {process.env.REACT_APP_ENV === "lab" ? (
                    <>
                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={chartActive} onChange={(active) => {
                                this.setModelActiveState("chartActive", active)
                            }}></Switch>
                            <SwitchLabel>{getString("chart")}</SwitchLabel>
                        </SwitchWrapper>

                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={boundingBoxActive} onChange={(active) => {
                                this.setModelActiveState("boundingBoxActive", active)
                            }}></Switch>
                            <SwitchLabel>{getString("bounding_box")}</SwitchLabel>
                        </SwitchWrapper>

                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={blendShapesActive} onChange={(active) => {
                                this.toggleOption("blendShapesActive")
                            }}></Switch>
                            <SwitchLabel>{getString(blendShapesActive ? "disable_blendshapes" : "enable_blendshapes")}</SwitchLabel>
                        </SwitchWrapper>

                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={handsModelActive} onChange={(active) => {
                                this.toggleOption("handsModelActive")
                            }}></Switch>
                            <SwitchLabel>{getString(handsModelActive ? "disable_hands" : "enable_hands")}</SwitchLabel>
                        </SwitchWrapper>

                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={moduleEditorActive} onChange={(active) => {
                                this.toggleOption("moduleEditorActive")
                            }}></Switch>
                            <SwitchLabel>{getString(moduleEditorActive ? "disable_module_editor" : "enable_module_editor")}</SwitchLabel>
                        </SwitchWrapper>

                        <SwitchWrapper>
                            <Switch height={18} width={36} checked={rightSidebarActive} onChange={(active) => {
                                this.toggleOption("rightSidebarActive")
                            }}></Switch>
                            <SwitchLabel>{getString(rightSidebarActive ? "disable_rightSidebar" : "enable_rightSidebar")}</SwitchLabel>
                        </SwitchWrapper>


                    </>
                ) : null
                }

                <SwitchWrapper>
                    <Switch height={18} width={36} checked={maskModelActive} onChange={(active) => {
                        this.setModelActiveState("maskModelActive", active)
                        toggleMaskDetection()
                    }}></Switch>
                    <SwitchLabel>{getString("mask_model")}</SwitchLabel>
                </SwitchWrapper>
                <SwitchWrapper>
                    <Switch height={18} width={36} checked={this.state.emojiFilterActive} onChange={(active) => {
                        this.toggleEmoji()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.emojiFilterActive ? "disable_emoji" : "enable_emoji")}</SwitchLabel>
                </SwitchWrapper>

                <SwitchWrapper>
                    <Switch height={18} width={36} checked={this.state.cplxModelActive} onChange={(active) => {
                        this.toggleCplxModel()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.cplxModelActive ? "disable_cplxModel" : "enable_cplxModel")}</SwitchLabel>
                </SwitchWrapper>

                <SwitchWrapper>
                    <Switch height={18} width={36} checked={this.state.alertsActive} onChange={(active) => {
                        this.toggleAlerts()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.alertsActive ? "disable_alerts" : "enable_alerts")}</SwitchLabel>
                </SwitchWrapper>
            </div>
        )
    }

    render() {

        const {
            loader,
            sidebarActive,
            rightSidebarActive,
            contentFeedbackPopupOpen,
            selectedButtons,
            moduleEditorActive,
            blendShapesActive,
            currentlyDisplayed,
            demoSignupPopupOpen
        } = this.state

        const {time, fps} = this.state
        const {sdkUser, sdkHouse, alertsStats, faceIds} = this.props

        const shoulRenderModuleEditor = moduleEditorActive && [OVER_TIME_BUTTONS.FATIGUE, OVER_TIME_BUTTONS.ATTENTION].includes(selectedButtons[0]) && "lab" === process.env.REACT_APP_ENV


        const renderContainer = () => {
            return <Container fluid style={{height: "100%", position: "relative"}}>
                {!loader &&
                    (<Row style={{height: "100%"}}>
                        <Col md={2} ref={this.emotionsSidebar} style={{paddingRight: 0}}>
                            {sidebarActive && this.renderEmotionsBar()}
                        </Col>
                        <Col md={rightSidebarActive ? 8 : 10}>
                            {this.renderPlayer()}
                            <Logo/>
                            {!rightSidebarActive && this.renderHeaderMenu()}
                            {this.renderOptions()}
                            {this.shouldRenderWidgets() && this.renderWidgets()}
                            {this.renderToggles()}

                            {shoulRenderModuleEditor &&
                                <div style={{
                                    position: "absolute",
                                    right: 50,
                                    top: 150,
                                    zIndex: 99999
                                }}>
                                    <ModuleEditor moduleType={selectedButtons[0]}/>
                                </div>
                            }
                        </Col>
                        {rightSidebarActive ? (<Col md={2} ref={this.blendshapesProgressBar}
                                                    style={{paddingLeft: 0, backgroundColor: "#fff"}}>
                            {this.renderHeaderMenu()}
                            {rightSidebarActive ? blendShapesActive ? <Sidebar itemHeight={30} items={[
                                {
                                    title: getString("faceId"),
                                    value: currentlyDisplayed ? currentlyDisplayed.id : null
                                },
                            ]}>{this.renderBlendShapes()}</Sidebar> : <Sidebar itemHeight={30}
                                                                               items={[
                                                                                   {
                                                                                       title: getString("sidebar_houseName"),
                                                                                       value: sdkUser && sdkHouse ? `SDK: ${sdkHouse}` : null
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_FPS"),
                                                                                       value: fps
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_speed"),
                                                                                       value: time
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_totalTime"),
                                                                                       value: formatMiliseconds(this.timeSpent)
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_totalNumOfFaces"),
                                                                                       value: faceIds.length
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_totalAttention"),
                                                                                       value: formatMiliseconds(alertsStats.totalAttentionTime)
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_avgAttention"),
                                                                                       value: formatMiliseconds(alertsStats.avgAttentionTime)
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_minAttention"),
                                                                                       value: formatMiliseconds(alertsStats.minAttentionTime)
                                                                                   },
                                                                                   {
                                                                                       title: getString("sidebar_maxAttention"),
                                                                                       value: formatMiliseconds(alertsStats.maxAttentionTime)
                                                                                   },
                                                                               ]}
                            /> : null}
                        </Col>) : null}

                    </Row>)}

                <ContentSelectionPopup isOpen={contentFeedbackPopupOpen} closeHandler={(url) => {
                    if (url) {
                        this.setState({
                            contentFeedbackPopupOpen: false,
                            contentFeedbackUrl: url,
                            playerType: PLAYER_TYPE.CONTENT_FEEDBACK
                        })
                    } else {
                        this.setState({
                            contentFeedbackPopupOpen: false
                        })
                    }

                }}/>

                {demoSignupPopupOpen && <SignUpPopup isOpen={demoSignupPopupOpen} closeHandler={() => {
                }}

                />}
            </Container>
        }

        if (demoSignupPopupOpen) {
            return (
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
                    {renderContainer()}
                </GoogleReCaptchaProvider>
            );
        } else {
            return renderContainer()
        }

    }
}

const mapStateToProps = ({emotionalData, engine, modules, faceId}) => ({
    overtime: emotionalData.overtime,
    widgetResultsArray: emotionalData.widgetResultsArray,
    marioReady: engine.play,
    marioReloading: engine.reloading,
    attentionConfig: modules.attention,
    fatigueConfig: modules.fatigue,
    faceIds: faceId.faceIds,
    selectedIds: faceId.selectedIds,
    faceIdCount: faceId.faceIdCount,
    alertsStats: emotionalData.alertsStats

});

const mapDispatchToProps = {
    pushToOvertime,
    resetOvertime,
    setWidgetResults,
    fetchModuleData,
    addFaceId,
    selectId,
    updateFaceIdCount,
    resetFaceId,
    handleAIUnit
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


const SwitchWrapper = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    text-align: center;

`

const SwitchLabel = styled.span`
    margin-left: 5px;
`

import React, { Component } from 'react';
import PopUp from 'reactjs-popup';
import Popup from "./Popup"
import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";
import Lottie from 'react-lottie';
import { getString } from '../utils/translations';

class ContentSelectionPopup extends Component {

    constructor() {
        super();
        this.state = {
            srcUrl: "",
            srcUrlFieldOpen: false,
            invalidVideoPopupOpen: false,
            validVideo: false
        }
    }

    updateInputValue(evt) {
        const val = evt.target.value;
        this.setState({
            srcUrl: val
        });
    }


    isValidVideoSrc = async (srcUrl) => {
       // const video = await this.loadVideo(videoFile)
      //  const videoDuration = video.duration
        let isValid = true

        if (!isValid) {
            this.setState({ invalidVideoPopupOpen: true, validVideo: false  })
        } else {
            this.setState({ validVideo: true})
            // this.props.closeHandler({ name, date, option, videoFile, videoDuration: this.state.videoDuration })
        }

        return isValid
    }
    submit = () => {
        const { srcUrl} = this.state
        //validate src url is a valid youtube or vimeo url
        const valid = this.isValidVideoSrc(srcUrl)
        if(valid){
            this.props.closeHandler(srcUrl)
        }
    }

    closeInvalidVideoPopup = () => {
        this.setState({ invalidVideoPopupOpen: false })
    }

    handleSrcUrlClick = () => {
        const { srcUrl, srcUrlFieldOpen } = this.state
        if (srcUrl && srcUrlFieldOpen) {
            return
        }
        this.setState({ srcUrlFieldOpen: !srcUrlFieldOpen })
    }

    render() {

        const { isOpen, closeHandler, title, loading, loader, error } = this.props
        const { srcUrl, srcUrlFieldOpen, invalidVideoPopupOpen , validVideo} = this.state

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };


        return (
            <div>
                <PopUp
                    position="center"
                    closeOnDocumentClick//={false}
                    open={isOpen}
                    onClose={closeHandler}
                    contentStyle={{
                        width: 335,
                        borderRadius: 9,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: 75
                    }}
                >
                    <PopupTitle>{title}</PopupTitle>

                    <Editable onClick={this.handleSrcUrlClick} style={{ cursor: 'pointer', height: 59 }} >
                        {`${getString("video_src_url")}`}
                        {srcUrlFieldOpen &&
                            <input value={srcUrl}
                                onChange={evt => this.updateInputValue(evt)}
                                onClickCapture={e => e.nativeEvent.stopPropagation()}
                                style={{ textAlign: "center",height: 40, width: 170, background: "rgba(0, 0, 0, 0)", marginBottom: 5, border: 'none', display: 'flex' }}
                                autoFocus />}
                    </Editable>


                    <ErrorMessage >{error}</ErrorMessage>

                    <Footer>
                            <FooterButton style={{ background: colors.blue, color: "#fff" }}
                            onClick={this.submit}
                                disabled={!validVideo}
                            >

                                    {loading ? (
                                        <Lottie
                                            options={defaultOptions}
                                            height={100}
                                            width={100}
                                        />) : (
                                        getString("submit")
                                    )}

                            </FooterButton>
                    </Footer>
                </PopUp>


                {invalidVideoPopupOpen && <Popup
                    closeOnDocumentClick={true}
                    isOpen={invalidVideoPopupOpen}
                    closeHandler={this.closeInvalidVideoPopup}
                    title={getString("invalid_video_upload_popup_title")}
                    description={getString("invalid_video_upload_popup_message")}
                    footerButtons={[{ label: getString("okay_button"), onPress: this.closeInvalidVideoPopup }]}
                />}
            </div >
        );
    }
}

const PopupTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6875em;
  line-height: 2.3125em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0190110625em;
  text-transform: capitalize; 
  /* Main 1 */
  color: #536EEC;
`

const Editable = styled.div`
    margin-bottom: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 304px;
    height: 52px;
    background: rgba(224, 231, 255, 0.2);
    border: 0.948611px solid #536EEC;
    box-sizing: border-box;
    border-radius: 4.74306px;
`

const Error = styled.div`
color: red;
`

const Options = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 22px;
`

const Option = styled.div`
    margin: 7px;
    width: 147px;
    height: 55px;
    background: #FFFFFF;
    border: 1.5px solid #536EEC;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    letter-spacing: 0.536252px;
    color: #000000;
    cursor: pointer;
`

const PopupMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125em;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #303030;
//   margin-bottom: 0.7em;
`

const Footer = styled.div`
    display: flex;
    position: absolute;
    bottom: -0.125em;
    left: -0.0625em;
    right: -0.0625em;
    height: 3.125em;
    box-shadow: 0em 0.070974375em 0.88718125em rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 9px 9px;
`

const FooterButton = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 2.6em;


    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.19643125em;
    line-height: 1.625em;
    text-align: center;
    letter-spacing: 0.03351575em;

    text-transform: capitalize; 
    border-radius: 0px 0px 9px 9px;

    flex: 1
`

const ErrorMessage = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing:0.0190110625em;

  /* Main 2 */

  color: #CC1D6C;
`

export default ContentSelectionPopup;

export const EMOTIONS = {
    CALM: "CALM",
    HAPPY: "HAPPY",
    SAD: "SAD",
    ANGRY: "ANGRY"
}

export const INDICATORS = {
    MOOD: "MOOD",
    STRESS: "STRESS",
    ENERGY: "ENERGY"
}

export const PROGRESS_BARS = [
    { title: "happy", color: "#FFBD58", emotion: "happy" },
    { title: "neutral", color: "#6FCF97", emotion: "neutral", },
    { title: "sad", color: "#EB5757", emotion: "sad" },
    { title: "angry", color: "#000000", emotion: "angry" },
    { title: "disgust", color: "#828282", emotion: "disgusted" },
    { title: "fear", color: "#BB6BD9", emotion: "fearful" },
    { title: "surprise", color: "#56CCF2", emotion: "surprised" },
]

export const OPTIONS = [
    { title: "demo_video_1", videoName: "CarRide" },
    { title: "demo_video_2", videoName: "Medical" },
    { title: "demo_video_3", videoName: "ZoomCall" },
    { title: "demo_video_4", videoName: "WatchingTV" }
]

export const LEVELS = {
    HIGH_STRESS: "high",
    NEUTRAL: "neutral",
    MEDIUM: "medium",
    LOW_STRESS: "low",
    HIGH_ENGAGEMENT: 'high_engagement',
    LOW_ENGAGEMENT: 'low_engagement',
    LIKE: "like",
    DISLIKE: "dislike",
    NO: "no_stress_data"
}

export const ASYNC_STORAGE_KEYS = {
    TRANSLATIONS: "translations",
    TRANSLATIONS_VERSION: "translations_version",
    TRANSLATIONS_SELECTED_LOCALE: "translations_locale"
}

export const PLAYER_TYPE = {
    IMAGE_UPLOAD: 'image_upload',
    VIDEO_UPLOAD: 'video_upload',
    VIDEO_PLAYER: 'video_player',
    CAMERA: 'camera',
    CHECKUP_CAMERA: 'checkup_camera',
    MARIO_JUMP: 'mario_jump',
    CONTENT_FEEDBACK: 'content_feedback',
}

export const FACE_API_OPTIONS = {
    SSD: "SsdMobilenetv1Options",
    TINY: "TinyFaceDetectorOptions"
}

export const OVER_TIME_BUTTONS = {
    ENGAGEMENT: "engagement",
    INTEREST: "interest",
    STRESS: "stress",
    MOOD: "mood",
    MOOD2: "mood2",
    MOOD3: "mood3",
    ENERGY: "energy",
    ENERGY2: "energy2",
    ENERGY3: "energy3",
    WELLBEING: "wellbeing",
    YAWN: "yawn",
    YAWN_RATIO: "yawnRatio",
    EYES: "openEyes",
    LEFT_EYE: "openLeftEye",
    RIGHT_EYE: "openRightEye",
    YAW_ANGLE: "yawAngle",
    PITCH_ANGLE: "pitchAngle",
    FROWN: "eyebrowEyeRatio",
    FROWN_SCORE: "frownScore",
    FROWN_NOSE: "noseEyebrowRatio",
    FATIGUE: "fatigue",
    ATTENTION: "attention",
    BLINKS_PER_MINUTE: "blinksPM",
    LIGHT_INDEX: "lightIndex",
    LEFT_RIGHT_LIGHT_DIFFERENCE: "leftRightLightDifference",
    TOP_BOTTOM_LIGHT_DIFFERENCE: "topBottomLightDifference",
    FACE_BACKGROUND_LIGHT_DIFFERENCE: "faceBackgroundLightDifference",
    LUX: "lux",
    LUX2: "lux2",
    FILTER_REASON: "filterReason",
    yawFallback: "yawFallback",
    pitchFallback: "pitchFallback",
    rollFallback: "rollFallback",
}

import React from 'react';

const SidebarItem = (props) => {
    const { title, value, itemHeight } = props;

    return (
        <div style={{marginTop: 10}}>
            <h2 style={{
                fontSize: 15,
                fontWeight: 'bold',
                color: "#000",
                textAlign: "center"

            }}>{title}</h2>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#E0E0E0',
                width: '100%',
                height: itemHeight || 57,
                fontSize: 18,
                color: "#2E384D"
            }}>
                {value}
            </div>
        </div>
    );
};

export default SidebarItem;

export default {
    pale_red: "#d9524e",
    blue: "#536eec",
    lipstick: "#cc1d6c",
    red: "#ff0000",
    black: "#000000",
    warm_grey: "#909090",
    steel: "#7d8297",
    dark_grey: "#696969",
    white: "#fff",
    emotionYellow: "#EFBE59",
    emotionGreen: "#a3c879",
    emotionRed: "#ee4d33",
    secondary: "#CC1D6C",
    bg: "#efefef",
    calm: "#6FCF97",
    happy: "#eebd59",
    sad: "#e9564e",
    angry: "#000",
    mild: "#F2994A",
    angryBlue: "#30619B",
    mood: "#d1568d",
    energy: "#6778c7",
    wellbeing: "#05b5fa",
    yellowish: "#FFBD58",
    gray: "#4F4F4F",
    tomato: "#EB5757",
    green: "#27AE60",
    engagement: "#eebd59",
    interest: "#6FCF97",
    stress: "#e9564e",
}
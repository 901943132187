import _, {get} from "lodash"
import {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertCheckupEmotions,
    calcValenceTracker as calcValence,
    calcEnergyTracker as calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement,
} from "solo-common"
import {
    YAWN_CERTAINTY_THRESHOLD,
    YAWN_TIREDNESS_TIME_THRESHOLD,
    EYE_CLOSED_CERTAINTY_THRESHOLD,
    EYE_CLOSED_ASLEEP_TIME_THRESHOLD,
    EYE_BLINKED_CERTAINTY_THRESHOLD,
    HIGH_STRESS_ALERT_THRESHOLD,
    YAW_ANGLE_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_ALERT_THRESHOLD,
    PITCH_ANGLE_MAX_ALERT_THRESHOLD,
    HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
} from "../../api/remoteConfig";
import {getString} from "../translations";
import {normalizeValue} from "../../views/Demo/helpers";

export const convertEmotions = (emotionsTracker) => {
    // console.log("emotionsTracker", emotionsTracker)
    const avg = calcEmotionsAvg(emotionsTracker, false)
    const valence = calcValence(avg)
    const energy = calcEnergy(avg)
    const stress = calcStress(avg)
    const wellbeing = calcWellbeing(avg)
    const interest = calcInterest(avg)
    const engagement = calcEngagement(avg)
    // console.log("convertEmotions", { valence, energy, avg, stress, wellbeing })
    return { valence, energy, avg, stress, wellbeing, interest, engagement }
}

export const calcMoodV3 = ({happiness, neutral, angry, disgusted, surprised, sad, fearful}) => {

    let valence = 0.5 + (
        (0.5 * happiness) +
        (-0.5 * sad) +
        (0 * surprised) +
        (-0.5 * fearful) +
        (-0.5 * disgusted) +
        (0.1 * neutral) +
        (-0.5 * angry)
    );
    return normalizeValue(valence)
}

export const calcEnergyV3 = ({happiness, neutral, angry, disgusted, surprised, sad, fearful}) => {

    let energy = 0.5 + (
        (0.5 * happiness) +
        (-0.5 * sad) +
        (0.5 * surprised) +
        (0.5 * fearful) +
        (0.5 * disgusted) +
        (-0.45 * neutral) +
        (0.5 * angry)
    );
    return normalizeValue(energy)
}

export const parseEmotions = (emotions) => {
    return {
        "neutral": emotions.neutral,
        "happy": emotions.happy,
        "sad": emotions.sad,
        "angry": emotions.angry,
        "fearful": emotions.fearful,
        "disgusted": emotions.disgusted,
        "surprised": emotions.surprised,
    }
}

export const thresholdEmotion = (emotion, threshold) => {
    return emotion >= threshold && emotion <= 1;
}

export const fromBoolToEmotion = (value) => {
    if(value) {
        return 1
    }
    return 0
}


export function calculateFatigueScore(blendshapes) {

    const fatigueWeights = {
        eyesBlink: 1,
        eyesSquint: 0.7, // Indicative of tired eyes
        jawOpen: 2, // Yawning
        mouthStretch: 0.5, // Could indicate tiredness
        browInnerUp: 0.4, // Eyebrow movements might indicate fatigue
        browsDown: 0.4,
    };

// Baseline norms for fatigue (set these based on normal, non-fatigued state data)

    const baselineNorms = {
        eyesBlink: { mean: 0.1, sd: 0.05 },
        eyesSquint: { mean: 0.2, sd: 0.1 },
        jawOpen: { mean: 0.1, sd: 0.05 },
        mouthStretch: { mean: 0.2, sd: 0.1 },
        browInnerUp: { mean: 0.2, sd: 0.1 },
        browsDown: { mean: 0.2, sd: 0.1 },
    };

    let fatigueScore = 0;

    for (const key in blendshapes) {
        if (baselineNorms[key] && fatigueWeights[key]) {
            const deviation = Math.abs(blendshapes[key].score - baselineNorms[key].mean);
            const normalizedDeviation = deviation / baselineNorms[key].sd;
            fatigueScore += normalizedDeviation * fatigueWeights[key];
        }
    }

    return fatigueScore;

}



// Function to calculate the attention score
export function calculateAttentionScore(blendshapes) {

    const attentionWeights = {
        eyesBlink: 0.5,
        eyesLookUp: 1,
        eyesLookDown: 1,
        eyesLookIn: 1,
        eyesLookOut: 1,
        eyesWide: 0.5,
        browsDown: 0.7, // Eyebrow movements
        mouthOpen: 0.3,
        mouthPucker: 0.3,
        browInnerUp: 0.7,
    };

// Baseline norms for attention (set these based on attentive state data)

    const baselineNorms = {
        eyesBlink: { mean: 0.1, sd: 0.05 },
        eyesLookUp: { mean: 0.2, sd: 0.1 },
        eyesLookDown: { mean: 0.2, sd: 0.1 },
        eyesLookIn: { mean: 0.2, sd: 0.1 },
        eyesLookOut: { mean: 0.2, sd: 0.1 },
        browsDown: { mean: 0.2, sd: 0.1 }, // Baselines for eyebrows
        mouthPucker: { mean: 0.2, sd: 0.1 },
        browInnerUp: { mean: 0.2, sd: 0.1 },
        eyesWide: { mean: 0.3, sd: 0.15 },
    };

    let attentionScore = 0;

    for (const key in blendshapes) {
        if (baselineNorms[key] && attentionWeights[key]) {
            const deviation = Math.abs(blendshapes[key].score - baselineNorms[key].mean);
            const normalizedDeviation = deviation / baselineNorms[key].sd;
            attentionScore += normalizedDeviation * attentionWeights[key];
        }
    }

    return attentionScore;

}

export function calculateModuleScore(blendshapes, { weights, norms }) {
    let moduleScore = 0;

    for (const key in blendshapes) {
        if (norms[key] && weights[key]) {
            const deviation = Math.abs(blendshapes[key].score - norms[key].mean);
            const normalizedDeviation = deviation / norms[key].sd;
            moduleScore += normalizedDeviation * weights[key];
        }
    }

    return moduleScore;
}




export function headFacingCamera(detection) {
    //console.log("headFacingCamera", detection)
    if (detection.yawAngle > YAW_ANGLE_MAX_ALERT_THRESHOLD || detection.yawAngle < YAW_ANGLE_MIN_ALERT_THRESHOLD
        || detection.pitchAngle > PITCH_ANGLE_MAX_ALERT_THRESHOLD || detection.pitchAngle < PITCH_ANGLE_MIN_ALERT_THRESHOLD) {
        return false
    }
    return true
}

export function headFacingCameraEmotionFilter(detection) {

    let headNotFacingCamera = detection.yaw > YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD || detection.yaw < YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD
        || detection.pitch > PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD || detection.pitch < PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD
        || detection.roll > ROLL_ANGLE_MAX_EMOTION_FILTER_THRESHOLD || detection.roll < ROLL_ANGLE_MIN_EMOTION_FILTER_THRESHOLD;

    if(headNotFacingCamera){
         return false
   }
   return true
}



export const isHeadFacingCamera = (overtimeArr) => {
    let last = _.last(overtimeArr)
    //yaw - LR
    //pitch - UD
   // console.log("last", {pitch: last.pitchAngle, yaw: last.yawAngle})


    return headFacingCamera(last);
}

const calcBlinksPerMinute = (blinkCount, totalTime) => {
    //console.log("calcBlinksPerMinute", blinkCount, totalTime)
    if(!totalTime){
        return 0
    }
    let blinksPerMinute = (blinkCount / (totalTime / 1000)) * 60
   // console.log("blinksPerMinute", blinksPerMinute)
    return blinksPerMinute
}

export const calculateFrequencyPerMinute = (overtimeData, key, durationInSeconds = 60, filterFirstXSeconds = 0) => {
    if (!Array.isArray(overtimeData) || overtimeData.length === 0) {
        return null;
    }

    let count = 0;
    const durationInMilliseconds = durationInSeconds * 1000;
    const currentTime = _.get(_.last(overtimeData), 'timeSpent', null);
    let earliestTime = currentTime;

    // return 0 if the duration is less than the filterFirstXSeconds
    if (currentTime < filterFirstXSeconds * 1000) {
        return null;
    }

    for (let i = overtimeData.length - 1; i >= 0; i--) {
        const item = overtimeData[i];
        if (currentTime - item.timeSpent > durationInMilliseconds) {
            break;
        }
        earliestTime = item.timeSpent;

        if(typeof key === "function"){
            if(key(item)){
                count++;
            }
        }else if (item[key]) {
            count++;
        }


    }

    const actualDurationInMilliseconds = currentTime - earliestTime;
    const actualDurationInSeconds = actualDurationInMilliseconds / 1000;

    // Calculate the frequency per minute, accounting for the actual duration covered
    const frequencyPerMinute = (count / actualDurationInSeconds) * 60;
    return frequencyPerMinute || 0;
};

const calcBlinksPerXSeconds = (overtimeData, x = 10) => {
  //  console.log("calcBlinksPerMinute")
    if(!overtimeData || overtimeData.length < 1){
        return 0
    }
    let blinkCount = 0

    // get last 10 seconds from overtime data
    let currTime = _.get(_.last(overtimeData), `timeSpent`, null)
 //   console.log("currTime", currTime)
    for(let i = overtimeData.length - 1; i >= 0; i--){
        let item = overtimeData[i]
        if(currTime - item.timeSpent > x * 1000){
            break;
        }

        if(item.blink){
    //        console.log("item blink", item.timeSpent, currTime)
            blinkCount++
        }
    }


    // calc blinks per minute by last 10 seconds avg

    let blinksPerMinute = blinkCount * 6
    // console.log("blinksPerMinute", blinksPerMinute)
    return blinksPerMinute
}

export const calcOvertimePatterns = (overtimeArr, alert) => {

    let tiredness = detectTiredness(overtimeArr, alert)
    let asleep = detectAsleep(overtimeArr, alert)
    let blink = _.get(_.last(overtimeArr), `blink`, false);
    if(blink){
        alert.blinkCount++
    }
    if(tiredness.isYawning){
        alert.yawnCount++
    }


    let currentTime = _.get(_.last(overtimeArr), `timeSpent`, 0);
    let timeBetween = currentTime
    if(overtimeArr.length >= 2){
        let prevTime = _.get(overtimeArr, `[${overtimeArr.length - 2}].timeSpent`, 0);
        timeBetween = currentTime - prevTime
        // console.log("timeBetween", timeBetween, currentTime ,prevTime)
    }

   /* if(currentTime >= 60 * 1000){
        alert.bpm = calcBlinksPerXSeconds(alert.blinkCount, 60)
        console.log("alert.bpm < 60", alert.bpm)
    }else {*/

    alert.bpm = _.get(_.last(overtimeArr), `blinksPM`, false);

    alert.yawnPerMinute = calculateFrequencyPerMinute(overtimeArr, (item)=>{
        return isYawning(item.yawnRatio / 100);
    }, 60)
       // console.log("alert.bpm", alert.bpm)
   // }
    alert.bpts = calcBlinksPerXSeconds(overtimeArr, 10)
    alert.yawning = tiredness.tired
    let highStressData = detectHighStress(overtimeArr, alert);

    alert.highStress = highStressData.highStress
    alert.totalStressTime = highStressData.totalStressTime
    alert.eyesClosed = asleep.asleep
    alert.headFacingCamera = isHeadFacingCamera(overtimeArr)

    //console.log("focusTime", alert.headFacingCamera, alert.focusTime, timeBetween)

    alert.focusTime = alert.headFacingCamera ? alert.focusTime + timeBetween : alert.focusTime

    return{
        tiredness,
        asleep,
        blinkCount: alert.blinkCount,
        alert
    }
}

const detectTiredness = (overtimeArr, alert) => {
    // calc total yawn time
    let tired = false
    let last = _.last(overtimeArr)
  //  console.log("last", last)
    let yawning = isYawning(last.yawnRatio / 100);

    if(yawning) {
      //  console.log("Yawning!", items[index].yawnRatio)
        let prevTime = _.get(overtimeArr, `[${overtimeArr.length - 2}].timeSpent`, null)
        if(prevTime) {
            let timeFromPrevFrame = last.timeSpent - prevTime
           // console.log("timeFromPrevFrame", timeFromPrevFrame)
            alert.totalYawnTime += timeFromPrevFrame
          //  console.log("totalYawnTime", totalYawnTime)
        }else {
            alert.totalYawnTime += 10
        }
    }else {
        alert.totalYawnTime = 0
    }
    if(alert.totalYawnTime > YAWN_TIREDNESS_TIME_THRESHOLD){
        tired = true
    }
    return {
        tired,
        totalYawnTime: alert.totalYawnTime,
        isYawning: yawning
    }
}

const isYawning = (value) => {
    let b = value >= YAWN_CERTAINTY_THRESHOLD;
    //console.log("isYawning", {value, b, YAWN_CERTAINTY_THRESHOLD})
    return b
}

const detectAsleep = (overtimeArr, alert) => {
    let asleep = false
    let last = _.last(overtimeArr)

   // console.log("index", index)

    let prevTime = _.get(overtimeArr, `[${overtimeArr.length - 2}].timeSpent`, null)
    if(eyeClosed(_.get(last, `eyesBlink`, 0))){
        if(prevTime) {
            let timeFromPrevFrame = last.timeSpent - prevTime
            alert.leftEyeClosedTimeInARow += timeFromPrevFrame
        }else {
            alert.leftEyeClosedTimeInARow += 10
        }
    }else {
        alert.leftEyeClosedTimeInARow = 0
    }
    if(eyeClosed(_.get(last, `eyesBlink`, 0))){
        if(prevTime) {
            let timeFromPrevFrame = last.timeSpent - prevTime
            alert.rightEyeClosedTimeInARow += timeFromPrevFrame
        }else {
            alert.rightEyeClosedTimeInARow += 10
        }
    }else {
        alert.rightEyeClosedTimeInARow = 0
    }

    if(alert.leftEyeClosedTimeInARow > EYE_CLOSED_ASLEEP_TIME_THRESHOLD && alert.rightEyeClosedTimeInARow > EYE_CLOSED_ASLEEP_TIME_THRESHOLD){
        asleep = true
    }

    return {
        asleep,
        leftEyeClosedTimeInARow: alert.leftEyeClosedTimeInARow,
        rightEyeClosedTimeInARow: alert.rightEyeClosedTimeInARow,
        avgClosedEye: (alert.leftEyeClosedTimeInARow + alert.rightEyeClosedTimeInARow) / 2
    }
}
const detectHighStress = (overtimeArr, alert) => {

    if(overtimeArr.length < 2){
        return false
    }

    let highStress = false

    let last = _.last(overtimeArr)
    let prev = overtimeArr[overtimeArr.length - 2]

    //  console.log("last", last)
    if(last.stress > HIGH_STRESS_ALERT_THRESHOLD * 100) {
      //  console.log("High Stress!", last.stress, HIGH_STRESS_ALERT_THRESHOLD, alert.totalStressTime)
        let prevTime = _.get(prev, `timeSpent`, null)
        if(prevTime) {
            let timeFromPrevFrame = last.timeSpent - prevTime
            alert.totalStressTime += timeFromPrevFrame
        }else {
            alert.totalStressTime += 10
        }

    }else {
        alert.totalStressTime = 0
    }
    if(alert.totalStressTime > HIGH_STRESS_ALERT_TIME_THRESHOLD_SECONDS * 1000){
        highStress = true
    }
    return {
        highStress,
        totalStressTime: alert.totalStressTime
    }
}
export const detectBlink = (overtimeArr, id) => {
    // get the last 2 frames
    if(overtimeArr.length < 2){
        return false
    }
    let last = _.last(overtimeArr)
    let prev = overtimeArr[overtimeArr.length - 2]

    let key = id ? `eyesBlink_${id}` : `eyesBlink`

    let prevBlinkValue = _.get(prev, key, null)
    let currentBlinkValue = _.get(last, key, null)
   // console.log("prev", prev)
  //  console.log("last", last)

    if(prevBlinkValue === null){
        return false
    }
    // check if both eyes are closed
    const currentBlink = eyeBlink(currentBlinkValue);
    const prevBlink = eyeBlink(prevBlinkValue);

    if(currentBlink && !prevBlink){
       return true
    }
    return false
}

const eyeClosed = (value) => {
    return value >= EYE_CLOSED_CERTAINTY_THRESHOLD
}

const eyeBlink = (value) => {
   // console.log("eyeBlink", value, EYE_BLINKED_CERTAINTY_THRESHOLD)
    return value > EYE_BLINKED_CERTAINTY_THRESHOLD
}

function getDistance(point1, point2) {
    const dx = point2._x - point1._x;
    const dy = point2._y - point1._y;
    return Math.sqrt(dx * dx + dy * dy);
}


export function detectYawnFromLandmarks(landmarks) {
    // Get the landmarks for the mouth
    const innerTopLip = landmarks[62];
    const innerBottomLip = landmarks[66];

    const upperTopLip = landmarks[51];
    const bottomBottomLip = landmarks[57];

    const noseCenter = landmarks[33];

    // console.log("points", {innerTopLip, innerBottomLip, upperTopLip, bottomBottomLip, noseCenter})

    // Using distance formula, calculate the distance between these two points
    const lipsDistance = getDistance(innerTopLip, innerBottomLip)
   // console.log("lipsDistance", lipsDistance)

    const upperLipsNoseDistance = getDistance(upperTopLip, noseCenter)
    //Math.sqrt(Math.pow(upperTopLip.x - noseCenter.x, 2) + Math.pow(upperTopLip.y - noseCenter.y, 2));
  //  console.log("upperLipsNoseDistance", upperLipsNoseDistance)

    const ratio = lipsDistance / upperLipsNoseDistance

  //  console.log("ratio", ratio)

    return ratio;
}

function calculateYawDistanceScore(landmarks) {
    const nose = landmarks[29];
    const leftSide = landmarks[0];
    const rightSide = landmarks[16];

    const leftDistance = nose.x - leftSide.x;
    const rightDistance = rightSide.x - nose.x;
    const totalDistance = rightDistance + leftDistance;

    const yawScore = (leftDistance - rightDistance) / totalDistance;

    return yawScore;
}

function calculateFaceDirectionScore2(landmarks) {
    const noseTop = landmarks[27];
    const noseBottom = landmarks[33];
    const chin = landmarks[8];

    const noseLength = noseBottom.y - noseTop.y;
    const noseToChinDistance = chin.y - noseBottom.y;

    const proportion = noseLength / noseToChinDistance;
    const faceDirectionScore = (proportion - 1) * 2 * -1 // Normalize to -1 to 1 range

    return faceDirectionScore;
}
export function detectHeadPose(landmarks) {
    // Get the relevant landmarks for head pose estimation
    const nose = landmarks[29];
    const leftEye = landmarks[36];
    const rightEye = landmarks[45];
    const chin = landmarks[8];

    // Calculate the yaw (left-right) angle
    const yawAngle = calculateYawDistanceScore(landmarks) * 90
  //  console.log("yawAngle", yawAngle)

    let pitchAngle = calculateFaceDirectionScore2(landmarks)
  //  console.log("pitchAngle", pitchAngle)

    //let pitchAngle2 = calculateFaceDirectionScore2(landmarks)

    // Calculate the pitch (up-down) angle
    const dy4 = leftEye.y - nose.y + rightEye.y - nose.y;
    const dy5 = nose.y - chin.y;
  //  const pitchAngle = Math.atan2(dy4, dy5) * (180 / Math.PI);

    // Calculate the roll (tilt) angle
    const dx6 = leftEye.x - rightEye.x;
    const dy6 = leftEye.y - rightEye.y;
    const rollAngle = Math.atan2(dy6, dx6) * (180 / Math.PI);

    return {...normalizeHeadPose({ horizontal: yawAngle, vertical: pitchAngle, tilt: rollAngle }), yawAngle, pitchAngle, rollAngle }
}
function normalizeHeadPose(headPose) {
    const { horizontal, vertical, tilt } = headPose;

    // Normalize yaw direction
    const yawDirection = horizontal < 0 ? 'L' : 'R';
    const normalizedYaw = Math.round((Math.abs(horizontal) / 1000 + Number.EPSILON) * 1000) + yawDirection;

    // Normalize pitch direction
   // const pitchDirection = vertical < 0 ? 'D' : 'U';
    const normalizedPitch = vertical.toFixed(2)

    // Normalize roll direction
    const rollDirection = tilt < 0 ? 'L' : 'R';
    const normalizedRoll = Math.round((Math.abs(tilt) / 1000 + Number.EPSILON) * 1000) + rollDirection;

    return {
        horizontal: normalizedYaw,
        vertical: normalizedPitch,
        tilt: normalizedRoll
    };
}

export function detectFrown(landmarks, box) {
    // Get the landmarks for the eyebrows (points 17 to 22 for left and 22 to 27 for right)
    const leftEyebrow = landmarks.slice(17, 22);
    const rightEyebrow = landmarks.slice(22, 27);

    const left0 = landmarks[17]
    const left1 = landmarks[18]
    const left2 = landmarks[19]
    const left3 = landmarks[20]
    const left4 = landmarks[21]

    const right0 = landmarks[22]
    const right1 = landmarks[23]
    const right2 = landmarks[24]
    const right3 = landmarks[25]
    const right4 = landmarks[26]

    const leftDist = getDistance(left0, left1) + getDistance(left1, left2) + getDistance(left2, left3) + getDistance(left3, left4)
    const rightDist = getDistance(right0, right1) + getDistance(right1, right2) + getDistance(right2, right3) + getDistance(right3, right4)
    const distBetween = getDistance(left4, right0)


    const upperTopLip = landmarks[51];

    const noseCenter = landmarks[33];
    const noseLeft = landmarks[31];
    const noseRight = landmarks[35];

    const noseWidth = getDistance(noseLeft, noseRight)

    const upperLipsNoseDistance = getDistance(upperTopLip, noseCenter)


    const frownScore =  (distBetween / upperLipsNoseDistance) * 100

    // Calculate the middle point of each eyebrow
    const leftEyebrowMiddle = landmarks[19];
    const rightEyebrowMiddle = landmarks[24];

    const eyebrowMiddleDistance = getDistance(leftEyebrowMiddle, rightEyebrowMiddle)

    // Get the landmarks for the eyes (points 36 to 47)
    const leftEye = landmarks.slice(36, 42);
    const rightEye = landmarks.slice(42, 48);

    // Calculate the eye width (average of left and right eye width)
    const eyeWidth = (getDistance(leftEye[0], leftEye[5]) + getDistance(rightEye[0], rightEye[5])) / 2;

    // calc eye height
    const eyeHeight = (getDistance(leftEye[1], leftEye[4]) + getDistance(rightEye[1], rightEye[4])) / 2;
    // Calculate the distance between the eyebrow middle points and eye top points
    const leftEyebrowToEyeTop = getDistance(leftEyebrowMiddle, leftEye[1]);
    const rightEyebrowToEyeTop = getDistance(rightEyebrowMiddle, rightEye[1]);
  //  console.log("leftEyebrowToEyeTop", leftEyebrowToEyeTop, rightEyebrowMiddle, rightEye[1])

    const eyebrowToEyeTop = (leftEyebrowToEyeTop + rightEyebrowToEyeTop) / 2;

    // Set a threshold to determine if a frown is detected (e.g., 0.2)
    const frownThreshold = 0.2; // Adjust the threshold based on your requirements

    // If the proportion of eyebrow-to-eye-top distance to eye width is below the threshold, a frown is detected
    const eyebrowEyeRatio = eyebrowToEyeTop / eyeWidth;

    let noseEyebrowRatio = (  eyebrowMiddleDistance / noseWidth) * 100

    let eyeFrownRatio = calculateFrownRatioEyes(landmarks)

    return {eyebrowEyeRatio,eyebrowToEyeTop,eyeWidth, leftDist, rightDist, distBetween, frownScore, upperLipsNoseDistance, eyeFrownRatio, noseEyebrowRatio};
}

function calculateFrownRatioEyes(landmarks) {


    // Calculate the middle point of each eyebrow
    const leftEyebrowMiddle = landmarks[19];
    const rightEyebrowMiddle = landmarks[24];

    // Get the landmarks for the eyes (points 36 to 47)
    const leftEye = landmarks.slice(36, 42);
    const rightEye = landmarks.slice(42, 48);

    const leftEyebrowToEyeTop = getDistance(leftEyebrowMiddle, leftEye[1]);
    const rightEyebrowToEyeTop = getDistance(rightEyebrowMiddle, rightEye[1]);
    //  console.log("leftEyebrowToEyeTop", leftEyebrowToEyeTop, rightEyebrowMiddle, rightEye[1])

    const eyebrowToEyeTop = (leftEyebrowToEyeTop + rightEyebrowToEyeTop) / 2;
    // Assuming landmarks is an array of [x, y] coordinates of 68 facial landmarks
    // Indices of relevant facial landmarks for the eyes
    const leftEyebrowMid = landmarks[19];
    const rightEyebrowMid = landmarks[24];
    const leftEyeMid = landmarks[39];
    const rightEyeMid = landmarks[42];
    // Calculate eyebrow width and eye height
    const eyebrowWidth = getDistance(leftEyebrowMid, rightEyebrowMid);
    const eyeHeight = getDistance(leftEyeMid, rightEyeMid);
    // Calculate frown ratio (Eye height / Eyebrow width)
    const frownRatioEyes = eyeHeight / eyebrowWidth;
    return eyebrowToEyeTop + eyebrowWidth;
}

export {
    calcEmotionsAvg,
    isEmotionHappy,
    isSad,
    isAngry,
    isDisgusted,
    isSurprised,
    isFearful,
    isNeutral,
    isEmotional,
    convertCheckupEmotions,
    calcValence,
    calcEnergy,
    calcStress,
    calcWellbeing,
    calcInterest,
    calcEngagement
}


import {calcEnergy, calcEngagement, calcInterest, calcStress, calcValence, calcWellbeing} from "../utils/bioFeedback";
import _ from "lodash";
import { DETECTION_SCORE_THRESHOLD_SDK_BILLING} from "./remoteConfig";
import {reportAIUnitsAndCalcTimeout} from "../services/aiUnits";


export const env = {
    // Here you can put all your Debug environment variables accessible from Javascript.
    name: "PRODUCTION",
    apiUrl: 'https://solo-sdk-332507.uc.r.appspot.com',
}

let apiUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://localhost:8081" : 'https://solo-sdk-332507.uc.r.appspot.com';
//let apiUrl = env.apiUrl;

export const routes = {
    getCheckupResults: `${apiUrl}/api/v1/emotional-checkup`,
    trackEvent: `${apiUrl}/api/v1/track-event`,
    getMonitoringResults: `${apiUrl}/api/v1/emotional-monitoring`,
    getAnonymousResults: `${apiUrl}/api/v1/anonymous-detection`,
    init: `${apiUrl}/api/v1/auth/init-sdk`,
    reportAIUnits: `${apiUrl}/api/v1/aiunit/report`,
}

export const EVENTS = {
    CHECKUP_STARTED: "checkup_started",
    CHECKUP_ENDED: "checkup_ended",
    CHECKUP_RESULTS: "checkup_results",
    MONITORING_RESULTS: "monitoring_results",
    MONITORING_STARTED: "monitoring_started",
    MONITORING_ENDED: "monitoring_ended",
    WIDGET_OPENED: "widget_opened",
    WIDGET_CLOSED: "widget_closed"
}



let _sessionId = null;
let _groupId = null;
let _contentId = null;
let _widgetOptions = null;
let _metadata = {};
let _userId = "abc";
let _userName = null
let _groupName = null;
let monitoringInterval = null;
let monitoringTimeout = null;
let soloSessionId = null;
let soloSessionUnitId = null
let detectionInterval = null;
let _sdkConfig = null;

let _apiKey = null//process.env.REACT_APP_API_KEY;
let _appId = null//process.env.REACT_APP_APP_ID;

let isMonitoring = false;
let ready = false;
let _detectionInterval = 1000;
let monitoringDuration = 0;
let unitDuration = 0;
let trackerIndex = 0;
export const MONITORING_INTERVAL_SECONDS = 10;


export const trackers = {
    a: {
        tracker: [],
        active: true,
        playedSeconds: 0,
        sending: false
    },
    b: {
        tracker: [],
        active: false,
        playedSeconds: 0,
        sending: false
    }
}

const cleanTrackerResultsData = (results) => {
    return results.map((r) => {
        return {
            expressions: r.expressions,
        }
    })
}

export const pushToTracker = ({results}) => {

    let expressions = results ? results.map((r) => {
    //    console.log("expressions", e.expressions);
        let score = r.detection.score;
        if (score < DETECTION_SCORE_THRESHOLD_SDK_BILLING) {
            console.log("invalid detection: score", score)
            return null
        }
        return r.expressions
    }): null;

   // console.log("pushToTracker", expressions)

    if(expressions && expressions.length > 0){
        //calc emotions avgs for each expression
        let avg = {
            angry: _.meanBy(expressions, "angry"),
            disgusted: _.meanBy(expressions, "disgusted"),
            fearful: _.meanBy(expressions, "fearful"),
            happiness: _.meanBy(expressions, "happy"),
            neutral: _.meanBy(expressions, "neutral"),
            sad: _.meanBy(expressions, "sad"),
            surprised: _.meanBy(expressions, "surprised"),
        }

        expressions = {
            ...avg,
            valence: calcValence(avg),
            energy: calcEnergy(avg),
            stress: calcStress(avg),
            wellbeing: calcWellbeing(avg),
            interest: calcInterest(avg),
            engagement: calcEngagement(avg),
        }
    }

 //   console.log("pushToTracker", expressions)



    let item = {
        index: trackerIndex,
        emotions: expressions,
        result: cleanTrackerResultsData(results),
        playedSeconds: unitDuration,
        duration: MONITORING_INTERVAL_SECONDS,
        monitoringDuration
    };
    trackers.a.tracker.push(item)

    if(trackers.b.active){
        trackers.b.tracker.push(item)
    }

    trackerIndex++;
}

const handleTrackerInterval = async (key, interval) => {
    if (trackers[key].active && Math.floor(trackers[key].playedSeconds) < MONITORING_INTERVAL_SECONDS) {
        trackers[key].playedSeconds = trackers[key].playedSeconds + interval / 1000
    }
    if(trackers[key].playedSeconds >= MONITORING_INTERVAL_SECONDS){
        if(trackers[key].sending){
            return;
        }
        trackers[key].sending = true;
        let duration = monitoringDuration;
        console.log("sending tracker", unitDuration, soloSessionId, soloSessionUnitId)
        let result = await solo.getMonitoringResults(trackers[key].tracker, MONITORING_INTERVAL_SECONDS, monitoringDuration, soloSessionId, soloSessionUnitId, monitoringDuration);
        //console.log("**************monitoring res", result)
        if (!result.error) {
            if (result.sessionUnitId && result.sessionUnitId !== soloSessionUnitId) {
                //console.log("got session unit id")
                soloSessionUnitId = result.sessionUnitId
            }
            if(result.soloSessionId && result.sessionUnitId !== soloSessionId){
               // console.log("got session id")
                soloSessionId = result.soloSessionId
            }
            emitEvent(EVENTS.MONITORING_RESULTS, result)
        }

        // reset tracker
        trackers[key].playedSeconds = 0
        trackers[key].tracker = [];
        trackers[key].sending = false;
    }

   // console.log("handleTrackerInterval", key, trackers[key].playedSeconds, trackers[key].active)
}

const monitor = async (interval) => {
    monitoringDuration = monitoringDuration + interval /1000;
    unitDuration = unitDuration + interval /1000;
   // estimateExpression();

    handleTrackerInterval("a", interval)

    if(!trackers.b.active && monitoringDuration >= MONITORING_INTERVAL_SECONDS/2){
        trackers.b.active = true;
    }

    handleTrackerInterval("b", interval)

}

const endMonitoring = async () => {

    let tracker = trackers.a.tracker.length > trackers.b.tracker.length ? trackers.a.tracker : trackers.b.tracker;
    let key = trackers.a.tracker.length > trackers.b.tracker.length ? "a" : "b";
    if (trackers[key].sending || tracker.length === 0){

        // reset trackers
        trackers["a"].playedSeconds = 0
        trackers["a"].tracker = [];
        trackers["a"].sending = false;

        trackers["b"].playedSeconds = 0
        trackers["b"].tracker = [];
        trackers["b"].sending = false;
        trackers["b"].active = false;

        return;
    }
    trackers[key].sending = true;
    let result = await solo.getMonitoringResults(tracker, MONITORING_INTERVAL_SECONDS, monitoringDuration, soloSessionId, soloSessionUnitId, monitoringDuration);
    //console.log("**************monitoring res", result)
    if (!result.error) {
        if (result.sessionUnitId && result.sessionUnitId !== soloSessionUnitId) {
            //console.log("got session unit id")
            soloSessionUnitId = result.sessionUnitId
        }
        if (result.soloSessionId && result.sessionUnitId !== soloSessionId) {
            // console.log("got session id")
            soloSessionId = result.soloSessionId
        }
        emitEvent(EVENTS.MONITORING_RESULTS, result)
    }

    // reset trackers
    trackers["a"].playedSeconds = 0
    trackers["a"].tracker = [];
    trackers["a"].sending = false;

    trackers["b"].playedSeconds = 0
    trackers["b"].tracker = [];
    trackers["b"].sending = false;
    trackers["b"].active = false;


}

export const getConfig = (key) => {
    return _sdkConfig ? _sdkConfig[key] : null
}

export const solo = {
    init: async ({ apiKey, appId, zoomMeeting }) => {
        console.log("*******************init", apiKey, appId)
        let headers = new Headers({
            'X-App-Id': appId,
            'X-API-Key': apiKey
        })

        let request = {
            method: 'POST',
            headers: headers,
        };
        try {
            let res = await fetch(routes.init, request).then((response) => response.json())
            console.log("res", res)
            if (res && res.success) {
                _apiKey = apiKey;
                _appId = appId;
                _sdkConfig = res.config
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error("error initializing sdk client", e)
            return false;
        }

        return false;
    },



    identify: ({ userId, groupId, sessionId, userName, groupName }) => {
        if (userId) {
            _userId = userId
        }
        if (groupId) {
            _groupId = groupId
        }
        if (userName) {
            _userName = userName
        }
        if (groupName) {
            _groupName = groupName
        }
        if (sessionId) {
            _sessionId = sessionId
        }

        if (!userId && !_userId) {
            throw "userId is required"
        }
        return true;
    },

    setContent: async (contentId, monitor) => {
        if(contentId === _contentId){
            return;
        }
        _contentId = contentId
        console.log("setContent")

        if(isMonitoring || monitor){
            await solo.stopMonitoring();
            solo.startMonitoring(_detectionInterval);
        }

        return true;
    },

    setSession: (sessionId) => {
        _sessionId = sessionId
        console.log("setSession")
        return true;
    },

    getSession: () => {
        return _sessionId;
    },

    getUserId: () => {
        return _userId;
    },

    setMetadata: (metadata) => {
        Object.assign(_metadata, metadata)
        console.log("setMetadata")
        return true;
    },

    removeMetadata: (keys) => {
        for (const k of keys) {
            if (_metadata[k]) {
                delete _metadata[k]
            }
        }
        console.log("removeMetadata")
        return true;
    },

    reset: () => {
        console.log("reset")
        return true;
    },

    startMonitoring: (detectionInterval) => {
        console.log("start monitoring")
        isMonitoring = true
        _detectionInterval = detectionInterval;
        let collectUsageData = getConfig("collectUsageData");
        console.log("collectUsageData", collectUsageData)
        if(collectUsageData === true){
            monitoringInterval = setInterval(() => {
                monitor(_detectionInterval)
            }, detectionInterval);
        }
        emitEvent(EVENTS.MONITORING_STARTED)

        reportAIUnitsAndCalcTimeout();
    },

    setDetectionInterval: (detectionInterval) => {
        _detectionInterval = detectionInterval;
        if(monitoringInterval){
            clearInterval(monitoringInterval)
        }
        if(isMonitoring){
            monitoringInterval = setInterval(() => {
                monitor(detectionInterval)
            }, detectionInterval);
        }
    },

    stopMonitoring: async () => {
        emitEvent(EVENTS.MONITORING_ENDED)
        isMonitoring = false;
        clearInterval(monitoringInterval)
        await endMonitoring();
        soloSessionUnitId = null;
        unitDuration = 0;
        monitoringDuration = 0;
        trackerIndex = 0;
    },

    getCheckupResults: async (tracker, duration) => {
        let headers = getAuthHeader();
        headers.append("Content-Type", "application/json")
        const { contentId, externalGroupId, externalGroupName, externalGroupUserId, externalGroupUserName, externalSessionId, metadata, page } = getRequestData()
        let init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                tracker,
                contentId,
                externalGroupId,
                externalGroupName,
                externalGroupUserId,
                externalGroupUserName,
                externalSessionId,
                metadata,
                page,
                duration
            })
        };

        return fetch(routes.getCheckupResults, init).then((response) => response.json())
    },

    getMonitoringResults: async (tracker, duration, playedSeconds, soloSessionId, sessionUnitId, monitoringDuration) => {
        let headers = getAuthHeader();
        headers.append("Content-Type", "application/json")
        const { contentId, externalGroupId, externalGroupName, externalGroupUserId, externalGroupUserName, externalSessionId, metadata, page } = getRequestData()
        let init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                tracker,
                soloSessionId,
                sessionUnitId,
                playedSeconds,
                monitoringDuration,
                contentId,
                externalGroupId,
                externalGroupName,
                externalGroupUserId,
                externalGroupUserName,
                externalSessionId,
                metadata,
                page,
                duration,
                type: "solo_playground"
                //url, type?
            })
        };

        return fetch(routes.getMonitoringResults, init).then((response) => response.json())
    },



    getAnonymousResults: async (tracker) => {
        let headers = getAuthHeader();
        headers.append("Content-Type", "application/json")
        let init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                tracker,
            })
        };

        return fetch(routes.getAnonymousResults, init).then((response) => response.json())
    },

    isMonitoring: () => {
        return isMonitoring;
    },

    reportAIUnits: async (aiUnits) => {
        let headers = new Headers({
            'X-App-Id': _appId,
            'X-API-Key': _apiKey,
            'Content-Type': 'application/json'
        })

        let request = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                aiUnits
            })
        };
        try {
            let res = await fetch(routes.reportAIUnits, request).then((response) => response.json())
            return res
        }catch (e) {
            return e;
        }

        return false;
    }
}

const getAuthHeader = () => {
    return new Headers({
        'X-App-Id': _appId,
        'X-API-Key': _apiKey
    })
}

const trackEvent = async (eventName) => {
    let headers = getAuthHeader();
    headers.append("Content-Type", "application/json")
    const { contentId, externalGroupId, externalGroupName, externalGroupUserId, externalGroupUserName, externalSessionId, metadata, page } = getRequestData()
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            event: eventName,
            externalGroupId,
            externalGroupName,
            externalGroupUserId,
            externalGroupUserName,
            externalSessionId,
            contentId,
            metadata,
            page,
        })
    };

    return fetch(routes.trackEvent, init)
}

const getRequestData = () => {
    return {
        contentId: _contentId,
        externalGroupId: _groupId,
        externalGroupName: _groupName,
        externalGroupUserId: _userId,
        externalGroupUserName: _userName,
        externalSessionId: _sessionId,
        metadata: _metadata,
        page: "playground"
    }
}


const listeners = {
    checkup_started: null,
    checkup_ended: null,
    checkup_results: null,
    widget_opened: null,
    widget_closed: null
}

export const emitEvent = (eventName, data) => {
    trackEvent(eventName)
    listeners[eventName] && listeners[eventName](data)
}


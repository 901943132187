// features/aiUnits/aiUnitsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {postAIUnits} from '../api/aiUnits';



const aiUnitsSlice = createSlice({
    name: 'aiUnits',
    initialState: {
        aiUnits: [],
        loading: false,
        retryCount: 0 ,
        unitCycleStartTime: null,
        activeUnit: null,
        unitsLimit: false
    },
    reducers: {
        setUnitsLimit: (state, action) => {
            state.unitsLimit = action.payload
        },
        handleAIUnit: (state, action) => {
            if(state.activeUnit) {
                // check if last unit startTime is less than 60 seconds from action.payload.startTime
                const lastUnitTime = state.activeUnit.startTime;
                if(action.payload.startTime - lastUnitTime < 60 * 1000) {
                   // console.log("adding frame to ai unit")
                    state.activeUnit.numberOfFrames = state.activeUnit.numberOfFrames +1 ;
                } else {
                   // console.log("push ai unit - new cycle", action.payload)
                    state.aiUnits.push(state.activeUnit);
                    state.activeUnit = action.payload;
                    console.log("start new cycle", state.activeUnit.startTime)
                }
            }else {
              //  console.log("push ai unit - first", action.payload)
                state.activeUnit = action.payload;
            }
        },
        loadPersistedState: (state, action) => {
          //  console.log("loadPersistedState", action.payload)
            if (action.payload.activeUnit) {
                state.aiUnits.push(action.payload.activeUnit);
                state.activeUnit = null;
              //  console.log("loadPersistedState", state.aiUnits)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postAIUnits.pending, (state) => {
                state.loading = true;
                state.retryCount++;
            })
            .addCase(postAIUnits.fulfilled, (state, action) => {
                // state.aiUnits = state.aiUnits.filter(unit => !unit.includes(action.payload));
           //     console.log("postAIUnits.fulfilled", action.payload)
                const aiUnits = action.payload;
                // remove first aiUnits.length from state.aiUnits
                state.aiUnits = state.aiUnits.slice(aiUnits.length);
                state.loading = false;
                state.retryCount = 0; // Reset retry count on success
            })
            .addCase(postAIUnits.rejected, (state, action) => {
                state.loading = false;
                // Optionally handle further error logic here
                if(action.payload === "units limit reached"){
                    state.unitsLimit = true
                }
                console.log("postAIUnits.rejected", action.payload)
            });
    }
});

export const { setUnitsLimit, handleAIUnit, loadPersistedState } = aiUnitsSlice.actions;
export default aiUnitsSlice.reducer;

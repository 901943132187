import React, {useEffect, useRef} from 'react';
import Lottie from "react-lottie";
import lottie from "lottie-web";
//react component that renders emoji lottie animation based on the emotion detected

export const EMOTION_ANIMATION_MAP = {
    happy: require('../../lotties/happy-emoji.json'),
    sad: require('../../lotties/crying-emoji-floating.json'),
    surprised: require('../../lotties/shocked-emoji-floating.json'),
    angry: require('../../lotties/fire-emoji-floating.json'),
    fear: require('../../lotties/skull-emoji-floating.json'),
    disgusted: require('../../lotties/rolling-eyes-emoji.json'),
}
const EmojiFilter = ({emotion, width, frameInfo, updateFrameInfo,id}) => {

    const containerRef = useRef(null);

    let currentFrame = 0

    useEffect(() => {
//        console.log("new", emotion)

        let animation = EMOTION_ANIMATION_MAP[emotion]
        if(!animation) {
            return
        }


        let autoplay = frameInfo && frameInfo.emotion === emotion ? false : true;
       /* if(autoplay){
            console.log("autoplay", autoplay)
            const instance = lottie.loadAnimation({
                container: containerRef.current,
                renderer: "svg",
                loop: true,
                name: emotion,
                autoplay: true,
                animationData: animation,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid',
                },
                // initialSegment: autoplay ? null : [frameInfo.currentTime, frameInfo.totalTime],
            });
        }else {
            console.log("", autoplay)
            lottie.play(emotion)
        }*/

        const instance = lottie.loadAnimation({
            container: containerRef.current,
            renderer: "svg",
            loop: true,
            name: emotion,
            autoplay: autoplay,
            animationData: animation,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid',
            },
            // initialSegment: autoplay ? null : [frameInfo.currentTime, frameInfo.totalTime],
        });

            instance.addEventListener("enterFrame", (data) => {
              //  console.log("enterFrame", data)

                /*!*{
                    "type": "enterFrame",
                    "currentTime": 16.999320000000008,
                    "totalTime": 150,
                    "direction": 1
                }*!*/
                updateFrameInfo(data, emotion, id)
            })

            if(!autoplay){
                //console.log("goToAndPlay", frameInfo.currentTime)
                instance.goToAndPlay(Math.round(frameInfo.currentTime), true)
            }

        // Return clean up function here
        return () => {
            instance.destroy(emotion)
            //lottie.pause(emotion)
        };
    }, []);


    useEffect(()=>{


    }, [emotion])
    let animation = EMOTION_ANIMATION_MAP[emotion]
    if(!animation) {
        return null
    }


    return (
        <div ref={containerRef} style={{width: width, height: width}}></div>
    );
}

export default EmojiFilter;

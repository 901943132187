import { createListenerMiddleware } from '@reduxjs/toolkit'
import {createAlertTemplate, pushToOvertime, setAlertsData, setWidgetResults, setOvertimeById} from "../reducers/emotionalData";
import _ from "lodash";
import {PLAYER_TYPE} from "../../constants";
import {calcOvertimePatterns, calculateFrequencyPerMinute, detectBlink} from "../../utils/bioFeedback";
import AttentionMetricsCalculator from "../../utils/bioFeedback/attentionMetricsCalculator";

export const emotionalDataListenerMiddleware = createListenerMiddleware()


emotionalDataListenerMiddleware.startListening({
    actionCreator: pushToOvertime,
    effect: async (action, {dispatch, getState}) => {
        const {data, playerType, vidCurrTime, timeSpent} = action.payload

        if(playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
          //  return
        }

        const alertsData = _.get(getState(), `emotionalData.alertsData`, {})
      //  console.log("alertsData", alertsData)
        const widgetResults = []
        let newAlertsData = {...alertsData}

     //   console.log("data", data)


        for(let item of data){
            const overtime = _.cloneDeep(_.get(getState().emotionalData.overtimeById, item.id, []))
            let alert = _.cloneDeep(_.get(alertsData, `${item.id}`, createAlertTemplate()))
          //  console.log("item", item)
            alert.id = item.id
           // let blink = detectBlink(overtime)
          //  overtime[overtime.length - 1].blink = blink
           // overtime[overtime.length - 1].blinksPM = calculateFrequencyPerMinute(overtime, "blink", 60)
          //  dispatch(setOvertimeById({id: item.id, data: overtime}))

            let patterns = calcOvertimePatterns(overtime, alert)
            //alertsData[current.id] = patterns.alert;
            newAlertsData = {...alertsData, [item.id]: patterns.alert}
            widgetResults.push({...item, ..._.last(overtime), alerts: patterns});

        }

        dispatch(setAlertsData(newAlertsData))
        dispatch(setWidgetResults(widgetResults))
    }
})


// ModuleEditor.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchModuleData, updateModuleData } from '../../app/reducers/modulesSlice';

const ModuleEditor = ({ moduleType }) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.modules[moduleType] || { weights: {}, norms: {} });

    useEffect(() => {
        dispatch(fetchModuleData(moduleType));
    }, [dispatch, moduleType]);

    const handleWeightChange = (key, value) => {
        const updatedWeights = { ...data.weights, [key]: parseFloat(value) };
        dispatch(updateModuleData({ moduleType, data: { ...data, weights: updatedWeights } }));
    };

    const handleNormChange = (key, meanOrSd, value) => {
        const updatedNorms = { ...data.norms, [key]: { ...data.norms[key], [meanOrSd]: parseFloat(value) }};
        dispatch(updateModuleData({ moduleType, data: { ...data, norms: updatedNorms } }));
    };

    return (
        <div>
            <h2>Edit {moduleType}</h2>
            {Object.keys(data.weights).map(key => (
                <div key={key}>
                    <label style={{fontSize: 12}}>{key} Weight:</label>
                    <input
                        style={{fontSize: 12, width: 50}}
                        type="number"
                        value={data.weights[key]}
                        onChange={(e) => handleWeightChange(key, e.target.value)}
                    />
                    <label style={{fontSize: 12}}>Mean:</label>
                    <input
                        style={{fontSize: 12, width: 50}}
                        type="number"
                        value={data.norms[key]?.mean || 0}
                        onChange={(e) => handleNormChange(key, 'mean', e.target.value)}
                    />
                    <label style={{fontSize: 12}}>SD:</label>
                    <input
                        style={{fontSize: 12, width: 50}}
                        type="number"
                        value={data.norms[key]?.sd || 0}
                        onChange={(e) => handleNormChange(key, 'sd', e.target.value)}
                    />
                </div>
            ))}
        </div>
    );
};

export default ModuleEditor;

import colors from "./colors";
import fonts from "./fonts";

export default {
    error: {
        fontFamily: fonts.OpenSans,
        color: colors.red
    }

}

const mediaMatch = window.matchMedia('(min-width:1025px)');
console.log("mediaMatch", mediaMatch)
const isDesktop = mediaMatch.matches;

// 640*360 ratio
let tabletProportionWidth = (window.innerHeight * .8) / 1.7777777777777777777777777777778



export const styles = {
    appWrapper: {
        display: "flex",
        width: !isDesktop ? "100%" : tabletProportionWidth,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    containerBG: {
        display: "flex",
        flexDirection: "column",
        width: "465px",
        height: "826.67px",
        background: "linear-gradient(180deg, #607CEF 0%, #E3E9FF 100%)",
        justifyContent: "space-evenly",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: "24px",
        borderRadius: "40px",
    },
    playerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        height: "80%",
        borderStyle: "solid",
        borderWidth: "24px",
        borderRadius: "40px",
    },
    auth: {
        title: {
            fontWeight: "900",
            fontSize: 70,
            padding: 0,
            color: "#FFFFFF",
            lineHeight: 1
        },
        message: {
            fontWeight: "350",
            fontSize: 30,
            padding: 10,
            color: "#000",
            width: '90%'
        }
    },
    player: {
        title: {
            fontWeight: "900",
            fontSize: 42,
            color: "#000000",
            textAlign: "left",
            width: "calc( 100% - 65px )"
        }
    },
    actionButton: {
        width: "90%",
        height: 52.05,
        background: "#536EEC",
        borderRadius: 66.7308,
        textAlign: "center",
        fontWeight: 600,
        fontSize: 24,
        color: "#FFFFFF",
        boxShadow: "0px 0px 9.08px rgba(0, 0, 0, 0.25)",
        outline: "none",
        border: 0,
        cursor: "pointer"
    },
    input: {
        width: "90%",
        height: 52.05,
        borderRadius: 66.7308,
        background: "#fff",
        textAlign: "center",
        fontWeight: 600,
        fontSize: 24,
        color: "#8C8C8C",
        boxShadow: "0px 0px 9.07636px rgba(0, 0, 0, 0.25)",
        outline: "none",
        border: 0
    },
    formError: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 16,
        color: "#D9524E",
    },
    emotionsSideBar: {
        background: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
    }
}

import { configureStore } from '@reduxjs/toolkit'
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import emotionalDataReducer from './reducers/emotionalData'

import {emotionalDataListenerMiddleware} from "./middleware/emotionalData";
import marioReducer from "./reducers/marioSlice";
import obstacleReducer from "./reducers/obstacleSlice";
import engineReducer from "./reducers/engineSlice";
import modulesSlice from "./reducers/modulesSlice";
import faceIdSlice from "./reducers/faceIdSlice";
import aiUnitsSlice from "./reducers/aiUnitsSlice";


export const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['aiUnits', 'activeUnit'],// only aiUnits will be persisted
    debug: process.env.NODE_ENV !== 'production',
    version: 1,
};

const persistedReducer = persistReducer(persistConfig, aiUnitsSlice);
export const store = configureStore({
    reducer: {
        emotionalData: emotionalDataReducer,
        mario: marioReducer,
        obstacle: obstacleReducer,
        engine: engineReducer,
        modules: modulesSlice,
        faceId: faceIdSlice,
        aiUnits: persistedReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
        .prepend(emotionalDataListenerMiddleware.middleware)
})

export const persistor = persistStore(store);

import React, { useState, useEffect, useRef } from 'react';
import { OverTimeButton } from "../../StyledComponents";
import OverTimeChart from '../../components/OverTime';
import { getString } from "../../utils/translations";
import { findIndex, get, cloneDeep } from 'lodash';
import {OVER_TIME_BUTTONS} from "../../constants";
import {FACE_CERTAINTY_FRAMES} from "../../api/remoteConfig";
import IdButtons from "../../components/IdButtons";
import colors from "../../styles/colors";

const OVERTIME_CHART_HEIGHT = 250

const SoloOverTimeChart = ({
                               overtime,
                               faceIds,
                               faceIdCount,
                               selectedIds,
                               showBrush,
                               onBrushChange,
                               toggleFaceLandmarks,
                               resetOverTime,
                               selectId,
                               showFaceLandmarks,
                               isJP
                           }) => {
    const [selectedButtons, setSelectedButtons] = useState([OVER_TIME_BUTTONS.STRESS]);
    const [chartShowAllTime, setChartShowAllTime] = useState(false);

    const [dynamicWidth, setDynamicWidth] = useState("60%");

    const otButtons1Container = useRef(null);
    const otButtons2Container = useRef(null);
    const otButtons3Container = useRef(null);

    const updateOTButtonsWidth = () => {
        if (otButtons1Container.current && otButtons3Container.current && otButtons2Container.current) {
            const parentWidth = otButtons2Container.current.parentElement.clientWidth;
            const div1Width = otButtons1Container.current.clientWidth;
            const div3Width = otButtons3Container.current.clientWidth;
            const newDiv2Width = parentWidth - div1Width - div3Width - 30;
            setDynamicWidth(newDiv2Width);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateOTButtonsWidth);
        updateOTButtonsWidth();
        return () => window.removeEventListener('resize', updateOTButtonsWidth);
    }, []);


    const onOvertimeEmotionClick = (emotion) => {
        let i = findIndex(selectedButtons, (value) => value === emotion);
        let update = cloneDeep(selectedButtons);

        if (!selectedButtons.length) {
            update.push(emotion);
        } else {
            if (i === -1) {
                update = [emotion];
            } else {
                update.splice(i, 1);
            }
        }

        setSelectedButtons(update);
    }

    const renderOvertimeButtons = (buttons) => {
        let extraStyle = isJP ? { fontSize: 10 } : {};

        return (
            <div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
                {
                    Object.keys(buttons).map((key) =>
                        <OverTimeButton
                            style={Object.assign(selectedButtons.includes(buttons[key]) ? { backgroundColor: colors.blue } : {}, extraStyle)}
                            onClick={() => onOvertimeEmotionClick(buttons[key])}>
                            {getString(buttons[key])}
                        </OverTimeButton>
                    )
                }
            </div>
        );
    }

    const tooltipLabelFormatter = (payload, data, x) => {
        if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        if (x.formatter) {
            return x.formatter(payload)
        }

       // console.log("tooltipLabelFormatter", payload, data, x);

        return payload;
    }

    const renderIdsButtons = (ids) => {

        const filteredIds = ids.filter(key => faceIdCount[key] >= FACE_CERTAINTY_FRAMES);

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: "100%" }}>
                <div ref={otButtons1Container} style={{ gap: 15, display: 'flex', flexDirection: 'row', }}>
                    <OverTimeButton style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0 }}
                                    onClick={resetOverTime}>
                        {getString("reset_data")}
                    </OverTimeButton>
                </div>

                <div ref={otButtons2Container} style={{ width: dynamicWidth, marginLeft: 15, marginRight: 15 }}>
                    <IdButtons ids={filteredIds} selectedIds={selectedIds} selectId={selectId} />
                </div>

                <div ref={otButtons3Container} style={{ gap: 15, display: 'flex', flexDirection: 'row', }}>
                    <OverTimeButton
                        style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0, width: 150 }}
                        onClick={() => setChartShowAllTime(!chartShowAllTime)}>
                        {chartShowAllTime ? getString("chart_show_all_time") : getString("chart_show_30")}
                    </OverTimeButton>
                    <OverTimeButton
                        style={{ backgroundColor: colors.black, color: colors.white, borderRadius: 0, width: 150 }}
                        onClick={toggleFaceLandmarks}>
                        {showFaceLandmarks ? getString("hide_landmarks") : getString("show_landmarks")}
                    </OverTimeButton>
                </div>
            </div>
        );
    }

    let modified, shouldModify = false, domain = [0, 100];
    shouldModify = true;
    modified = cloneDeep(overtime);

    if (!chartShowAllTime && modified.length > 1) {
        const totalTimeSpent = modified[modified.length - 1].timeSpent;
        const last30SecondsEntries = modified.filter(item => item.timeSpent >= totalTimeSpent - 30 * 1000);
        modified = last30SecondsEntries;
    }

    if (selectedButtons.length === 1) {
        if (selectedButtons[0] === OVER_TIME_BUTTONS.YAW_ANGLE || selectedButtons[0] === OVER_TIME_BUTTONS.yawFallback) {
            domain = [-90, 90];
        } else if (selectedButtons[0] === OVER_TIME_BUTTONS.PITCH_ANGLE || selectedButtons[0] === OVER_TIME_BUTTONS.pitchFallback) {
            domain = [-90, 90];
        } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN) {
            domain = [-100, 100];
        } else if (selectedButtons[0] === OVER_TIME_BUTTONS.YAWN_RATIO) {
            domain = [0, 100];
        } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_SCORE) {
            domain = [0, 100];
        } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_NOSE) {
            domain = [300, 350];
        }else if (selectedButtons[0] === OVER_TIME_BUTTONS.FILTER_REASON) {
            domain = [1, 15];
        }
    }

   // console.log("ot", modified);
    let colAButtons = [OVER_TIME_BUTTONS.MOOD, OVER_TIME_BUTTONS.ENERGY, OVER_TIME_BUTTONS.STRESS, OVER_TIME_BUTTONS.ENGAGEMENT];
    let colBButtons = [OVER_TIME_BUTTONS.YAWN_RATIO, OVER_TIME_BUTTONS.EYES, OVER_TIME_BUTTONS.YAW_ANGLE, OVER_TIME_BUTTONS.PITCH_ANGLE, OVER_TIME_BUTTONS.FROWN, OVER_TIME_BUTTONS.BLINKS_PER_MINUTE, OVER_TIME_BUTTONS.FILTER_REASON ];

    if(process.env.REACT_APP_ENV === "lab"){
        colBButtons.push(OVER_TIME_BUTTONS.LIGHT_INDEX)
    }

    return (
        <div style={{ height: OVERTIME_CHART_HEIGHT, position: "absolute", left: 0, right: 0, bottom: 0 }}>
            <div style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 12,
                flexDirection: 'column'
            }}>
                <div style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                    {renderIdsButtons(faceIds)}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    marginTop: 10
                }}>
                    {renderOvertimeButtons(colAButtons)}
                    <div style={{ width: '100%', height: '100%', }}>
                        <OverTimeChart
                            x={{ key: "index" }}
                            y={{ key: "value" }}
                            data={shouldModify ? modified : (overtime || [{}])}
                            selectedEmotions={selectedButtons}
                            tooltipLabelFormatter={tooltipLabelFormatter}
                            domain={domain}
                            selectedIds={selectedIds}
                            showBrush={showBrush}
                            onBrushChange={onBrushChange}
                        />
                    </div>
                    {renderOvertimeButtons(colBButtons)}
                </div>
            </div>
        </div>
    );
}

export default SoloOverTimeChart;

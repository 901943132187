import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import Input from "./Input";
import typography from "../styles/typography";
import placeholder from "lodash/fp/placeholder";

class InputWithLabel extends PureComponent {
    render() {

        const {
            type,
            value,
            onChange,
            label,
            error,
            wrapperStyle,
            inputStyle,
            labelStyle,
            placeholder
        } = this.props;
        return (
            <div style={wrapperStyle}>
                <Label style={labelStyle}>{label}</Label>
                <Input
                    onChange={onChange}
                    value={value}
                    type={type}
                    style={inputStyle}
                    placeholder={placeholder}
                />
                <div style={typography.error}>{error}</div>
            </div>
        );
    }
}

InputWithLabel.propTypes = {
    label: PropTypes.string,
    error: PropTypes.string,
    wrapperStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    ...Input.propTypes
};

InputWithLabel.defaultProps = {
    ...Input.defaultProps
}

const Label = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.71145625em;
  line-height: 1em;
  letter-spacing: 0.066699375em;
  text-transform: uppercase;

  color: #B0BAC9;
  margin-bottom: 0.5em;
`

export default InputWithLabel;

import React, {useState} from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";


import styled from "styled-components";
import Popup from "../../components/Popup";
import {getString} from "../../utils/translations";
import {ErrorMessage, ValueBoxMain, ValueBoxSecondaryInput} from "./styledComponents";
import {demoSignUp, login} from "../../api/api";

const recaptchaSiteKey = "6LdPORIqAAAAADxt1hiETlwMjMPYwkgOsWMV4ARA"

export default function SignUpPopup({ isOpen, closeHandler }) {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [companyNameError, setCompanyNameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [formError, setFormError] = useState("");

    const { executeRecaptcha } = useGoogleReCaptcha();



    const validate = () => {
        let valid = true;
        if (!name) {
            setNameError("Name is required");
            valid = false;
        }
        if (!email) {
            setEmailError("Email is required");
            valid = false;
        }
        if(!verifyEmail()){
            valid = false;
        }
        if (!companyName) {
            setCompanyNameError("Company Name is required");
            valid = false;
        }
        if (!password) {
            setPasswordError("Password is required");
            valid = false;
        }
        if (!verifyPassword()){
            valid = false;
        }
        return valid;
    };

    const verifyEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let validEmail = emailRegex.test(email);
        if (!validEmail) {
            setEmailError("Invalid email");
            return false
        }else {
            setEmailError("");
            return true
        }
    }

    const verifyPassword = () => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        let validPassword = passwordRegex.test(password);
        if (!validPassword) {
            setPasswordError("Password must contain at least one number, one uppercase and lowercase letter, and at least 8 or more characters");
            return false
        }else {
            setPasswordError("");
            return true
        }
    }

    const submit = async () => {
        let valid = validate();
        if(!valid){
            return;
        }

        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        try {
            const recaptchaToken = await executeRecaptcha("signup");
            let res = await demoSignUp({email, name, companyName, password, recaptchaToken});
            console.log("res", res)
            if(res && res.success){
                let password = res.password;
                let loginRes = await login(email, password);
                if(loginRes){
                    setFormError("")
                    //remove ?mode=signup from url
                    let url = window.location.href;
                    let newUrl = url.split("?")[0];
                    window.history.pushState({}, null, newUrl);
                    window.location.reload();

                }
            }
            console.log("res", res)
            setFormError("Error signing up with this email & password. Please try again.")
        }catch (e) {
            console.error("signup error", e)
            setFormError("Error signing up with this email & password. Please try again.")
        }
    }

    return (

        <Popup
            isOpen={isOpen}
            closeHandler={closeHandler}
            title={getString("sign_up_title")}
            description={getString("sign_up_description")}
            footerButtons={[
                {
                    label: getString("sign_up_submit_button"),
                    onPress: submit
                }
            ]}
        >

            <>
                <ValueBoxSecondaryInput placeholder={getString("name")} autoFocus value={name} onChange={e => setName(e.target.value)}/>
                {nameError && <ErrorMessage>{nameError}</ErrorMessage>}

                <ValueBoxSecondaryInput placeholder={getString("email")}  value={email} onChange={e => setEmail(e.target.value)} onBlur={verifyEmail}/>
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}

                <ValueBoxSecondaryInput placeholder={getString("password")} type={"password"}  value={password} onChange={e => setPassword(e.target.value)} onBlur={verifyPassword}/>
                {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}

                <ValueBoxSecondaryInput placeholder={getString("company_name")} value={companyName} onChange={e => setCompanyName(e.target.value)}/>
                {companyNameError && <ErrorMessage>{companyNameError}</ErrorMessage>}

                {formError && <ErrorMessage>{formError}</ErrorMessage>}
            </>

        </Popup>

    );
}


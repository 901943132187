import React, { Component } from 'react';
import CircularProgress from "./CircularProgress"
import styled from 'styled-components'
import _ from 'lodash'

class Widget extends Component {
    render() {
        return (
            <div style={{
                backgroundColor: '#FFFFFF',
                width: this.props.width - 100,
                height: this.props.width - 100,
                borderRadius: (this.props.width - 100) / 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: this.props.grayOut ? 0.5 : 1
            }} >
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress
                        width={this.props.width}
                        percentage={this.props.value}
                        color={this.props.progressColor}
                        value={this.props.presentationValue}
                        title={this.props.title} />
                </div>
            </div >
        );
    }
}

export default Widget;
















